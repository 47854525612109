import i18next from 'i18next';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { LocalDataTrack } from 'twilio-video';
import useDataTrack, { CallMessagingAction } from '../../../hooks/useDataTrack/useDataTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import ConfirmationModal from '../../ConfirmationModal';
import PrimaryButton from '../../PrimaryButton';

const Wrapper = styled.div`
  position: absolute;
  right: 5rem;
  top: 2rem;
  z-index: 2;

  @media only screen and (max-width: 768px) {
    right: 1.5rem;
    top: 2rem;
  }
`;

const EndCallButton: FC<{ pin?: string }> = ({ pin }) => {
  const { endCall, isCallHost, localTracks } = useVideoContext();
  const [sendMessage] = useDataTrack();
  const [showModal, setShowModal] = useState(false);

  const endCallForAllParticipants = async () => {
    if (isCallHost) {
      // send message to all participants to end call
      sendMessage({
        action: CallMessagingAction.ACTION_END_CALL,
      });
    }

    localTracks.forEach((track) => {
      if (!(track instanceof LocalDataTrack)) {
        track.stop();
      }
    });

    await endCall(pin);
  };

  return (
    <Wrapper>
      <ConfirmationModal
        show={showModal}
        title="Gespräch für alle Teilnehmer beenden"
        body={
          <>
            <p>Wollen Sie wirklich das Gespräch beenden?</p>
            <p>Die Sitzung wird damit für alle Teilnehmer beendet.</p>
          </>
        }
        buttonPositive="Beenden"
        buttonNegative={i18next.t('generic.cancel')}
        onPositive={endCallForAllParticipants}
        onNegative={() => setShowModal(false)}
      />
      <PrimaryButton onClick={isCallHost ? () => setShowModal(true) : endCallForAllParticipants}>
        {isCallHost ? 'Gespräch beenden' : 'Gespräch verlassen'}
      </PrimaryButton>
    </Wrapper>
  );
};

export default EndCallButton;
