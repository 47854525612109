import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import styled from 'styled-components';
import SecondaryButton from '../../components/SecondaryButton';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import { isEmpty, map, union } from 'lodash';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

const ActionButton = styled(SecondaryButton)`
  float: center;
`;

const QuestionsInsurancePlan = () => {
    const planInit = {
        _id: null,
        planName: "",
        planCode: "",
        headerTitle: "",
        headerImage: "",
        price: 0,
        videoUrl: "",
        planDescription: "",
        planLogo: "",
        status: "Active",
        isCouponRequired: false
    };
    const questionInit = {
        _id: null,
        de: "",
        en: "",
        planId: "",
        type: "SINGLE",
        deOptionList: [],
        enOptionList: [],
        correctAnswerIndex: 0,
        isActive: true
    }
    const [isLoading, setIsLoading] = useState(false);
    const [planList, setPlanList] = useState([]);
    const [deOptionList, setDeOptionList] = useState([]);
    const [enOptionList, setEnOptionList] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(planInit);
    const [selectedQuestion, setSelectedQuestion] = useState(questionInit);


    const { getIdTokenClaims, user } = useAuth0();
    const fetchPlanList = async () => {
        setSelectedPlan(planInit);
        setIsLoading(true);
        const token = await getIdTokenClaims();
        if (token) {
            const plans = await fetchFromRestAPI('/api/v1/insurance/all', {
                method: 'GET',
                token,
            });
            if (plans && plans.length > 0) {
                setPlanList(plans);
                setSelectedPlan(plans[0]);
                fetchQuestionList(plans[0]._id);
            }
        }
        setIsLoading(false);
    }
    const fetchQuestionList = async (planId) => {
        setIsLoading(true);
        setSelectedQuestion(questionInit);
        setQuestionList([]);

        const token = await getIdTokenClaims();
        const questions = await fetchFromRestAPI('/api/v1/insurance/question/all/' + planId, {
            method: 'GET',
            token,
        });
        if (questions && questions.length > 0) {
            setQuestionList(questions);
            let de = [];
            let en = [];
            questions.forEach(element => {
                de = [...de, ...element.deOptionList];
                en = [...en, ...element.enOptionList];
                setDeOptionList(de);
                setEnOptionList(en);
            });

        }
        setIsLoading(false);
    }
    const editQuestion = (q) => {
        setSelectedQuestion(q);
    }
    const addQuestion = async (question) => {
        setIsLoading(true);
        const token = await getIdTokenClaims();
        await fetchFromRestAPI('/api/v1/insurance/question/add', {
            method: 'POST',
            body: question,
            token,
        });
        fetchQuestionList(selectedPlan._id);
        setIsLoading(false);
    }
    const saveQuestion = () => {
        if (selectedQuestion && !isEmpty(selectedQuestion.de) && !isEmpty(selectedQuestion.en)) {
            let question = selectedQuestion;
            question.planId = selectedPlan._id;
            question.deOptionList = union(question.deOptionList);
            question.enOptionList = union(question.enOptionList);
            if (question._id) {
                updateQuestion(question);
            } else {
                addQuestion(question);
            }
        } else {
            alert("Missing inputs");
        }
    }
    const updateQuestion = async (question) => {
        setIsLoading(true);
        const token = await getIdTokenClaims();
        await fetchFromRestAPI('/api/v1/insurance/question/update', {
            method: 'PATCH',
            body: question,
            token,
        });
        fetchQuestionList(selectedPlan._id);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchPlanList();
        // eslint-disable-next-line
    }, [user]);

    return (
        <React.Fragment>
            <Form.Group>
                <Form.Label>Select Plan</Form.Label>
                <Form.Control as="select" size="sm" defaultValue={selectedPlan._id} onChange={(e) => {
                    selectedPlan._id = e.target.value;
                    setSelectedPlan(selectedPlan);
                    setIsLoading(true);
                    fetchQuestionList(e.target.value)
                }}>
                    {map(planList, (plan, index) => {
                        return (
                            <option key={index + plan._id} value={plan._id}>{plan.planName} [{plan.planCode}]</option>
                        )
                    })}
                </Form.Control>
            </Form.Group>
            {isLoading ? <span>Refreshing...</span> :
                <Table striped bordered hover className="h-75 overflow-auto">
                    <thead>
                        <tr>
                            <th className='centered-td'>de</th>
                            <th className='centered-td'>en</th>
                            <th className='centered-td'>Type</th>
                            <th className='centered-td'>de Option List</th>
                            <th className='centered-td'>en Option List</th>
                            <th className='centered-td'>Correct Answer Index</th>
                            <th className='centered-td'>Active</th>
                            <th className='centered-td'></th>
                        </tr>
                    </thead>
                    <tbody key={questionList.length} >
                        <tr>
                            <td className='centered-td'>
                                <Form.Control type="text"
                                    defaultValue={selectedQuestion.de}
                                    onChange={(e) => {
                                        selectedQuestion.de = e.target.value;
                                        setSelectedQuestion(selectedQuestion);
                                    }}
                                />
                            </td>
                            <td className='centered-td'>
                                <Form.Control type="text"
                                    defaultValue={selectedQuestion.en}
                                    onChange={(e) => {
                                        selectedQuestion.en = e.target.value;
                                        setSelectedQuestion(selectedQuestion);
                                    }}
                                /></td>
                            <td className='centered-td'>
                                <Form.Control as="select" size="sm" defaultValue={selectedQuestion.type} onChange={(e) => {
                                    selectedQuestion.type = e.target.value;
                                    setSelectedQuestion(selectedQuestion);
                                }}>
                                    <option value="SINGLE">SINGLE</option>
                                </Form.Control>
                            </td>
                            <td className='centered-td'>
                                <Typeahead
                                    id="de-typeahead-multiple"
                                    multiple
                                    allowNew
                                    onChange={(e) => {
                                        if (e && e.length > 0 && e[e.length - 1] && e[e.length - 1].customOption) {
                                            const p = [];
                                            p.push(e[e.length - 1].label.trim());
                                            let newelement = [...deOptionList, ...p];

                                            setDeOptionList(newelement);
                                            const pNew = selectedQuestion.deOptionList;
                                            pNew.push(e[e.length - 1].label.trim());
                                            setSelectedQuestion({ ...selectedQuestion, deOptionList: pNew });
                                        } else {
                                            setSelectedQuestion({ ...selectedQuestion, deOptionList: e });
                                        }
                                    }}
                                    options={deOptionList}
                                    placeholder="De Option List"
                                    selected={selectedQuestion.deOptionList}
                                />
                            </td>
                            <td className='centered-td'>
                                <Typeahead
                                    id="en-typeahead-multiple"
                                    multiple
                                    allowNew
                                    onChange={(e) => {
                                        if (e && e.length > 0 && e[e.length - 1] && e[e.length - 1].customOption) {
                                            const p = [];
                                            p.push(e[e.length - 1].label.trim());
                                            let newelement = [...enOptionList, ...p];
                                            setEnOptionList(newelement);
                                            const pNew = selectedQuestion.enOptionList;
                                            pNew.push(e[e.length - 1].label.trim());
                                            setSelectedQuestion({ ...selectedQuestion, enOptionList: pNew });
                                        } else {
                                            setSelectedQuestion({ ...selectedQuestion, enOptionList: e });
                                        }
                                    }}
                                    options={enOptionList}
                                    placeholder="EN Option List"
                                    selected={selectedQuestion.enOptionList}
                                />
                            </td>
                            <td className='centered-td'>
                                <Form.Control type="number"
                                    defaultValue={selectedQuestion.correctAnswerIndex}
                                    onChange={(e) => {
                                        selectedQuestion.correctAnswerIndex = parseInt(e.target.value);
                                        setSelectedQuestion(selectedQuestion);
                                    }}
                                /></td>


                            <td className='centered-td'>
                                <Form.Check
                                    inline
                                    label=""
                                    type="checkbox"
                                    defaultChecked={selectedQuestion.isActive}
                                    onChange={(e) => {
                                        selectedQuestion.isActive = e.target.checked;
                                        setSelectedQuestion(selectedQuestion)
                                    }}
                                />

                            </td>
                            <td className='centered-td'>


                                <ActionButton className="m-1" onClick={() => saveQuestion()}>{selectedQuestion._id ? "Save" : "Add New"}</ActionButton>
                                <ActionButton onClick={() => setSelectedQuestion(questionInit)}>Cancel</ActionButton>
                            </td>
                        </tr>
                        {map(questionList, (q) => {
                            return (
                                <tr key={q._id}>
                                    <td className='centered-td'>{q.de}</td>
                                    <td className='centered-td'>{q.en}</td>
                                    <td className='centered-td'>{q.type}</td>
                                    <td className='centered-td'>
                                        {q.deOptionList.map(t => <p key={t}>{t}</p>)}</td>
                                    <td className='centered-td'>
                                        {q.enOptionList.map(t => <p key={t}>{t}</p>)}</td>
                                    <td className='centered-td'>{q.correctAnswerIndex}</td>
                                    <td className='centered-td'>
                                        {q.isActive ? 'YES' : 'NO'}
                                    </td>
                                    <td className='centered-td'>
                                        <ActionButton onClick={() => editQuestion(q)}>Edit</ActionButton>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </Table>}
        </React.Fragment>
    );
};

export default QuestionsInsurancePlan;
