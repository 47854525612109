import { Auth0Lock } from 'auth0-lock';
import i18next from 'i18next';
import { noop } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_AUDIENCE } from '../../config';
import { useAuth0 } from '../../util/auth0';
import StyledModal from '../StyledModal';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { fetchFromRestAPI } from '../../util/api';
const i18nextLng = localStorage.getItem('i18nextLng');
const LockWrapper = styled.div`
  .auth0-lock.auth0-lock {
    .auth0-lock-header {
      display: none;
    }
    .auth0-lock-input-wrap.auth0-lock-focused {
      border-color: #ff695f;
    }
  }
`;

const LoginBoxAuth0Lock = ({ signIn }) => {
  const { handleOnAuthenticated, handleOnSignedIn, logout } = useAuth0();
  const [showEmailConfirmation, setEmailConfirmation] = useState(false)
  const [signInLocal, setSignInLocal] = useState(signIn)
  const location = useLocation();
  useEffect(() => {

    const getReferralCodeFromQueryParam = () => {
      const qs = queryString.parse(location.search);
      if (qs.supportSignUp === "true" && qs.success === "true") {
        setSignInLocal(true);
        setEmailConfirmation(true);
      } else {
        setEmailConfirmation(false);
      }

      if (qs.ref) {
        const referralCode = qs.ref;
        localStorage.setItem('referralCode', referralCode); // for later sign-ups
        //console.log(`use referral code '${referralCode}' for signup`);
        return referralCode;
      } else {
        return localStorage.getItem('referralCode');
      }
    };
    const referralCode = getReferralCodeFromQueryParam();
    const userLanguage = i18nextLng && i18nextLng.includes("-") ? i18nextLng.split("-")[0] : i18nextLng;

    const langOption = i18next.t('auth0lock.languageDictionary', { returnObjects: true })
    langOption['error'] = { signUp: { 'lock.fallback': i18next.t('auth0lock.languageDictionary.genericError') } };

    const lock = new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_DOMAIN, {
      container: 'auth0Lock',
      language: userLanguage,
      rememberLastLogin: true,
      avatar: null,
      allowSignUp: !signInLocal,
      allowLogin: signInLocal,
      theme: {
        primaryColor: '#ff695f',
      },
      languageDictionary: langOption,
      mustAcceptTerms: true,
      loginAfterSignUp: false,
      additionalSignUpFields: referralCode
        ? [
          {
            type: 'hidden',
            name: 'referralCode',
            value: referralCode,
          },
        ]
        : [],
      auth: {
        responseType: 'token id_token',
        sso: true,
        autoParseHash: false,
        audience: AUTH0_AUDIENCE
        //redirectUrl: AUTH0_REDIRECTURL,
      }
    });

    lock.on('authenticated', function (authResult) {
      lock.getUserInfo(authResult.accessToken, function (error, profileResult) {
        if (error) {
          console.error(error);
          return;
        }
        handleOnAuthenticated(authResult, profileResult);
      });
    });

    lock.on('signup success', async function (res) {
      await fetchFromRestAPI('/api/v1/user-profile/signup', {
        method: 'POST',
        body: { email: res.email }
      });
      handleOnSignedIn();
    });

    if (/access_token|id_token|error/.test(window.location.hash)) {
      lock.resumeAuth(window.location.hash, noop);
    } else {
      lock.show();
    }
    return () => {
      lock.destroy();
    };
  }, [handleOnAuthenticated, handleOnSignedIn, location.search, logout, signInLocal]);

  return (
    <>
      <StyledModal size="sm" centered show={showEmailConfirmation} onHide={() => setEmailConfirmation(false)}>
        <Modal.Body>
          {i18next.t("loginpage.emailVerifiedBody")}
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button variant="secondary" onClick={() => setEmailConfirmation(false)}>
            {i18next.t("loginpage.emailVerifedButton")}
          </Button>
        </Modal.Footer>
      </StyledModal>
      <LockWrapper id="auth0Lock" />
    </>
  );
};

export default LoginBoxAuth0Lock;
