import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form, Accordion, Card, Alert } from 'react-bootstrap';
import map from 'lodash/map';
import { useAuth0 } from '../../util/auth0';
import { ButtonGroup } from 'react-bootstrap';
import ConfirmationModal from '../ConfirmationModal';
import i18next from 'i18next';
import moment from 'moment';
import styled from 'styled-components';

type Props = {
    show: boolean;
    title: ReactNode;
    noTemplateMsg: ReactNode;
    maxTemplateError: ReactNode;
    templateList: any;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    onPositive: (trainingPlan) => void;
    onNegative: () => void;
    disableTemplate: (tpId, isGlobal) => void;
    loadTemplate: (isGlobal) => void;
    trainingindex: number;
    initSelected: any;
    yourTemplateText: ReactNode;
    globalTemplateText: ReactNode;
};
const BodyDiv = styled.div`
    max-height: 50vh;
    overflow-y: auto;
`;
const LoadTemplateModal: FC<Props> = ({
    show,
    title,
    noTemplateMsg,
    maxTemplateError,
    templateList,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableTemplate,
    trainingindex,
    initSelected,
    loadTemplate,
    yourTemplateText,
    globalTemplateText
}) => {
    const { isAdminUser } = useAuth0();
    const [idx, setIdx] = useState("0");
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [confirmBody, setConfirmBody] = useState('');
    const [isGlobal, setIsGlobal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);
    const [selectedTrainings, setSelectedTrainings] = useState([]);
    useEffect(() => {
        setSelectedTrainings([]);
    }, [initSelected]); // eslint-disable-line
    useEffect(() => {
        loadTemplate(isGlobal)
    }, [isGlobal]); // eslint-disable-line
    const deleteConfirmBox = (template: any) => {
        setSelectedTemplate(template);
        const body = i18next.t('trainingPlanForm.deleteTemplateBody').replace('{templateName}', template.templateName);
        setConfirmBody(body)

        setShowConfirmDelete(true);
    }
    const deleteTemplate = () => {
        if (selectedTemplate) {
            disableTemplate(selectedTemplate._id, isGlobal);
        }
        setShowConfirmDelete(false);
    }
    const renderTrainingSelection = (t, index) => {
        return (
            <li className="list-group-item" key={'Training-' + index}>
                <Form.Check type="checkbox" id={`training-${index}`}>
                    <Form.Check.Input className="mt-2" type="checkbox"
                        defaultChecked={selectedTrainings.findIndex(x => x._id === t._id) >= 0} onChange={(e) => {
                            if (e.target.checked) {
                                selectedTrainings.push(t);
                                setSelectedTrainings([...selectedTrainings]);
                            } else {
                                const iArray = selectedTrainings.findIndex(x => x._id === t._id);
                                if (iArray >= 0) {
                                    selectedTrainings.splice(iArray, 1);
                                    setSelectedTrainings([...selectedTrainings]);
                                }
                            }
                        }} />
                    <Form.Check.Label>{t.name}
                    </Form.Check.Label>
                </Form.Check>
            </li>
        )
    };
    const renderTemplate = (t, index) => {
        return (
            <Card key={'Template-' + index}>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}
                        onClick={() => {
                            idx === index.toString() ? setIdx("") : setIdx(index.toString());
                        }}
                    >
                        <span key={"Template" + idx}>
                            {idx === index.toString() ? (
                                <i className="fas fa-caret-up"></i>
                            ) : (
                                <i className="fas fa-caret-down"></i>
                            )}
                        </span> {t.templateName}


                    </Accordion.Toggle>

                    {isAdminUser && isGlobal ? <Button
                        className="float-right"
                        onClick={() => { deleteConfirmBox(t) }}
                        style={{ fontSize: '1rem' }}
                    >
                        <i className="fas fa-trash" ></i>
                    </Button> : null}
                    {!isGlobal ? <Button
                        className="float-right"
                        onClick={() => { deleteConfirmBox(t) }}
                        style={{ fontSize: '1rem' }}
                    >
                        <i className="fas fa-trash" ></i>
                    </Button> : null}
                    <small className="float-right pt-2 pr-3">{moment(t.createdAt).fromNow()}</small>
                </Card.Header>
                <Accordion.Collapse eventKey={index.toString()}>
                    <Card.Body>
                        <ul className="list-group">
                            {map(t.trainingPlan.trainings, (t, i) => renderTrainingSelection(t, i))}
                        </ul></Card.Body>
                </Accordion.Collapse>
            </Card>

        )
    };
    return (
        <Modal size='lg' show={show} onHide={() => onNegative()}>
            <Modal.Header closeButton className="bg-light">
                <Modal.Title><h2>{title}</h2></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ConfirmationModal
                    show={showConfirmDelete}
                    title={i18next.t('trainingPlanForm.deleteTemplateTitle')}
                    body={confirmBody}
                    buttonPositive={i18next.t('generic.delete')}
                    buttonNegative={i18next.t('generic.close')}
                    onPositive={deleteTemplate}
                    onNegative={() => setShowConfirmDelete(false)}
                />
                <ButtonGroup aria-label="GlobalTemplate" className="pb-2">
                    <Button variant={!isGlobal ? 'primary' : 'secondary'} onClick={() => { setIsGlobal(false) }}>{yourTemplateText}</Button>
                    <Button variant={isGlobal ? 'primary' : 'secondary'} onClick={() => { setIsGlobal(true) }}>{globalTemplateText}</Button>
                </ButtonGroup>
                {
                    templateList.length > 0 ? <>

                        {
                            selectedTrainings.length + trainingindex > 4 ?
                                <Alert key={'alert-' + selectedTrainings.length} variant="danger">
                                    {maxTemplateError}
                                </Alert> : null
                        }

                        <Accordion defaultActiveKey="0" >
                            <BodyDiv>
                            {map(templateList, (t, i) => renderTemplate(t, i))}
                            </BodyDiv>
                        </Accordion></> : <p>{noTemplateMsg}</p>}

            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()}>
                    {buttonNegative}
                </Button>
                <Button onClick={() => { onPositive(selectedTrainings); }} variant="danger" disabled={(selectedTrainings.length + trainingindex > 4)}>
                    {buttonPositive}
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default memo(LoadTemplateModal);
