import { LocalDataTrack } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';

export enum CallMessagingAction {
  ACTION_MUTE_ALL = 'muteAll',
  ACTION_UNMUTE_ALL = 'unMuteAll',
  ACTION_SHARE_FILE = 'shareFile',
  ACTION_REQUEST_SCREENSHOT = 'requestScreenshot',
  ACTION_SCREENSHOT_GENERATED = 'screenshotGenerated',
  ACTION_SCREENSHOT_NOT_POSSIBLE = 'screenshotNotPossible',
  ACTION_END_CALL = 'callEnded',
}

interface DataTrackMessage {
  action: CallMessagingAction;
  [property: string]: any;
}

export default function useDataTrack() {
  const { localTracks } = useVideoContext();
  const dataTrack = localTracks.find((track) => track.kind === 'data') as LocalDataTrack;

  const sendMessage = (msg: DataTrackMessage) => {
    dataTrack.send(JSON.stringify(msg));
  };

  return [sendMessage] as const;
}
