import i18next from 'i18next';
import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import useAuthenticatedImage from '../../../hooks/useAuthenticatedImage';
import useRequestScreenshot from '../../../hooks/useRequestScreenshot/useRequestScreenshot';
import { emitNotification } from '../../Notification';
import ControlsTooltip from './ControlsToolTip';

const ScreenshotImage = styled.img`
  width: 100%;
`;

const RequestScreenshotButton: FC = () => {
  const [isSaving, setIsSaving] = useState(false);

  const {
    requestScreenshot,
    isWaitingForScreenshot,
    screenshotReceived,
    cancel,
    attachScreenshotToSession,
  } = useRequestScreenshot();

  const onRequestScreenshotClicked = async () => {
    requestScreenshot().then();
  };

  const saveScreenshot = async () => {
    setIsSaving(true);
    attachScreenshotToSession()
      .then(() => {
        emitNotification(`Screenshot wurde erfolgreich gespeichert!`);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const screenshotObject = useAuthenticatedImage(screenshotReceived);

  return (
    <>
      <ControlsTooltip id="tooltip-toggle-fullscreen" label="Screenshot anfordern">
        <Button className="share-files" onClick={onRequestScreenshotClicked}>
          <strong>
            <i className="fas fa-camera" />
          </strong>
        </Button>
      </ControlsTooltip>
      <Modal show={!isSaving && (isWaitingForScreenshot || !isEmpty(screenshotReceived))} size="lg" onHide={cancel}>
        <Modal.Header closeButton>
          <Modal.Title>Screenshot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isWaitingForScreenshot ? <Spinner animation="border" /> : <ScreenshotImage src={screenshotObject} />}
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ marginRight: 'auto' }} variant="secondary" onClick={cancel}>
            {isWaitingForScreenshot ? i18next.t('generic.cancel') : 'Screenshot verwerfen'}
          </Button>
          {!isWaitingForScreenshot && (
            <Button variant="primary" onClick={saveScreenshot}>
              Screenshot verwenden
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestScreenshotButton;
