import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import { FaExpand, FaCompress } from 'react-icons/fa';
import ControlsTooltip from './ControlsToolTip';
import useFullScreenToggle from '../../../hooks/useFullScreenToggle/useFullScreenToggle';

const ToggleFullScreenButton: FC<{ disabled?: boolean; showLabel?: boolean }> = ({ disabled, showLabel }) => {
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle(true);
  return (
    <ControlsTooltip
      id="tooltip-toggle-fullscreen"
      label={isFullScreen ? 'Fullscreenmodus deaktivieren' : 'Fullscreenmodus aktivieren'}
    >
      <Button onClick={toggleFullScreen} disabled={disabled}>
        {isFullScreen ? <FaCompress /> : <FaExpand />}
        {showLabel ? (isFullScreen ? ' Fullscreenmodus deaktivieren' : ' Fullscreenmodus aktivieren') : ''}
      </Button>
    </ControlsTooltip>
  );
};

export default ToggleFullScreenButton;
