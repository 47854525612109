import React, { useRef, useEffect, FC } from 'react';
import { Track } from 'twilio-video';
import styled from 'styled-components';
import { IVideoTrack } from '../../util/types';

const Video = styled.video`
  width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

const VideoTrack: FC<VideoTrackProps> = ({ track, isLocal, priority }) => {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }

    track.attach(el);
    return () => {
      track.detach(el);

      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment';
  const style = isLocal && isFrontFacing ? { transform: 'rotateY(180deg)' } : {};

  return <Video ref={ref} style={style} />;
};

export default VideoTrack;
