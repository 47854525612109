import i18next from 'i18next';
import React, { FC, memo, useEffect } from 'react';
import styled from 'styled-components';
import ChatComponent from '../components/chat/ChatComponent';
import PrimaryButton from '../components/PrimaryButton';
import { resetNotificationDot } from '../util';
import { useAuth0 } from '../util/auth0';
import { fetchUserAccountRemote, fetchTherapistKey } from '../util/UserAccount';

const WrapperChat = styled.div`
background: #ffffff;
width : 100%;
height: 100vh;
display: flex;
flex-direction: row;
`;
const Wrapper = styled.div`
    background: #ffffff;
    width : 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content : center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content : center;
    align-items: center;
`;

const TitleText = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 100%;
    color: #FF695F;
`;

const SubHeading = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 150%;
    color: #161616;
`;

const ContentText = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #161616;
    margin-bottom: 15px;
`;
type Props = {
  userProfile: any,
  webSocket: any,
  chatKey: string,
  messages: any,
  tKeyPair: any,
  selectedTherapy:any,
  onLoadCompleted: (userProfile, token, eKey) => void,
  resetNewChatMessage: () => void;
  handleTherapySelection: (therapy) => void;
};
const ChatPageContainer: FC<Props> = ({ userProfile, webSocket, chatKey, messages, onLoadCompleted, tKeyPair, resetNewChatMessage, handleTherapySelection,selectedTherapy }) => {
  const { user, getIdTokenClaims, getAccessTokenClaims } = useAuth0();
  useEffect(() => {
    resetNotificationDot();
    const fetchAccountData = async () => {
      await handelRetry();
    };
    if (!user) return;
    if (!webSocket && !userProfile) {
      fetchAccountData();
    }
    // eslint-disable-next-line
  }, [user, getIdTokenClaims]);

  const handelRetry = async () => {
    const token = await getIdTokenClaims();
    const userData = await fetchUserAccountRemote(token);
    const idToken = await getAccessTokenClaims();
    const keyData = await fetchTherapistKey(idToken, token);
    onLoadCompleted(userData, token.__raw, keyData);
  }
  if (webSocket && tKeyPair) {
    return (
      <WrapperChat>
        {userProfile ? <ChatComponent therapist={userProfile} selectedTherapy={selectedTherapy} webSocket={webSocket} messages={messages} chatKey={chatKey} tKeyPair={tKeyPair} resetNewChatMessage={resetNewChatMessage} handleTherapySelection={handleTherapySelection}></ChatComponent> : null}
      </WrapperChat>)
  } else {
    return (<Wrapper>
      <Content>
        <TitleText></TitleText>
        <SubHeading>{i18next.t('chat.chatErrorTitle')}</SubHeading>
        <ContentText>{i18next.t('chat.chatErrorBody')}</ContentText>
        <PrimaryButton onClick={handelRetry}>
          {i18next.t('chat.retryButtonText')}
        </PrimaryButton>
      </Content>
    </Wrapper>)
  }
};
export default memo(ChatPageContainer);