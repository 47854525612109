import React, { memo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Card, Container, Button, Col, Form, Row } from "react-bootstrap";
import i18next from 'i18next';
import styled from 'styled-components';
import {
    fetchTherapistPlan,
    fetchUserAccountForSetup,
    updateUserAccountRemote, UserAccountSchema, validateUserForm, userProfileInitData, upgradeTherapistPlan
} from '../../util/UserAccount';
import { isEmpty } from 'lodash';
import { useAuth0 } from '../../util/auth0';
import { useHistory } from 'react-router';
import moment from 'moment';
import PlanSelectComponent from '../PlanSelectComponent';
import ConfirmationModal from '../ConfirmationModal';
const FormFieldError = styled.div`
width: 100%;
margin-top: 0.25rem;
font-size: 80%;
color: #dc3545;
font-weight: 700 !important;
`;

const OnboardingSteps = () => {
    const history = useHistory();
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [planBody, setPlanBody] = useState('');
    const { getIdTokenClaims, getAccessTokenClaims } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [isMounted, setIsMounted] = useState(false);

    const [showConfirmPlan, setShowConfirmPlan] = useState(false);

    const [userProfile, setUserProfile] = useState(userProfileInitData);
    const [userProfileIsComplete, setUserProfileIsComplete] = useState(false);
    const [step, setStep] = useState(1);
    const saveProfile = async (values: any) => {
        setShowConfirmPlan(false);
        setIsLoading(true);
        const token = await getAccessTokenClaims();
        values.birthDate = new Date(+values.year, +values.month - 1, +values.date);
        if (values.profilePic) {
            delete values.profilePic;
        }
        const profileResponse = await updateUserAccountRemote(token, values);
        if (profileResponse && !profileResponse.error && step === 2) {
            setIsMounted(true);
        }
        setIsLoading(false);
    }
    const upgradePlan = async (values: any) => {
        setShowConfirmPlan(false);
        setIsLoading(true);
        const token = await getIdTokenClaims();
        const body = { planId: values.therapistPlan, startDate: moment(moment().add(selectedPlan.trailDays, 'days').format('YYYY-MM-DDT00:00:00+00:00')).toDate(), endDate: moment(moment().add(selectedPlan.trailDays, 'days').endOf("month").format('YYYY-MM-DDT23:00:00+00:00')).toDate() }
        const profileResponse = await upgradeTherapistPlan(token, body);
        if (profileResponse && !profileResponse.error && step === 2) {
            setIsMounted(true);
        }
        setIsLoading(false);
    }
    const getUserProfile = (idToken: any) => {
        fetchUserAccountForSetup(idToken).then((profileResponse) => {
            let userProfileData = profileResponse ? JSON.parse(JSON.stringify(profileResponse.user)) : userProfileInitData as any;
            let isExpired = profileResponse && profileResponse.plan ? profileResponse.plan.isExpired : true;
            if (UserAccountSchema.isValidSync(userProfileData) && !isExpired) {
                setIsMounted(true);
            } else {
                setUserProfileIsComplete(false);
                if (userProfileData['birthDate']) {
                    const birthDate = userProfileData['birthDate'];
                    userProfileData['date'] = moment(birthDate).date();
                    userProfileData['month'] = moment(birthDate).month() + 1;
                    userProfileData['year'] = moment(birthDate).year();
                }
                setUserProfile(userProfileData);
                if (UserAccountSchema.isValidSync(userProfileData)) {
                    setStep(2);
                }
                setIsLoading(false);
            }
        });
    }
    const initUserProfile = () => {
        if (userProfile.firstName === '') {
            getIdTokenClaims().then((idToken) => {
                getPlanList();
                getUserProfile(idToken);
            });
        }
    }
    const getPlanList = () => {
        fetchTherapistPlan().then((response) => {
            if (response && response.length > 0) {
                setPlans(response);
            }
        });
    }
    useEffect(() => {
        if (isMounted) {
            history.push('/therapien');
        }else{
            initUserProfile();
        }
    }, [isMounted]);// eslint-disable-line
    const handleSelectPlan = (plan: any) => {
        setShowConfirmPlan(true);
        userProfile.therapistPlan = plan._id;
        let pBody = i18next.t('planDetails.confirmPlanBody');
        pBody = pBody.replace("{planStartDate}", moment().add(plan.trailDays, 'days').format('DD-MM-YYYY'));
        setPlanBody(pBody);
        setUserProfile(userProfile);
        setSelectedPlan(plan);
    }

    const renderPlanList = () => {
        return (<Row className="mr-3 ml-3 pt-2">
            {plans.map((plan, i) => {
                return <PlanSelectComponent key={`plan-${plan._id}${i}`} plan={plan} selectPlan={handleSelectPlan} buttonText={i18next.t('planDetails.planButton')} />
            })}
        </Row>)
    }
    const renderProfilForm = () => {
        return (<Card.Body>
            <h3 className='font-weight-bold mb-3'>{i18next.t('accountSetup.personalDetailLabel')}</h3>
            <Formik
                initialValues={userProfile}
                enableReinitialize={true}
                validate={validateUserForm}
                onSubmit={async (values) => {
                    if (!values.country) {
                        values.country = i18next.t('accountSetup.countryList').split(',')[0];
                    }
                    setUserProfile(values);
                    await saveProfile(values);
                    setStep(2);
                }}
            >{({ values, handleChange, errors, touched, dirty, isValid, isSubmitting, handleSubmit, submitForm, setFieldValue, setFieldTouched, setFieldError }) => (
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{i18next.t('accountSetup.firstName')}</Form.Label>
                            <Form.Control
                                defaultValue={values.firstName}
                                type="text"
                                name="firstName"
                                id="firstName"
                                isInvalid={touched.firstName ? !isEmpty(errors.firstName) : false}
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                            <Form.Control.Feedback className="font-weight-bold" type="invalid" role="alert">{errors.firstName}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{i18next.t('accountSetup.lastName')}</Form.Label>
                            <Form.Control
                                defaultValue={values.lastName}
                                type="text"
                                name="lastName"
                                id="lastName"
                                isInvalid={touched.lastName ? !isEmpty(errors.lastName) : false}
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                            <Form.Control.Feedback className="font-weight-bold" type="invalid" role="alert">{errors.lastName}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{i18next.t('accountSetup.street')}</Form.Label>
                            <Form.Control
                                defaultValue={values.street}
                                type="text"
                                name="street"
                                id="street"
                                isInvalid={touched.street ? !isEmpty(errors.street) : false}
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                            <Form.Control.Feedback className="font-weight-bold" type="invalid" role="alert">{errors.street}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                            <Form.Label>{i18next.t('accountSetup.postal')}</Form.Label>
                            <Form.Control
                                defaultValue={values.postal}
                                type="text"
                                name="postal"
                                id="postal"
                                isInvalid={touched.postal ? !isEmpty(errors.postal) : false}
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                            <Form.Control.Feedback className="font-weight-bold" type="invalid" role="alert">{errors.postal}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md={3}>
                            <Form.Label>{i18next.t('accountSetup.city')}</Form.Label>
                            <Form.Control
                                defaultValue={values.city}
                                type="text"
                                name="city"
                                id="city"
                                isInvalid={touched.city ? !isEmpty(errors.city) : false}
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                            <Form.Control.Feedback className="font-weight-bold" type="invalid" role="alert">{errors.city}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{i18next.t('accountSetup.telephone')}</Form.Label>
                            <Form.Control
                                defaultValue={values.telephone}
                                type="text"
                                name="telephone"
                                id="telephone"
                                isInvalid={touched.telephone ? !isEmpty(errors.telephone) : false}
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                            <Form.Control.Feedback className="font-weight-bold" type="invalid" role="alert">{errors.telephone}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>{i18next.t('accountSetup.country')}</Form.Label>
                            <Form.Control as="select"
                                value={values.country}
                                type="text"
                                name="country"
                                id="country"
                                isInvalid={touched.country ? !isEmpty(errors.country) : false}
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            > {
                                    i18next.t('accountSetup.countryList').split(',').map((val, i) => {
                                        return (
                                            <option key={'country-' + i} value={val}>{val}</option>
                                        );
                                    })}</Form.Control>
                            <Form.Control.Feedback className="font-weight-bold" type="invalid" role="alert">{errors.country}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row className="mb-0">
                        <Form.Group as={Col} className="mb-0">
                            <Form.Label>{i18next.t('accountSetup.dateOfBirth')}</Form.Label>
                        </Form.Group>
                    </Form.Row> <Form.Row>
                        <Form.Group as={Col} md={2} xs={3} className="mb-0">
                            <Form.Control
                                defaultValue={values.date}
                                name="date"
                                type="text"
                                id="date"
                                placeholder="DD"
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={2} xs={4} className="mb-0">
                            <Form.Control
                                defaultValue={values.month}
                                type="text"
                                name="month"
                                id="month"
                                placeholder="MM"
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={3} xs={5} className="mb-0">
                            <Form.Control
                                defaultValue={values.year}
                                type="text"
                                name="year"
                                id="year"
                                placeholder="YYYY"
                                onChange={handleChange}
                                disabled={userProfileIsComplete}
                            />
                        </Form.Group>

                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}> {errors.date ? (
                            <FormFieldError>{errors.date}</FormFieldError>
                        ) : null}</Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} className="float-right">
                            <Button type="button" variant="primary" className="mt-3 float-right" onClick={submitForm} disabled={userProfileIsComplete}>{i18next.t('accountSetup.completeRegistration')}</Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
            )}
            </Formik>
        </Card.Body>);
    }
    const renderPricePlan = () => {
        return (<Card.Body>
            <h3 className='font-weight-bold mb-3'>{i18next.t('accountSetup.choosePlanLabel')}</h3>
            {plans && plans.length > 0 ? renderPlanList() : null}
        </Card.Body>);
    }
    const goToStep = (stepNo: number) => {
        if (stepNo === 2 && UserAccountSchema.isValidSync(userProfile)) {
            setStep(2);
        } else {
            setStep(1);
        }
    }
    return (
        <Container className="h-100">
            <div className="d-flex justify-content-md-center align-items-center vh-100">
                {isLoading ? <Row>
                    <Col md={12} className="text-center">

                        <span className="loader">
                            <i className="fas fa-spin fa-cog fa-5x text-light" />
                        </span>

                    </Col>
                </Row> :
                    <Card>
                        <Card.Header className="hidden-xs">
                            <ul className="steps mb-5">
                                <li className="step" >
                                    <div className="step-content pointer" onClick={() => goToStep(1)}>
                                        <span className="step-circle step-circle-active">1</span>
                                        <span className={step===1? "step-text step-text-custom font-weight-bold": "step-text step-text-custom"} >{i18next.t('accountSetup.personalDetailLabel')}</span>
                                    </div>
                                </li>
                                <li className="step" >
                                    <div className="step-content pointer" onClick={() => goToStep(2)}>
                                        <span className={step === 2 ? "step-circle step-circle-active" : "step-circle step-circle-custom"}>2</span>
                                        <span className={step===2? "step-text step-text-custom font-weight-bold": "step-text step-text-custom"}>{i18next.t('planDetails.choosePlanLabel')}</span>
                                    </div>
                                </li>
                            </ul>

                        </Card.Header>
                        {step === 1 ? renderProfilForm() : renderPricePlan()}
                    </Card>}
            </div>
            <ConfirmationModal
                show={showConfirmPlan}
                title={i18next.t('planDetails.confirmPlanTitle')}
                body={planBody}
                buttonPositive={i18next.t('planDetails.planConfirmButton')}
                buttonNegative={i18next.t('planDetails.planCancelButton')}
                onPositive={() => upgradePlan(userProfile)}
                onNegative={() => { setShowConfirmPlan(false) }}
            />
        </Container>
    );
};

export default memo(OnboardingSteps);