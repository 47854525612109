import React, { useState, useEffect, memo } from 'react';
import i18next from 'i18next';
import { useAuth0 } from '../../util/auth0';
import { fetchFromRestAPI } from '../../util/api';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Answer from './Answer';
import { Badge, Button, Card, Col, Modal, Row } from 'react-bootstrap';
import TrainingPlanAccordion from './TrainingPlanAccordion';

const Wrapper = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 8px;
  h1 {
    font-weight: bold;
    font-size: 30px;
  }
  .banner {
    display: flex;
    padding-bottom: 10px;
    > div {
      max-width: 480px;
    }
  }
  .bg-training-strip{
    background-color:#F0F0F3 !important;

  }
  .bg-training-strip-text{
    color: #161616 !important;
  }
  .button-font{
    font-size:1em !important;
  }

  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .banner {
      h1 {
        font-size: 1.8rem;
      }
      flex-direction: column;
      .control-btns {
        align-items: flex-start;
      }
    }
  }
`;

const WarningDiv = styled.div`
  background: #ffcc4a;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  margin-bottom: 10px;
  .span-margin {
    margin-right: 5px;
    margin-top: 12px;
  }
  p {
    margin-bottom: 0px;
  }
`;
const ModalBodyDiv = styled.div`
    max-height: 50vh;
    overflow-y: auto;
    overflow-x:hidden;
    padding-right:10px;
`;
const AnswerAndActivity = ({ therapy }) => {
  const { getIdTokenClaims } = useAuth0();
  const [answerData, setAnswer] = useState([]);
  const [trainingPlanList, setTrainingPlanList] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState<any>();
  const [selectedId, setSelectedId] = useState([]);
  const [showTrainingPlan, setShowTrainingPlan] = useState(false);
  // const i18nextLng = localStorage.getItem('i18nextLng');

  const fetchTrainingPlan = async (therapyId) => {
    const idToken: any = await getIdTokenClaims();
    const trainingPlanList = await fetchFromRestAPI(`/api/v1/trainingplan/list/therapy/${therapyId}`, {
      token: idToken,
    });
    if (trainingPlanList && !trainingPlanList.error && trainingPlanList.length > 0) {
      setTrainingPlanList(trainingPlanList);
      setSelectedTraining(trainingPlanList[0]);
      setSelectedId(selectedId => [...selectedId, trainingPlanList[0]._id]);
    }
  };

  const fetchAnswers = async (therapyId) => {
    const idToken: any = await getIdTokenClaims();
    const maxData = await fetchFromRestAPI(`/api/v1/promquestions/analysis/graph/${therapyId}`, {
      token: idToken,
      method: 'POST',
    });
    setAnswer(maxData);
  };
  const handleViewTrainingPlan = (tp) => {
    setSelectedTraining(tp);
    setShowTrainingPlan(true);
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      if (therapy) {
        await fetchTrainingPlan(therapy.id);
        await fetchAnswers(therapy.id);
      }
    };
    fetchDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [therapy]);

  const handleTrainingSelected = (id, index) => {
    if (index < 0)
      setSelectedId(selectedId => [...selectedId, id]);
    else {
      const temp = [...selectedId];
      temp.splice(index, 1);
      setSelectedId(temp);
    }

  }

  const renderAnswer = (answeObj, index) => {
    if (answeObj && answeObj.promData) {
      return (
        <Col md={6} className="mb-3" key={index}>
          <Answer answer={answeObj} />
        </Col>
      );
    } else {
      return null;
    }
  };
  const renderExerciseModalBody = (e, index) => {
    return (
      <p key={'exercise_' + index} className='small pl-1 mb-1'>
        {e.name}
        <span className='float-right'>{e.exerciseType === 'relaxation' ? '' : e.sets ? e.sets + ' x ' : null}{e.exerciseType === 'relaxation' ? '' : e.time ? e.time + ' ' + e.unit : e.repetition ? e.repetition : null}</span>
      </p>
    )
  };
  const renderTrainingPlanModalBody = (t, index) => {
    return (
      <div key={'training_' + index}>
        {index !== 0 ? <p></p> : null}
        <p className='mb-0'><strong>{t.name}</strong></p>
        <p className='small pb-1 pl-1'>{t.workoutsPerWeek} x {i18next.t('answerAndActivity.viewTrainingPlanModal.workoutsPerWeek')}, {t.workoutPause} {i18next.t('answerAndActivity.viewTrainingPlanModal.workoutPause')}</p>
        {t.exercise ? t.exercise.map((e, j) => renderExerciseModalBody(e, j)) : null}
      </div>
    )
  };
  const renderTrainingPlan = (tp, index) => {
    return (
      <span key={'tp_' + index}>
        <Card key={'tp_' + index} className="bg-training-strip mb-2 pb-3 border-0">
          <Row className="p-1 pl-3 pr-3">
            <Col md={9} className="align-middle pt-3 pointer bg-training-strip-text" onClick={() => { handleTrainingSelected(tp._id, selectedId.indexOf(tp._id)) }} key={'t_' + index + '_' + selectedId}>
              {selectedId.indexOf(tp._id) >= 0 ? <i className="fas fa-angle-up" /> : <i className="fas fa-angle-down" />}  {i18next.t('answerAndActivity.trainingPlanFrom').replace('{startDate}', moment.parseZone(tp.startDate).format('DD-MM-YYYY')).replace('{endDate}', tp.status === 'PUBLISHED' ? moment.parseZone(tp.endDate).format('DD-MM-YYYY') : tp.unpublishDate ? moment.parseZone(tp.unpublishDate).format('DD-MM-YYYY') : moment.parseZone(tp.endDate).format('DD-MM-YYYY'))}  {tp.status === 'PUBLISHED' ? <Badge variant="success">{tp.status}</Badge> : null}
            </Col>
            <Col md={3} >
              <Button size="sm" variant="link" className="text-primary float-right mt-3 button-font" onClick={() => handleViewTrainingPlan(tp)}>{i18next.t('answerAndActivity.viewTrainingPlanBtn')}</Button>
            </Col>
          </Row>
        </Card>
        {selectedId.indexOf(tp._id) >= 0 ?
          <Row >
            <Col md={12} >
              <TrainingPlanAccordion trainingPlanData={tp} trainingPlanId={tp._id} trainingPlanStatus={tp.status}></TrainingPlanAccordion>
            </Col>
          </Row> : null}
      </span>
    );
  };

  return (
    <Wrapper>
      <WarningDiv>
        <span className="span-margin">
          <i className="fas fa-exclamation-triangle"></i>
        </span>
        <p>{i18next.t('answerAndActivity.warningText')}</p>
      </WarningDiv>
      <div className="banner">
        <h1>{i18next.t('answerAndActivity.answerTitle')}</h1>
      </div>
      <div>
        <Row>
          {answerData && !isEmpty(answerData) ? (
            answerData.map((answer, i) => renderAnswer(answer, i))
          ) : (
            <div className="pl-4">{i18next.t('answerAndActivity.noAnswer')}</div>
          )}
        </Row>
      </div>

      <div className="banner">
        <h1>{i18next.t('answerAndActivity.activityTitle')}</h1>
      </div>
      {trainingPlanList ? (
        <>
          {trainingPlanList.map((value, index) => renderTrainingPlan(value, index))}

        </>
      ) : (
        <div>{i18next.t('answerAndActivity.noActivity')}</div>
      )
      }
      {selectedTraining ?
        <Modal className='p-1' show={showTrainingPlan} onHide={() => setShowTrainingPlan(false)} key={selectedTraining.startDate}>
          <Modal.Header closeButton className='bg-light'>
            <Modal.Title><h3>
              {selectedTraining ? i18next.t('answerAndActivity.trainingPlanFrom').replace('{startDate}', moment(selectedTraining.startDate).format('DD-MM-YYYY')).replace('{endDate}', selectedTraining.status === 'PUBLISHED' ? moment(selectedTraining.endDate).format('DD-MM-YYYY') : selectedTraining.unpublishDate ? moment(selectedTraining.unpublishDate).format('DD-MM-YYYY') : moment(selectedTraining.endDate).format('DD-MM-YYYY')) : null} </h3></Modal.Title>
          </Modal.Header>
          <Modal.Body className='m-2'>
            <ModalBodyDiv>
              {selectedTraining ? selectedTraining.trainings.map((t, i) => renderTrainingPlanModalBody(t, i)) : null}
            </ModalBodyDiv>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowTrainingPlan(false)}>
              {i18next.t('generic.close')}
            </Button>
          </Modal.Footer>
        </Modal>
        : null}

    </Wrapper >
  );
};

export default memo(AnswerAndActivity);
