import React, { FC } from 'react';
import styled from 'styled-components';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from './Controls/EndCallButton';
import MainParticipant from './MainParticipant';
import ControlsBar from './Controls/ControlsBar';
import ParticipantSwitch from './ParticipantSwitch';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #002b4b;
  display: grid;
  grid-template-areas:
    'mainParticipant'
    'bottomControlsBar';
  grid-template-rows: 1fr 7em;
  grid-template-columns: 1fr;
`;

const MainParticipantContainer = styled.div`
  position: relative;
  grid-area: mainParticipant;
  display: flex;
  justify-content: center;
  margin: 6em 1em 0em 1em;
`;

const BottomControlsBar = styled.div`
  position: relative;
  grid-area: bottomControlsBar;
`;

const ParticipantsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  z-index: 19;
  width: 180px;
  height: auto;
  bottom: 5rem;
  right: 5rem;
`;

const GroupRoomP2P: FC = () => {
  const { isCallHost, isP2P, pinCode } = useVideoContext();

  return (
    <Container>
      <EndCallButton pin={pinCode} />
      <MainParticipantContainer id="main-video-container">
        <MainParticipant />
        <ParticipantsContainer>
          <ParticipantSwitch isHighlighted />
        </ParticipantsContainer>
      </MainParticipantContainer>
      <BottomControlsBar>
        <ControlsBar isCallHost={isCallHost} isConnected isP2P={isP2P} />
      </BottomControlsBar>
    </Container>
  );
};

export default GroupRoomP2P;
