import React from 'react';
import styled from 'styled-components';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import RechargeCredits from './RechargeCredits';
import Therapists from './Therapists';
import AdminVideoList from './AdminVideoList';
import AppManagement from './APPManagement';
import DeletePatient from './DeletePatient';
import InsurancePlan from './InsurancePlan';
import QuestionsInsurancePlan from './QuestionsInsurancePlan';

const Wrapper = styled.div`
  max-width: 90vw;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 2rem;
  .tab-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const AdminPage = () => {
  return (
    <Wrapper>
      <Tabs defaultActiveKey="therapists" id="admin-sections" mountOnEnter={true}>

        <Tab eventKey="therapists" title="Therapists">
          <Therapists />
        </Tab>
        <Tab eventKey="insurancePlan" title="Insurance Plans">
          <InsurancePlan />
        </Tab>
        <Tab eventKey="insuranceQuestion" title="Insurance Questions">
          <QuestionsInsurancePlan />
        </Tab>

        <Tab eventKey="recharge" title="Recharge Credits">
          <RechargeCredits />
        </Tab>
        <Tab eventKey="videoList" title="Video List">
          <AdminVideoList />
        </Tab>
        <Tab eventKey="appManagement" title="App Management">
          <AppManagement />
        </Tab>
        <Tab eventKey="deletePatient" title="Delete Patient">
          <DeletePatient />
        </Tab>
      </Tabs>
    </Wrapper>
  );
};

export default AdminPage;
