import React, { FC } from 'react';
import { Participant } from 'twilio-video';
import styled from 'styled-components';
import { FaSignal } from 'react-icons/fa';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import ControlsTooltip from './Controls/ControlsToolTip';

const IndicatorWrapper = styled.span`
  width: 22px;
  height: 22px;
  font-size: 12px;
  line-height: 22px;
  color: #fff;
  border-radius: 100%;
  background-color: ${(props: { color: string }) => (props.color ? props.color : '#595959')};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border: 2px solid #fff;
`;

const ParticipantNetworkIndicator: FC<{ participant: Participant }> = ({ participant }) => {
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  const getColorByQuality = () => {
    switch (networkQualityLevel) {
      case 5:
      case 4:
        return 'green';
      case 3:
        return 'orange';
      default:
        return 'darkred';
    }
  };

  const getQualityTooltip = () => {
    switch (networkQualityLevel) {
      case 5:
      case 4:
        return 'gute Netzwerkverbindung';
      case 3:
        return 'eingeschränkte Netzwerkverbindung';
      default:
        return 'schlechte Netzwerkverbindung';
    }
  };

  return (
    <ControlsTooltip id="tooltip-toggle-audio" label={getQualityTooltip()}>
      <IndicatorWrapper color={getColorByQuality()}>
        <FaSignal />
      </IndicatorWrapper>
    </ControlsTooltip>
  );
};

export default ParticipantNetworkIndicator;
