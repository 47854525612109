import React, { FC } from 'react';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from './AudioTrack';

import { AudioTrack as IAudioTrack, LocalTrackPublication, Participant, RemoteTrackPublication } from 'twilio-video';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocal: boolean;
}

const PublicationAudio: FC<PublicationProps> = ({ publication, isLocal }) => {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case 'audio':
      return <AudioTrack track={track as IAudioTrack} />;
    default:
      return null;
  }
};

export default PublicationAudio;
