import React, { FC } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { showUpgradeModel } from '../util';

const UpgradeNotification: FC = () => {
  const history = useHistory();
  const handleClose = () => {
    showUpgradeModel(false)
  }
  const navigateToAccount = () => {
    showUpgradeModel(false)
    history.push(`/account?billing=true`);
  }
  return (
    <div className="custom-modal modal fade" id="upgradeModal" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="upgradeNotificationTitle">{i18next.t('errorMessageTemplate.key_TherapistPlanUpgradeTitle')}</h4>
            <button type="button" className="close" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" id="upgradeNotificationBody">
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-secondary float-left" data-dismiss="modal" onClick={handleClose}>{i18next.t('planDetails.upgradeCancelButton')}</button>
            <button type="button" className="btn btn-primary float-right" onClick={navigateToAccount}>{i18next.t('planDetails.upgradePlanButton')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeNotification;
