import React, { memo, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { Card, Form, InputGroup, Row, Col, Button, ButtonGroup, Accordion } from 'react-bootstrap';
import { useAuth0 } from '../../util/auth0';
import { fetchFromRestAPI } from '../../util/api';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import i18next from 'i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { debounce, map } from 'lodash';

const buttonStyle = {
  padding: '0.6rem 0.8rem',
  fontSize: '0.9rem'
};
const catNameStyle = {
  fontWeight: 600,
  fontSize: '1.3rem',
}
const filterTextStyle = {
  fontSize: '1.5rem',
  fontWeight: 600,
};
const clearFilterStyle = {
  fontSize: '1.3rem',
}

const FilterDiv = styled.div`
    font-family: Monserat, Helvetica, serif;
    font-style: normal;
    font-weight: normal;
    line-height: 20pt;
    font-size:1.2rem;
`;
const FilterBody = styled.div`
max-height: 67vh;
overflow-y: auto;
overflow-x: hidden;

`;
const VideoDiv = styled.div`
max-height: 70vh;
overflow-y: auto;
overflow-x: hidden;
padding-right:8px;
.card-footer{
  min-height: 75px;
  overflow-y: hidden;
  overflow-x: hidden;
}
`;
const TagLabel = styled.label`
    font-family: Monserat, Helvetica, serif;
    font-style: normal;
    font-weight: normal;
    line-height: 10pt;
    font-size: 1.2rem;
`;
const VideoExerciseModal = ({ categoryList, tagList, show, onPositive, onNegative }) => {
  const { getIdTokenClaims } = useAuth0();
  const [idx, setIdx] = useState("0");
  const [videoList, setVideoList] = useState([]);
  const [selectedVideoList, setSelectedVideoList] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [filterTagList, setFilterTagList] = useState({});
  const [hasMore, setHasMore] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [filterPageNo, setFilterPageNo] = useState(0);
  const i18nextLng = localStorage.getItem('i18nextLng');
  const userLanguage = i18nextLng && i18nextLng.includes("-") ? i18nextLng.split("-")[0] : i18nextLng;
  const enduranceExercise = {
    enName: i18next.t('exerciseModal.enduranceExercise.enName'),
    enDescription: i18next.t('exerciseModal.enduranceExercise.enDescription'),
    deName: i18next.t('exerciseModal.enduranceExercise.deName'),
    deDescription: i18next.t('exerciseModal.enduranceExercise.deDescription'),
    videoUrl: null,
    isDynamic: true,
    exerciseType: 'endurance',
    skipPause: false,
  };
  const staticExercise = {
    enName: i18next.t('exerciseModal.customExercise.enName'),
    enDescription: i18next.t('exerciseModal.customExercise.enDescription'),
    deName: i18next.t('exerciseModal.customExercise.deName'),
    deDescription: i18next.t('exerciseModal.customExercise.deDescription'),
    videoUrl: null,
    isDynamic: true,
    exerciseType: 'dynamic',
    skipPause: false
  };

  useEffect(() => {
  }, [filterPageNo]);

  useEffect(() => {
    if (show) {
      setPageNo(0);
      setFilterPageNo(0);
      setVideoList([]);
      setHasMore(true);
      const fetchDataAsync = async () => {
        await handleOnTagCheckChange();
      };
      fetchDataAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, searchKey, filterTagList]);
  const getObjPropForMatchPrefixKey = (object, prefix) => Object.keys(object).filter(item => item.toString().startsWith(prefix));

  async function fetchVideoData() {
    const token = await getIdTokenClaims();
    const videoData = await fetchFromRestAPI(`/api/v1/exercise/video/` + pageNo, {
      token,
    });
    if (videoData && videoData.length > 0) {
      if (pageNo === 0) {
        setVideoList(videoData);
      } else {
        const newArray = [...videoList, ...videoData];
        setVideoList(newArray);
      }

      setHasMore(videoData.length === 20);
      if (videoData.length === 20) {
        setPageNo(pageNo + 1);
      }
    } else {
      if (pageNo === 0) {
        setVideoList([]);
      }
      setHasMore(false);
    }
  }
  const handleOnTagCheckChange = async () => {
    if (Object.keys(filterTagList).length || searchKey.length >= 3) {
      await filterExerciseVideo();
    } else {
      await fetchVideoData();
    }
  };
  async function filterExerciseVideo() {
    const token = await getIdTokenClaims();
    const videoData = await fetchFromRestAPI('/api/v1/exercise/video/filter/' + filterPageNo, {
      method: 'POST',
      body: { 'filter': filterTagList, 'searchKey': searchKey },
      token,
    });
    if (videoData && videoData.length > 0) {
      if (filterPageNo === 0) {
        setVideoList(videoData);
      } else {
        const newArray = [...videoList, ...videoData];
        setVideoList(newArray);
      }
      setHasMore(videoData.length === 20);
      if (videoData.length === 20) {
        setFilterPageNo(filterPageNo + 1);
      }
    } else {
      if (filterPageNo === 0) {
        setVideoList([]);
      }
      setHasMore(false);
    }
  }
  const renderFilters = (category, index) => {
    return (
      <Row key={'Filter-' + index}>
        <Col md={12}>
          <Accordion.Toggle as={Button} variant="link" className=" w-100" eventKey={index.toString()} onClick={() => {
            idx === index.toString() ? setIdx("") : setIdx(index.toString());
          }}> <Row>
              <Col md={9} xs={7} sm={8} lg={10} className="text-left text-wrap">

                <h4 style={catNameStyle} className="pb-1 pt-1">{userLanguage === 'en' ? category.en : category.de}</h4>
              </Col> <Col md={3} xs={5} sm={4} lg={2}>
                <span key={"Filter-Title" + idx} className="pointer float-right">
                  {idx === index.toString() ? (
                    <i className="fas fa-1x fa-caret-up"></i>
                  ) : (
                    <i className="fas fa-1x fa-caret-down"></i>
                  )}
                </span></Col></Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index.toString()}>
            <Row>
              <Col md={12} className="ml-2">
                {renderTags(category._id)}
              </Col>
            </Row>
          </Accordion.Collapse>
        </Col>
      </Row>
    )
  };
  const onChangeSelectVideo = (e, video) => {
    if (e) {
      setSelectedVideoList(selectedVideoList => [...selectedVideoList, video])
    } else {
      const index = selectedVideoList.findIndex(({ _id }) => _id === video._id);
      if (index !== -1) {
        setSelectedVideoList([
          ...selectedVideoList.slice(0, index),
          ...selectedVideoList.slice(index + 1)
        ]);
      }
    }
  }

  const onChangeSearchInput = (e) => {
    if (e.target.value.length >= 3) {
      debouncedSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      debouncedSearch('');
    }
  };
  const debouncedSearch = debounce(function (query) {
    setSearchKey(query);
  }, 1000);
  const renderSelectionButton = (video, index) => {
    return (
      <ButtonGroup key={'button_' + index + '_' + selectedVideoList.length} className="float-right">
        {selectedVideoList.findIndex(({ _id }) => _id === video._id) !== -1 ?
          <Button
            className="btn btn-primary btn-xs btn-circle float-right"
            onClick={() => onChangeSelectVideo(false, video)}
          > <i className="fas fa-check"></i>

          </Button> : <Button
            className="btn btn-primary btn-xs btn-circle float-right"
            onClick={() => onChangeSelectVideo(true, video)}
          >
            <i className="fas fa-plus"></i>
          </Button>}
      </ButtonGroup>

    );
  };
  const renderVideoCard = (video, index) => {
    return (
      <Col md={6} xl={4} sm={6} className='mb-3' xs={12} key={'VideoCard-' + index}>
        <Card className={selectedVideoList.findIndex(({ _id }) => _id === video._id) !== -1 ? 'border border-danger primary-border-2 ' : 'border-2'}>
          <Video key={video._id} loop muted preload={'none'}
            type={'video/mp4'}
            controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
            poster={video.hThumbnail ? video.hThumbnail : '/video_thumbnail.png'}>
            <source src={video.horizontalUrl} type="video/mp4" />
          </Video>
          <Card.Footer>
            <Row className="align-items-center">
              <Col xl={10} md={9} sm={9} className="pr-1 font-15">
                <b>{userLanguage === "en" ? video.enName : video.deName}</b>
              </Col>
              <Col xl={2} md={3} sm={3} className="text-right pr-2" >
                {renderSelectionButton(video, index)}

              </Col>
            </Row>

          </Card.Footer>
        </Card>
      </Col>
    );
  };

  const renderTagsCheckBox = (t, index) => {
    return (
      <Row key={'TagCheck-' + index}>
        <Col md={12}>
          <TagLabel>
            <Form.Check key={t._id} checked={selectedTag.indexOf(t._id) >= 0} inline
              label={userLanguage === "en" ? t.en : t.de} name={t.en} type="checkbox" id={t.en} onChange={e => {
                if (e.target.checked) {
                  if (filterTagList[t.categoryId]) {
                    const iArray = filterTagList[t.categoryId].indexOf(t._id);
                    if (iArray >= 0)
                      filterTagList[t.categoryId].splice(iArray, 1);
                    else
                      filterTagList[t.categoryId].push(t._id);

                  } else {
                    filterTagList[t.categoryId] = [t._id];
                  }
                  if (selectedTag.indexOf(t._id) >= 0)
                    selectedTag.splice(selectedTag.indexOf(t._id), 1);
                  else
                    selectedTag.push(t._id);
                } else {
                  const iArray = filterTagList[t.categoryId].indexOf(t._id);
                  if (iArray >= 0)
                    filterTagList[t.categoryId].splice(iArray, 1);

                  if (selectedTag.indexOf(t._id) >= 0)
                    selectedTag.splice(selectedTag.indexOf(t._id), 1);

                }
                if (filterTagList[t.categoryId].length <= 0)
                  delete filterTagList[t.categoryId];
                setFilterPageNo(0);
                setPageNo(0);
                setFilterTagList(filterTagList);
                setSelectedTag(selectedTag);
                setVideoList([]);
                setHasMore(true);
              }} />
          </TagLabel>
        </Col>
      </Row>

    )
  };

  const renderTagDiv = (tag, index) => {
    return (
      <span key={'TagDiv-' + index}>
        {map(tag, (t, i) => renderTagsCheckBox(t, i))}
      </span>
    )
  };

  const renderTags = (catId) => {
    const tags = getObjPropForMatchPrefixKey(tagList, catId);
    return (
      <span key={'TagLine-' + catId}>
        {map(tags, (t, i) => renderTagDiv(tagList[t], i))}
      </span>
    )
  };
  return (
    <Modal size="xl" dialogClassName="modal-90w" show={show} onHide={() => {
      setFilterTagList({});
      setSelectedTag([]); onNegative();
    }}>
      <Modal.Header closeButton={false} className="bg-light">
        <Modal.Title className="p-3"><h2>{i18next.t('exerciseModal.title')}</h2>
        </Modal.Title>
        <Button type="button" onClick={() => {
          setFilterTagList({});
          setSearchKey('');
          setSelectedTag([]); onNegative(); setSelectedVideoList([]);
        }} className="close mt-2 mr-2 text-dark"><i className="fa fa-times fa-1x text-dark" aria-hidden="true"></i></Button>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4} xl={3} xs={4} className="mb-3 border-right" key="FilterCol">
            <FilterDiv>
              <Card key='catCard' className="border-0">
                <Card.Header className="border-bottom-0 bg-transparent pl-3 pr-0 mt-2">
                  <Form.Label className='font-weight-bold' style={filterTextStyle}>{i18next.t('exerciseModal.filterText')}</Form.Label>
                  <Button disabled={searchKey.length <= 0 && Object.keys(filterTagList).length <= 0} variant="link" size="sm" className="float-right pt-0" style={clearFilterStyle} onClick={e => {
                    setFilterTagList({});
                    setSelectedTag([]);
                  }}>{i18next.t('exerciseModal.filterClearText')}</Button>
                </Card.Header>
                <Card.Body className="pt-0 pl-3">
                  <FilterBody>
                    <Accordion defaultActiveKey="0" className="filterDiv">
                      {map(categoryList, (category, i) => renderFilters(category, i))}

                    </Accordion>
                  </FilterBody>
                </Card.Body>
              </Card>
            </FilterDiv>
          </Col>

          <Col md={8} xl={9} xs={8} className="mb-3" key="MainCol">
            <div>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search..."
                  name="search"
                  key={'search' + searchKey}
                  defaultValue={searchKey}
                  autoFocus={true}
                  onChange={onChangeSearchInput}
                />
                {searchKey.length > 0 ?
                  <InputGroup.Prepend onClick={e => { setSearchKey('') }}>
                    <InputGroup.Text>
                      <i className="fas fa-backspace"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend> : null}
                <InputGroup.Prepend onClick={e => { if (searchKey.length >= 3) handleOnTagCheckChange() }} >
                  <InputGroup.Text>
                    <i className="fas fa-search"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>

              </InputGroup>

            </div>
            <VideoDiv>
              <InfiniteScroll
                className="row"
                pageStart={0}
                loadMore={(page) => {
                  handleOnTagCheckChange();
                }}
                hasMore={hasMore}
                useWindow={false}
                loader={<Col md={12} className="loader text-center" key={0}><i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true" /></Col>}
              >
                <Col md={6} xl={6} xs={12}>
                  <Card bsPrefix="card mb-4">
                    <Card.Body>
                      <p className="text-center">
                        <button
                          className="btn btn-primary btn-xs rounded-circle"
                          style={buttonStyle}
                          onClick={() => onPositive(staticExercise, true)}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </p>
                      <p className="text-center font-15">
                        <b>{i18next.t('exerciseModal.addStaticExercise')}</b>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} xl={6} xs={12}>
                  <Card>
                    <Card.Body>
                      <p className="text-center">
                        <button
                          className="btn btn-primary btn-xs rounded-circle"
                          style={buttonStyle}
                          onClick={() => onPositive(enduranceExercise, true)}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </p>
                      <p className="text-center font-15">
                        <b>{i18next.t('exerciseModal.addEnduranceExercise')}</b>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                {map(videoList, (video, i) => renderVideoCard(video, i))}
              </InfiniteScroll>
            </VideoDiv>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer style={{ background: '#F1F1F4' }}>
        <Button key={selectedVideoList.length} disabled={selectedVideoList.length <= 0} type="button" onClick={() => {
          setFilterTagList({});
          setSelectedTag([]);
          //onNegative();
          onPositive(selectedVideoList, false);
          setSelectedVideoList([]);
        }} className={selectedVideoList.length <= 0 ? "mt-2 mr-2 btn btn-secondary" : "mt-2 mr-2 btn btn-primary"} >{i18next.t('exerciseModal.selectionDone')} {selectedVideoList.length > 0 ? '(' + selectedVideoList.length + ')' : null}</Button>

      </Modal.Footer>
    </Modal>
  );
};

export default memo(VideoExerciseModal);