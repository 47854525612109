import i18next from 'i18next';
import React, { FC, memo } from 'react';
import { Card, Button, Col } from "react-bootstrap";
type Props = {
    plan: any,
    buttonText:string,
    selectPlan: (planId) => void;
};
const PlanSelectComponent: FC<Props> = ({ plan, buttonText, selectPlan }) => {

    const i18nextLng = localStorage.getItem('i18nextLng');
    const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
    const points = userLanguage === 'de' ? plan.planPointsDE : plan.planPointsEN

    return (
        <Col className="mt-2">
            <Card className="mb-3 text-center border-0 plan-price-card">
                <Card.Header className="plan-trial-heading p-0 m-0">  <h4 className='font-weight-bold pt-2'>
                    <span dangerouslySetInnerHTML={{ __html: userLanguage === 'de' ? plan.descriptionDE : plan.descriptionEN }}></span></h4></Card.Header>
                <Card.Body>
                    <h3 className="font-weight-bold">{userLanguage === 'de' ? plan.planNameDE : plan.planNameEN}</h3>
                    <h3 className="card-title pricing-card-title font-weight-bold">{i18next.t('planDetails.currencySymbol')} {plan.planPrice} <small className="text-muted primary-text-color">/ {plan.planBilled}</small></h3>
                    <ul className="price-list list-unstyled mt-3 mb-4 pl-5 pr-5">
                        {points.map((p, i) => {
                            return <li className="pl-2" key={i}><span dangerouslySetInnerHTML={{ __html: p }}></span></li>
                        })}
                    </ul></Card.Body>
                <Card.Footer className="border-0 plan-price-card-footer">
                    {plan.isRecommended === true ?
                        <Button type="button" variant='primary' onClick={() => selectPlan(plan)} className="btn btn-lg btn-block">{buttonText}</Button>
                        : <Button type="button" variant='outline-primary' onClick={() => selectPlan(plan)} className="btn btn-lg btn-block">{buttonText}</Button>}
                </Card.Footer>
            </Card>	</Col>);
};

export default memo(PlanSelectComponent);