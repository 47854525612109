import React, { FC, useState } from 'react';
import { Participant } from 'twilio-video';
import styled from 'styled-components';
import { FaMicrophone, FaMicrophoneSlash } from 'react-icons/fa';

interface IndicatorWrapperProps {
  muted?: boolean;
}

const IndicatorWrapper = styled.span<IndicatorWrapperProps>`
  width: 14px;
  height: 14px;
  font-size: 10px;
  line-height: 22px;
  color: #fff;
  border-radius: 100%;
  background-color: ${(props: IndicatorWrapperProps) => (props.muted ? '#595959' : '#ff695f')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ParticipantAudioIndicator: FC<{ participant: Participant }> = ({ participant }) => {
  const [iAmMuted, setIAmMuted] = useState(false);

  const audioIndicatorOnTrackEnabled = (track) => {
    if (track && track.kind === 'audio') {
      setIAmMuted(false);
    }
  };

  const audioIndicatorOnTrackDisabled = (track) => {
    if (track && track.kind === 'audio') {
      setIAmMuted(true);
    }
  };

  if (participant && participant.audioTracks) {
    if (!participant.listeners('trackEnabled').filter((f) => f.name === 'audioIndicatorOnTrackEnabled').length) {
      participant.on('trackEnabled', audioIndicatorOnTrackEnabled);
    }

    if (!participant.listeners('trackDisabled').filter((f) => f.name === 'audioIndicatorOnTrackDisabled').length) {
      participant.on('trackDisabled', audioIndicatorOnTrackDisabled);
    }

    setTimeout(() => {
      participant.audioTracks.forEach((track) => {
        if (!track.isTrackEnabled) {
          setIAmMuted(true);
        }
      });
    }, 1000);
  }

  return <IndicatorWrapper muted={iAmMuted}>{iAmMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}</IndicatorWrapper>;
};

export default ParticipantAudioIndicator;
