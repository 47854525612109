import React, { FC } from 'react';
import { Participant } from 'twilio-video';
import styled from 'styled-components';

import ParticipantAudioIndicator from './ParticipantAudioIndicator';
import ParticipantTrainerIndicator from './ParticipantTrainerIndicator';

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
}

interface ParticipantWrapperProps {
  isSelected?: boolean;
}

const ParticipantWrapper = styled.div<ParticipantWrapperProps>`
  display: flex;
`;

const ParticipantStatusContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
`;

const ParticipantInfo: FC<ParticipantInfoProps> = ({ participant, onClick, isSelected, children }) => {
  return (
    <ParticipantWrapper onClick={onClick} isSelected={isSelected}>
      <ParticipantStatusContainer>
        <ParticipantTrainerIndicator participant={participant} />
        {/*<ParticipantConnectionIndicator participant={participant} />*/}
        <ParticipantAudioIndicator participant={participant} />
      </ParticipantStatusContainer>
      {children}
    </ParticipantWrapper>
  );
};

export default ParticipantInfo;
