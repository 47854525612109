import React, { FC, useState, memo } from 'react';
import styled from 'styled-components';
import { Form, Col, Tab, Nav } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import i18next from 'i18next';
import PrimaryButton from '../PrimaryButton';
import { map } from 'lodash';
import update from 'immutability-helper';
import VideoExerciseModal from './VideoExerciseModal';
import moment from 'moment';
import DeleteTrainingModal from './DeleteTrainingModal';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import SecondaryButton from '../SecondaryButton';
import LoadTemplateModal from './LoadTemplateModel';
import SaveTemplateModel from './SaveTemplateModel';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ExerciseCardContainer } from './ExcerciseCardContainer';

const Wrapper = styled.div`
  background-color: white;
  padding: 1.6rem 1.6rem 0rem 1.6rem;

  .nav-tabs {
    border-bottom: 0px white;
  }

  .nav-tabs .nav-link {
    border: 0px white;
    padding: 1rem 1rem 1rem 1rem;
    color: #000000;
  }

  .nav-tabs .nav-link.active {
    border-bottom: 3px red solid !important;
    color: #ff695f;
  }
`;

const TabContentWrapper = styled.div`
  background-color: #f6f6f8;
  padding: 1.6rem;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
`;

const DeleteTrainingSpan = styled.span`
  float: right;
  color: red;
  cursor: pointer;
`;

const ExerciseContainerDiv = styled.div`
  padding: 4rem 0rem 0rem 0rem;
  width: 100%;
`;

const CenterParagraph = styled.p`
    text-align: center;
`;

const TraininPlanForm: FC<{ updateTrainings: (tp: any, tml: any[]) => Promise<void>; training; saveTrainingTemplate: (isGlobalTemplate: boolean, templateName: string) => Promise<void>; }> = ({ training, updateTrainings, saveTrainingTemplate }) => {
    const trainings = training.trainings;
    const { getIdTokenClaims } = useAuth0();
    const [key, setKey] = useState(0);
    const [tabIndex, setTabIndex] = useState(0);
    const [showAddExerciseModal, setShowAddExerciseModal] = useState(false);
    const [showTemplateModal, setShowTempateModal] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isGlobalTemplate, setIsGlobalTemplate] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [templateList, setTemplateList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const i18nextLng = localStorage.getItem('i18nextLng');
    const userLanguage = i18nextLng && i18nextLng.includes("-") ? i18nextLng.split("-")[0] : i18nextLng;
    async function fetchCategory(token) {
        const response = await fetchFromRestAPI(`/api/v1/exercise/video/category`, {
            method: 'GET',
            token,
        });
        return response;
    }
    async function fetchTag(token) {
        const response = await fetchFromRestAPI(`/api/v1/exercise/video/tag`, {
            method: 'GET',
            token,
        });
        return response;
    }
    const handleShowAddExerciseModal = () => {
        if (categoryList.length <= 0) {
            getIdTokenClaims().then((idToken) => {
                fetchCategory(idToken).then((response) => {
                    if (response) {
                        setCategoryList(response);
                        fetchTag(idToken).then((tags) => {
                            if (tags) {
                                setTagList(tags);
                            }
                            else {
                                setTagList([]);
                            }
                            setShowAddExerciseModal(true);
                        });
                    }
                    else {
                        setCategoryList([]);
                        setShowAddExerciseModal(true);
                    }
                });
            });
        } else {
            setShowAddExerciseModal(true);
        }
    }
    const handleLoadTemplates = async (isGlobal = false) => {
        setTemplateList([]);
        const idToken = await getIdTokenClaims();
        let templateData = null;
        setIsGlobalTemplate(isGlobal);
        if (isGlobal) {
            templateData = await fetchFromRestAPI(`/api/v1/trainingplan/templates/list`, {
                token: idToken,
            });
        } else {
            templateData = await fetchFromRestAPI(`/api/v1/trainingplan/templates/list/therapist`, {
                token: idToken,
            });
        }
        if (templateData && templateData.length > 0) {
            setTemplateList(templateData);
        } else {
            setTemplateList([]);
        }
    }
    const handleDisableTemplate = async (id, isGlobal) => {
        const idToken = await getIdTokenClaims();
        await fetchFromRestAPI(`/api/v1/trainingplan/templates/remove/${id}`, {
            method: 'DELETE',
            token: idToken,
        });
        await handleLoadTemplates(isGlobal);
        return true;
    }
    const handleAddTab = () => {
        if (trainings.length < 4) {
            const newTabObject = {
                name: `${i18next.t('trainingPlan.newTabTraning')} ${trainings.length + 1}`,
                workoutsPerWeek: 3,
                workoutPause: 60,
                exercise: [],
            };

            training.trainings = [...trainings, newTabObject];
            updateTrainings(JSON.parse(JSON.stringify(training)), []);
            setKey(trainings.length);
            setTabIndex(trainings.length);
        }
    };

    const deleteTraining = (index) => {
        if (trainings.length > 1) {
            trainings.splice(index, 1);
            training.trainings = trainings;
            updateTrainings(JSON.parse(JSON.stringify(training)), []);
            setKey(trainings.length - 1);
            setTabIndex(trainings.length - 1);
        } else {
            // Min of 1 training is needed.
        }
        setShowConfirmModal(false);
    };
    const saveTemplate = (isGlobalTemplate, templateName) => {
        saveTrainingTemplate(isGlobalTemplate, templateName);
        setShowSaveModal(false);
    }
    const addTemplateToTraining = (selectedTraining) => {
        if (trainings.length < 4) {
            const newList = [];
            selectedTraining.forEach(element => {
                const newTabObject = {
                    name: element.name,
                    workoutsPerWeek: element.workoutsPerWeek,
                    workoutPause: element.workoutPause,
                    exercise: element.exercise,
                };
                newList.push(newTabObject);
            });
            updateTrainings(JSON.parse(JSON.stringify(training)), newList);
            setKey(trainings.length);
            setTabIndex(trainings.length);
            setShowTempateModal(false);

        }
        setShowTempateModal(false);
    }

    const handleTabChange = (activeKey) => {
        setKey(activeKey);
        setTabIndex(activeKey);
    };

    const handleTrainingChange = (event, tabIndex) => {
        const fieldName = event.target.name;
        const fleldValue = event.target.value;
        trainings[tabIndex][fieldName] = fleldValue;
        training.trainings = trainings;
        updateTrainings(JSON.parse(JSON.stringify(training)), []);
        setValidated(true);
    };

    const moveCard = (dragIndex, hoverIndex, tabIndex) => {
        const dragCard = trainings[tabIndex].exercise[dragIndex];
        const eList = update(trainings[tabIndex].exercise, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        });
        trainings[tabIndex].exercise = eList;
        training.trainings = trainings;
        updateTrainings(JSON.parse(JSON.stringify(training)), []);
    };

    const removeExercise = (index, tabIndex) => {
        const exerciseList = trainings[tabIndex].exercise;
        exerciseList.splice(index, 1);
        trainings[tabIndex].exercise = exerciseList;
        training.trainings = trainings;
        updateTrainings(JSON.parse(JSON.stringify(training)), []);
    };

    const addExercise = (video, isCustom) => {
        if (isCustom) {
            const exerciseObj = {
                eid: moment().valueOf() + Math.random(),
                name: userLanguage === 'en' ? video.enName : video.deName,
                description: userLanguage === 'en' ? video.enDescription : video.deDescription,
                video: isCustom ? null : video,
                isDynamic: video.isDynamic,
                customDesc: isCustom ? true : false,
                sets: video.exerciseType === 'endurance' ? undefined : 0,
                repetition: 0,
                time: 0,
                unit: video.exerciseType === 'endurance' ? 'min' : (video.isDynamic ? 'reps' : 'sec'),
                exerciseType: video.exerciseType,
                skipPause: video.skipPause
            };
            trainings[tabIndex].exercise.push(exerciseObj);
            training.trainings = trainings;
            setShowAddExerciseModal(false);
        } else if (video.length > 0) {
            video.forEach((element) => {
                const exerciseObj = {
                    eid: moment().valueOf() + Math.random(),
                    name: userLanguage === 'en' ? element.enName : element.deName,
                    description: userLanguage === 'en' ? element.enDescription : element.deDescription,
                    video: element,
                    isDynamic: element.isDynamic,
                    customDesc: false,
                    sets: element.exerciseType === 'endurance' ? undefined : 0,
                    repetition: 0,
                    unit: element.exerciseType === 'endurance' ? 'min' : (element.isDynamic ? 'reps' : 'sec'),
                    time: 0,
                    exerciseType: element.exerciseType,
                    skipPause: false
                };
                trainings[tabIndex].exercise.push(exerciseObj);
                training.trainings = trainings;
                setShowAddExerciseModal(false);
            });
            updateTrainings(JSON.parse(JSON.stringify(training)), []);
        } else {
            setShowAddExerciseModal(false);
        }
        window.scrollTo({
            top: document.body.clientHeight + 200,
            left: 0,
            behavior: 'smooth'
        });
    };

    const updateExercise = (index, tabIndex, exercise) => {
        const newTrainingObj = JSON.parse(JSON.stringify(trainings))
        newTrainingObj[tabIndex].exercise[index] = exercise;
        training.trainings = newTrainingObj;
        updateTrainings(JSON.parse(JSON.stringify(training)), []);
    }



    return (
        <Tab.Container id="exercise-tabs" defaultActiveKey={key} activeKey={key} onSelect={handleTabChange}>
            <VideoExerciseModal
                categoryList={categoryList}
                tagList={tagList}
                show={showAddExerciseModal}
                onPositive={addExercise}
                onNegative={() => setShowAddExerciseModal(false)}
            />
            <Wrapper>
                <h1 className="hidden-lg">
                    <b>{i18next.t('trainingPlanForm.title')}</b>
                </h1>
                <h1>
                    <b className="hidden-xs">{i18next.t('trainingPlanForm.title')}</b>
                    <PrimaryButton className='float-right' onClick={handleAddTab} disabled={trainings.length >= 4}>
                        + <span className="hidden-xs">{i18next.t('trainingPlanForm.addTraining')}</span>
                    </PrimaryButton>
                    <SecondaryButton className="float-right mr-3" onClick={() => { handleLoadTemplates(isGlobalTemplate); setShowTempateModal(true); }} disabled={trainings.length > 4}>
                        <i className='fas fa-folder' /> <span className="hidden-xs">{i18next.t('trainingPlanForm.loadTemplate')}</span>
                    </SecondaryButton>
                    <SecondaryButton className="float-right mr-3" onClick={() => { setShowSaveModal(true) }} disabled={trainings.length > 4}>
                        <i className='fas fa-clone' /> <span className="hidden-xs">{i18next.t('trainingPlanForm.saveAsTemplate')}</span>
                    </SecondaryButton>
                </h1>
                <Nav variant="tabs" className="clear mt-2">
                    {map(trainings, (tab, i) => {
                        return (
                            <Nav.Item key={'nav-' + i}>
                                <Nav.Link key={'navLink-' + i} eventKey={i}>
                                    <h3>
                                        <b>{tab.name}</b>
                                    </h3>
                                    <h5>{tab.workoutsPerWeek}x {i18next.t('trainingPlanForm.perWeek')}</h5>
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
                </Nav>
            </Wrapper>
            <TabContentWrapper>
                <Tab.Content key={'TabContent-' + trainings.length}>
                    {map(trainings, (tab, i) => {
                        return (
                            <Tab.Pane key={'Pane-' + i} eventKey={i}>
                                <Form noValidate validated={validated}>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Label>{i18next.t('trainingPlanForm.trainingName')}</Form.Label>
                                            <Form.Control
                                                required
                                                defaultValue={tab.name}
                                                type="text"
                                                name="name"
                                                id={`TrainingName-${i}`}
                                                placeholder={i18next.t('trainingPlanForm.trainingName')}
                                                onChange={(Event) => handleTrainingChange(Event, i)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {i18next.t('trainingPlanForm.trainingNameValidation')}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>{i18next.t('trainingPlanForm.workoutsPerWeek')}</Form.Label>
                                            <Form.Control
                                                required
                                                type="number"
                                                name="workoutsPerWeek"
                                                id={`workoutsPerWeek-${i}`}
                                                min="1"
                                                max="7"
                                                defaultValue={tab.workoutsPerWeek}
                                                placeholder={i18next.t('trainingPlanForm.workoutsPerWeek')}
                                                onChange={(Event) => handleTrainingChange(Event, i)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {i18next.t('trainingPlanForm.workoutsPerWeekValidation')}
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>{i18next.t('trainingPlanForm.workoutPause')}</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    required
                                                    type="number"
                                                    name="workoutPause"
                                                    id={`workoutPause-${i}`}
                                                    min="10"
                                                    max="180"
                                                    defaultValue={tab.workoutPause}
                                                    placeholder="Pause"
                                                    onChange={(Event) => handleTrainingChange(Event, i)}
                                                />
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrepend">{i18next.t('trainingPlanForm.pauseGroup')}</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control.Feedback type="invalid">
                                                    {i18next.t('trainingPlanForm.workoutPauseValidation')}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                        <Col md={2}>
                                            <DeleteTrainingSpan onClick={() => setShowConfirmModal(true)}
                                                style={{ pointerEvents: trainings.length === 1 ? 'none' : 'auto', color: trainings.length === 1 ? 'grey' : 'red' }} >
                                                <i className="fas fa-trash" /><span className='hidden-xs'> {i18next.t('trainingPlanForm.deleteTraining')}</span>
                                            </DeleteTrainingSpan>
                                        </Col>
                                    </Form.Row>
                                </Form>
                                <ExerciseContainerDiv>
                                    <h2>
                                        <b>{i18next.t('trainingPlanForm.exercise')}</b>
                                        <button
                                            className="btn btn-primary btn-sm float-right"
                                            onClick={handleShowAddExerciseModal}
                                            style={{ fontSize: '1rem' }}
                                        >
                                            <i className="fas fa-plus"></i>
                                        </button>
                                    </h2>
                                    <br></br>
                                    <div key={'eList' + tab.exercise.length}>
                                        {tab.exercise.length ? (
                                            <DndProvider backend={HTML5Backend} >
                                                <ExerciseCardContainer
                                                    tabIndex={i}
                                                    exerciseList={tab.exercise}
                                                    removeExercise={removeExercise}
                                                    updateExercise={updateExercise}
                                                    userLanguage={userLanguage}
                                                    moveExercise={moveCard} />
                                            </DndProvider>) : (
                                            <CenterParagraph>
                                                {i18next.t('trainingPlanForm.noExercise')}
                                            </CenterParagraph>
                                        )}

                                    </div>
                                </ExerciseContainerDiv>
                            </Tab.Pane>
                        );
                    })}
                </Tab.Content>
            </TabContentWrapper>
            <DeleteTrainingModal
                show={showConfirmModal}
                title={i18next.t('trainingPlanForm.deleteTraining')}
                body={
                    <>
                        <p>{i18next.t('trainingPlanForm.deleteTrainingBody')}</p>
                    </>
                }
                buttonPositive={i18next.t('generic.delete')}
                buttonNegative={i18next.t('generic.cancel')}
                onPositive={deleteTraining}
                onNegative={() => setShowConfirmModal(false)}
                trainingindex={tabIndex}
            />
            <LoadTemplateModal
                show={showTemplateModal}
                title={i18next.t('trainingPlanForm.templateModalTitle')}
                templateList={templateList}
                buttonPositive={i18next.t('trainingPlanForm.templateModalButton')}
                buttonNegative={i18next.t('generic.cancel')}
                noTemplateMsg={i18next.t('trainingPlanForm.templateModalNoTemplate')}
                maxTemplateError={i18next.t('trainingPlanForm.templateModalError')}
                onPositive={addTemplateToTraining}
                onNegative={() => setShowTempateModal(false)}
                disableTemplate={handleDisableTemplate}
                trainingindex={trainings.length}
                initSelected={[]}
                loadTemplate={handleLoadTemplates}
                yourTemplateText={i18next.t('trainingPlanForm.yourTemplateText')}
                globalTemplateText={i18next.t('trainingPlanForm.globalTemplateText')}
            />
            <SaveTemplateModel
                show={showSaveModal}
                title={i18next.t('trainingPlanForm.saveAsTemplateTitle')}
                buttonPositive={i18next.t('trainingPlanForm.saveAsTemplate')}
                buttonNegative={i18next.t('generic.cancel')}
                onPositive={saveTemplate}
                onNegative={() => setShowSaveModal(false)}
                initName={''}
            ></SaveTemplateModel>
        </Tab.Container>
    );
};

export default memo(TraininPlanForm);