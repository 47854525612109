import React, { createContext, ReactNode, useState } from 'react';
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
  LocalDataTrack,
} from 'twilio-video';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRoom from './useRoom/useRoom';
import { Callback, ErrorCallback } from '../../util/types';
import GenerateScreenshotHandler from './GenerateScreenshotHandler/GenerateScreenshotHandler';
import EndCallOnHostRequestHandler from './EndCallOnHostRequestHandler/EndCallOnHostRequestHandler';
import { noop } from 'lodash';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room;
  localTracks: (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[];
  isConnecting: boolean;
  connect: (roomCode: string, pin?: string) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  isCallHost: boolean;
  isHostConnected: boolean;
  isParticipantConnected: boolean;
  sharedFile: any;
  setSharedFile: any;
  sessionId: string;
  endCall: (pin, isBack?) => Promise<void>;
  isP2P: boolean;
  pinRequired: boolean;
  pinCode: string;
  isFullScreen: boolean;
  setIsFullScreen: (state) => void;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  isP2P: boolean;
  pinRequired: boolean;
  onError: ErrorCallback;
  onDisconnect?: Callback;
  children: ReactNode;
}

export function VideoProvider({
  options,
  isP2P,
  pinRequired,
  children,
  onError = noop,
  onDisconnect = noop,
}: VideoProviderProps) {
  const onErrorCallback = (error: TwilioError) => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };

  const { localTracks, getLocalVideoTrack, getLocalAudioTrack, isAcquiringLocalTracks } = useLocalTracks();
  const {
    room,
    isConnecting,
    connect,
    isCallHost,
    isHostConnected,
    isParticipantConnected,
    sessionId,
    endCall,
    pinCode,
    isFullScreen,
    setIsFullScreen,
  } = useRoom(localTracks, onErrorCallback, options);
  const [sharedFile, setSharedFile] = useState();

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        isCallHost,
        isHostConnected,
        isParticipantConnected,
        setSharedFile,
        sharedFile,
        sessionId,
        endCall,
        isP2P,
        pinRequired,
        pinCode,
        isFullScreen,
        setIsFullScreen,
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
      <GenerateScreenshotHandler />
      <EndCallOnHostRequestHandler />
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      <AttachVisibilityHandler />
      */}
    </VideoContext.Provider>
  );
}
