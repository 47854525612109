import React, { FC } from 'react';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import MainParticipantInfo from './MainParticipantInfo';
import ParticipantTracks from './ParticipantTracks';

const MainParticipant: FC<{
  isFullScreen?: boolean;
}> = ({ isFullScreen }) => {
  const mainParticipant = useMainSpeaker();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const { isP2P } = useVideoContext();

  const videoPriority =
    isP2P || mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant ? 'high' : null;

  return (
    /* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks participant={mainParticipant} disableAudio enableScreenShare videoPriority={videoPriority} />
    </MainParticipantInfo>
  );
};

export default MainParticipant;
