import i18next from 'i18next';
import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import StyledModal from './StyledModal';

type Props = {
  show: boolean;
  sent: boolean;
  onPositive: () => void;
  onNegative: () => void;
};

export const EmailValidationModal: FC<Props> = ({ show, sent, onPositive, onNegative }) => {
  return (
    <StyledModal show={show} onHide={() => onNegative()}>
      <Modal.Header closeButton>
        <Modal.Title>Du hast deine E-Mail Adresse noch nicht bestätigt.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Bevor du room4Physio nutzen kannst, musst du deine E-Mail verifizieren.
        <br />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => onNegative()}>
          {i18next.t('generic.cancel')}
        </Button>
        {sent ? (
          <span>Bestätigungsemail gesendet</span>
        ) : (
          <Button variant="link" onClick={() => onPositive()}>
            Bestätigungsemail neu senden
          </Button>
        )}
      </Modal.Footer>
    </StyledModal>
  );
};

export default EmailValidationModal;
