import React, { FC, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaRetweet } from 'react-icons/fa';
import ControlsTooltip from './ControlsToolTip';
import SelectCameraModal from '../SelectCameraModal';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { LocalVideoTrack } from 'twilio-video';
import i18next from 'i18next';

const ToggleSelectCameraButton: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();
  const videoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack;
  const [selectCameraModal, setSelectCameraModal] = useState(false);

  const toggleModal = () => {
    setSelectCameraModal(!selectCameraModal);
  };

  const switchCamera = (id) => {
    if (videoTrack) {
      if (localParticipant) {
        const localTrackPublication = localParticipant.unpublishTrack(videoTrack);
        localParticipant.emit('trackUnpublished', localTrackPublication);
      }
      videoTrack.stop();
    }
    getLocalVideoTrack({
      deviceId: { exact: id },
    }).then((track: LocalVideoTrack) => {
      if (localParticipant) {
        localParticipant.publishTrack(track, { priority: 'low' });
      }
    });
  };

  return (
    <>
      <ControlsTooltip id="tooltip-toggle-camera" label={i18next.t('room.controls.cameraSwitch.changeActiveCamera')}>
        <Button onClick={toggleModal} disabled={disabled}>
          {selectCameraModal ? <FaRetweet /> : <FaRetweet />}
        </Button>
      </ControlsTooltip>
      <SelectCameraModal
        show={selectCameraModal}
        onPositive={(id) => {
          switchCamera(id);
          toggleModal();
        }}
        onNegative={() => {
          toggleModal();
        }}
      />
    </>
  );
};

export default ToggleSelectCameraButton;
