import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { fetchFromRestAPI } from '../../util/api';
import { map } from 'lodash';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAuth0 } from '../../util/auth0';
import styled from 'styled-components';
import PrimaryButton from '../../components/PrimaryButton';
import { emitNotification } from '../../components/Notification';
const RefreshButton = styled(PrimaryButton)`
  float: right;
  margin: 1rem 0;
`;

const AppManagement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [versionList, setVersionList] = useState([]);
    const [updateReqVersion, setUpdateReqVersion] = useState('');
    const [featureList, setFeatureList] = useState([]);
    const [loginBottomPanel, setLoginBottomPanel] = useState('');
    const { getIdTokenClaims, user } = useAuth0();
    const fetchVersion = useCallback(async () => {
        if (!user) {
            return;
        }
        setIsLoading(true);
        setUpdateReqVersion('');
        setFeatureList([]);
        setLoginBottomPanel('');
        const token = await getIdTokenClaims();
        const version = await fetchFromRestAPI(`/api/v1/admin/version`, {
            method: 'GET',
            token,
        });
        if (version && version.length > 0) {
            setVersionList(version);
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [user, getIdTokenClaims]);


    useEffect(() => {
        fetchVersion();
    }, [user, fetchVersion]);


    const updateVersion = async () => {
        setIsLoading(true);
        const body = {
            latestVersion: null,
            updateReqVersion: updateReqVersion,
            featureList: featureList,
            loginBottomPanel: loginBottomPanel
        }
        const token = await getIdTokenClaims();
        await fetchFromRestAPI(`/api/v1/admin/version/update/`, {
            method: 'POST',
            token,
            body
        });
        emitNotification("APP version updated");
        setIsLoading(false);
        window.location.reload();
    }
    return (
        <React.Fragment>
            <RefreshButton className="ml-2" onClick={() => { setVersionList([]); fetchVersion(); }} disabled={isLoading}>
                {isLoading ? 'Refreshing...' : 'Refresh'}
            </RefreshButton>


            <Table striped bordered hover className="h-75 overflow-auto">
                <thead>
                    <tr>

                        <th className='centered-td'>Update App Below &#60;=</th>
                        <th className='centered-td'>Bottom Panel</th>
                        <th className='centered-td'>Feature List</th>
                        <th className='centered-td'></th>
                    </tr>
                    <tr>

                        <th className='centered-td'> <Form.Control type="text"
                            placeholder="Update app below <="
                            aria-describedby="basic-addon"
                            defaultValue={updateReqVersion}
                            onChange={(e) => { setUpdateReqVersion(e.target.value) }}
                        /></th>
                        <th className='centered-td'> <Form.Control as="textarea" rows={3}
                            placeholder="Login Bottom Panel"
                            aria-describedby="basic-addon"
                            defaultValue={loginBottomPanel}
                            onChange={(e) => { setLoginBottomPanel(e.target.value) }}
                        /></th>

                        <th className='centered-td'> <Typeahead
                            id="specialization-typeahead-multiple"
                            multiple
                            onChange={(e) => {
                                const fList = [];
                                e.forEach(element => {
                                    fList.push(element);
                                });
                                setFeatureList(fList);

                            }}
                            labelKey={(option) => `${option}`}
                            options={['INSURANCE_PLAN']}
                            placeholder="Feature list"
                            selected={featureList}
                        /></th>
                        <th className='centered-td'><Button variant='dark' onClick={() => { updateVersion() }}>Save</Button></th>
                    </tr>
                </thead>
                <tbody key={versionList.length} >

                    {map(versionList, (version) => {
                        return (
                            <tr key={version._id}>

                                <td className='centered-td'>{version.updateReqVersion}</td>
                                <td className='centered-td'>
                                    <textarea rows={3} cols={50}  defaultValue={version.loginBottomPanel ? version.loginBottomPanel.replace(/\n/g, '').replace(/\\/g, '') : ''}></textarea>
                                </td>
                                <td className='centered-td'>{version.featureList}</td>
                                <td className='centered-td'></td>
                            </tr>

                        );
                    })}

                </tbody>
            </Table>
        </React.Fragment>
    );
};
export default AppManagement;
