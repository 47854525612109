import React, { FC, memo, ReactNode, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { useAuth0 } from '../../util/auth0';
import i18next from 'i18next';
import styled from 'styled-components';

type Props = {
    show: boolean;
    title: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    onPositive: (isGlobalTemplate: boolean, templateName: string) => void;
    onNegative: () => void;
    initName: string;
};
const ErrorDiv = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
const SaveTemplateModel: FC<Props> = ({
    show,
    title,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    initName,
}) => {
    const { isAdminUser } = useAuth0();
    const [isGlobalTemplate, setIsGlobalTemplate] = useState(false);
    const [templateName, setTemplateName] = useState(initName);


    return (
        <Modal show={show} onHide={() => onNegative()}>
            <Modal.Header closeButton className="bg-light">
                <Modal.Title><h2>{title}</h2></Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Form.Group>
                <Form.Label>{i18next.t('trainingPlan.saveAndPublishModal.templateLabel')}</Form.Label>
                    <Form.Control
                        type="text"
                        name="templateName"
                        id="templateName"
                        defaultValue={templateName}
                        onChange={(e) => { setTemplateName(e.target.value) }}
                        placeholder={i18next.t('trainingPlan.saveAndPublishModal.templatePlaceHolder')}

                    />
                     {templateName.length <= 0 ? (
                        <ErrorDiv>{i18next.t('trainingPlan.validation.templateName')}</ErrorDiv>
                    ) : null}

                    <p><small>{i18next.t('trainingPlan.saveAndPublishModal.templateHelpText')}</small></p>
                   
                </Form.Group>
                {isAdminUser ? <Form.Check
                        type="checkbox"
                        name="isGlobalTemplate"
                        id="isGlobalTemplate"
                        defaultChecked={isGlobalTemplate}
                        onChange={(e) => { setIsGlobalTemplate(e.target.checked) }}
                        label={i18next.t('trainingPlan.saveAndPublishModal.isGlobalLabel')}
                    /> : null}
                  
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()}>
                    {buttonNegative}
                </Button>
                <Button onClick={() => { onPositive(isGlobalTemplate, templateName); }} variant="danger">
                    {buttonPositive}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default memo(SaveTemplateModel);
