import { getFullURL } from './endpoints';

export function generatePatientRoomURL(code: string): string {
  return getFullURL('/' + code);
}

export function generateGroupRoomURL(code: string): string {
  return getFullURL('/' + code);
}
export function titleCase(str: string): string {
  return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()).trim();
}