import i18next from 'i18next';
import React, { FC } from 'react';
import ConfirmationModal from './ConfirmationModal';

const DeleteFileModal: FC<{ show: boolean; onDelete: () => void; onAbort: () => void }> = ({
  show,
  onDelete,
  onAbort,
}) => (
  <ConfirmationModal
    show={show}
    title={i18next.t('deleteFileModal.title')}
    body={i18next.t('deleteFileModal.question')}
    buttonPositive={i18next.t('generic.delete')}
    buttonNegative={i18next.t('generic.close')}
    onPositive={onDelete}
    onNegative={onAbort}
  />
);

export default DeleteFileModal;
