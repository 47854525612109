import React, { FC, useEffect, useState, memo } from 'react';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import Avatar from 'react-avatar';
import ChatMessage from './ChatMessage';
import { IChatMessage } from '../../util/types';
import i18next from 'i18next';
import { box } from 'tweetnacl';
import { encrypt, formatChatString } from '../../util/crypto';
import { decodeBase64 } from 'tweetnacl-util';
import { useHistory } from 'react-router-dom';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html";
import { Button, Col } from 'react-bootstrap';
import moment from 'moment';
import { blurUnsentMessage, displayLocalLastMessage } from '../../util';
import { formatDateForChat } from '../../util/dateTimeUtils';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
type Props = { therapy: any, therapist: any, webSocket: any, newMessage: any, tKeyPair: any, isMobile: boolean };

const ChatMessageComponent: FC<Props> = ({ therapy, therapist, webSocket, newMessage, tKeyPair, isMobile }) => {
    const history = useHistory();
    const [publicKeyPatient, setPublicKeyPatient] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [messages, setMessages] = useState([]);
    const { user, getIdTokenClaims } = useAuth0();
    const [formValue, setFormValue] = useState('');
    const pageSize = 15;
    const scrollToBottom = () => {
        setTimeout(
            function () {
                const objDiv = document.getElementById("chatMessage");
                if (objDiv && objDiv.lastElementChild) {
                    objDiv.lastElementChild.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' });
                }
            }, 400);
    }
    const resendMessage = async (text: any) => {
        const finalKeyTherpaist = box.before(decodeBase64(publicKeyPatient), tKeyPair.secretKey);
        let actualText = formatChatString(text);
        if (actualText) {
            const ackId = moment().unix().toString();
            const message: IChatMessage = {
                messageType: 'TEXT',
                senderId: therapy.therapist,
                text: encrypt(finalKeyTherpaist, actualText),
                ackId: ackId,
                isNew: true,
            };
            const chat = {
                therapyId: therapy._id,
                connectionId: therapy.patient._id,
                message: message
            };
            webSocket.send(JSON.stringify(chat));
            setMessages([...messages, message]);
            setFormValue('');
            scrollToBottom();
            blurUnsentMessage(ackId);
            const localtz = moment.tz.guess();
            displayLocalLastMessage(therapy._id, actualText, formatDateForChat(moment().locale(localtz)));
        }
    }
    const sendMessage = async (e) => {
        e.preventDefault();
        if (publicKeyPatient) {
            const finalKeyTherpaist = box.before(decodeBase64(publicKeyPatient), tKeyPair.secretKey);
            let actualText = formatChatString(formValue);
            if (actualText) {
                const ackId = moment().unix().toString();
                const message: IChatMessage = {
                    messageType: 'TEXT',
                    senderId: therapy.therapist,
                    text: encrypt(finalKeyTherpaist, actualText),
                    ackId: ackId,
                    isNew: true,
                };
                const chat = {
                    therapyId: therapy._id,
                    connectionId: therapy.patient._id,
                    message: message
                };
                webSocket.send(JSON.stringify(chat));
                setMessages([...messages, message]);
                setFormValue('');
                scrollToBottom();
                blurUnsentMessage(ackId);
                const localtz = moment.tz.guess();
                displayLocalLastMessage(therapy._id, actualText, formatDateForChat(moment(moment.utc().toDate()).locale(localtz)));
            }
        } else {
            console.log("Invalid Encryption Key : This case will not happen for real user");
        }
    }

    useEffect(() => {
        if (newMessage && therapy.patient._id === newMessage.senderId) {
            setMessages([...messages, newMessage]);
            scrollToBottom();
        }
        // eslint-disable-next-line
    }, [newMessage]);
    const getNextPageChat = async (pageNo: number) => {
        const token = await getIdTokenClaims();
        const chatHead = await fetchFromRestAPI(`/api/v1/chat/${therapy._id}/${pageNo}/${pageSize}`, { token });
        if (chatHead && chatHead.messages) {
            setPublicKeyPatient(chatHead.patientKey);
            if (chatHead.messages.length > 0 && messages) {
                setMessages([...chatHead.messages, ...messages]);
            } else {
                setHasMore(false);
            }
        }
    }
    useEffect(() => {
        const fetchChatData = async () => {
            const token = await getIdTokenClaims();
            const chatHead = await fetchFromRestAPI(`/api/v1/chat/${therapy._id}/1/${pageSize}`, { token });
            if (chatHead && chatHead.messages) {
                setPublicKeyPatient(chatHead.patientKey);
                if (chatHead.messages.length > 0) {
                    setMessages([...chatHead.messages, ...messages]);
                    scrollToBottom();
                    setHasMore(true);
                } else {
                    scrollToBottom();
                    setHasMore(false);
                }
            }
        };
        if (!user) return;

        if (isEmpty(newMessage)) {
            fetchChatData();
        }
        // eslint-disable-next-line
    }, [user, getIdTokenClaims]);

    if (therapy) {
        const patientName = (therapy.patient.firstName + " " + therapy.patient.lastName);
        return (
            <div className="col-12 col-lg-8 col-md-7 col-xl-9 chat-message-container">
                <div className="py-2 px-4 border-bottom d-none d-lg-block">
                    <div className="d-flex align-items-center py-1">
                        <div className="position-relative">
                            <Avatar name={patientName} size="40" color="#707089" className="rounded-circle mr-1" round={true} />
                        </div>
                        <div className="flex-grow-1 pl-4">
                            <strong>{patientName}</strong>
                            <div className="text-muted small"><em></em></div>
                        </div>
                        <div>
                            <Button variant='link' onClick={() => history.push(`/therapien/${therapy.id}`)}> {i18next.t('chat.openPatientProfile')}</Button>
                        </div>
                    </div>
                </div>
                {messages && publicKeyPatient && messages.length > 0 ?
                    <div className="chat-messages p-4" >
                        <InfiniteScroll
                            className='chat-message-body p-4' id="chatMessage"
                            pageStart={1}
                            loadMore={(page) => {
                                getNextPageChat(page);
                            }}
                            initialLoad={true}
                            hasMore={hasMore}
                            isReverse={true}
                            useWindow={false}
                            loader={<Col md={12} className="loader text-center" key={0}><i className="fa fa-spinner fa-spin fa-2x" aria-hidden="true" /></Col>}
                        >
                            {messages && publicKeyPatient && messages.map((msg, index) => <ChatMessage key={'msg-' + index} message={msg} pname={patientName} therapist={therapist} patientPublicKey={publicKeyPatient} therapistPrivateKey={tKeyPair.secretKey} resendMessage={resendMessage} />)}
                            {isMobile ? <><br /><br /></> : <br />}
                        </InfiniteScroll></div> :
                    <div className="chat-messages p-4 text-center">
                        {i18next.t('chat.noChatMessageFound')} </div>}
                <div className='chat-input col-12 col-lg-8 col-md-7 col-xl-9'>
                    <form onSubmit={sendMessage} id="sendMsgForm">
                        <div className="flex-grow-0 py-3 px-4 border-top chat-textarea">
                            <div className="input-group">
                                {isMobile ?
                                    <ContentEditable id="chat-msg-textbox" className="message-wrapper"
                                        html={formValue}
                                        onChange={(e) => setFormValue(sanitizeHtml(e.target.value))}
                                        onKeyDown={(e) => {
                                            if (isMobile && e.key === 'Enter') {
                                                if (formValue && formValue.length > 0) {
                                                    sendMessage(e);
                                                }
                                            }
                                            else if (e.key === 'Enter' && !e.shiftKey) {
                                                if (formValue && formValue.length > 0) {
                                                    sendMessage(e);
                                                }
                                            }
                                        }}
                                        data-placeholder={i18next.t('chat.typeMsgPlaceholder')}
                                    />

                                    : <ContentEditable id="chat-msg-textbox" className="message-wrapper"
                                        html={formValue}
                                        onChange={(e) => setFormValue(sanitizeHtml(e.target.value))}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                if (formValue && formValue.length > 0) {
                                                    sendMessage(e);
                                                }
                                            }
                                        }}
                                        data-placeholder={i18next.t('chat.typeMsgPlaceholder')}
                                    />}
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-primary float-right" disabled={!formValue}><i className="fas fa-paper-plane" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    } else {
        return null;
    }
};

export default memo(ChatMessageComponent);
