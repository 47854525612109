import * as Yup from 'yup';
import { fetchFromRestAPI } from './api';
import moment from 'moment';
import 'moment/locale/de';
import i18next from 'i18next';
import { isValidDate } from './dateTimeUtils';
import jwt from 'jwt-decode'
import { decryptText } from './crypto';
import { REACT_APP_AUTH0_SECRET_INDEX_KEY } from '../config';
export const userProfileInitData = {
  firstName: '',
  lastName: '',
  street: '',
  postal: '',
  city: '',
  approved: false,
  birthDate: null,
  telephone: '',
  date: '',
  month: '',
  year: '',
  country: '',
  isCallAllowed: true,
  therapistPlan: null,
  profilePic: null
}
export const UserAccountSchema = Yup.object().shape({
  firstName: Yup.string().required('Bitte Vornamen eingeben!'),
  lastName: Yup.string().required('Bitte Nachnamen eingeben!'),
  street: Yup.string().required('Bitte ausfüllen!'),
  postal: Yup.string().required('Bitte ausfüllen!'),
  city: Yup.string().required('Bitte ausfüllen!'),
  telephone: Yup.string().required('Bitte ausfüllen!'),
  therapistPlan: Yup.string().required('Bitte ausfüllen!').notOneOf(['undefined']),
  birthDate: Yup.date()
    .required('Bitte ausfüllen')
    .max(new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 18), 'Bitte gültiges Datum auswählen!') // at least 18 years old
    .min(new Date('1920-01-01'), 'Bitte gültiges Datum auswählen!'), // max. 100 years old
});

export const validateUserForm = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors['firstName'] = i18next.t('accountSetup.firstNameRequired');
  } else if (!values.lastName) {
    errors['lastName'] = i18next.t('accountSetup.lastNameRequired');
  } else if (!values.street) {
    errors['street'] = i18next.t('accountSetup.pleaseFillIn');
  } else if (!values.postal) {
    errors['postal'] = i18next.t('accountSetup.pleaseFillIn');
  } else if (!values.city) {
    errors['city'] = i18next.t('accountSetup.pleaseFillIn');
  } else if (!values.telephone) {
    errors['telephone'] = i18next.t('accountSetup.pleaseFillIn');
  } else if (values.telephone && !(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-.0-9]{8,14}$/g).test(values.telephone)) {
    errors['telephone'] = i18next.t('accountSetup.invalidPhone');
  } else if (values.date && values.month && values.year) {
    if (isValidDate(values.month + "/" + values.date + "/" + values.year)) {
      const differenceYears = moment().diff(`${values.year}-${values.month}-${values.date}`, 'years');
      if (differenceYears < 18 || differenceYears > 100) {
        errors['date'] = i18next.t('accountSetup.ageValidation');
      }
    } else {
      errors['date'] = i18next.t('accountSetup.ageValidation');
    }
  } else {
    errors['date'] = i18next.t('accountSetup.pleaseFillIn');
  }
  return errors;
};

export async function fetchUserAccountRemote(token) {
  const userProfile = await fetchFromRestAPI('/api/v1/user-profile', {
    method: 'GET',
    token,
  });
  if (userProfile && userProfile.error) {
    return null;
  }
  return userProfile;
}

export async function fetchTherapistKey(idToken, token) {
  const keyToken = jwt(idToken.__raw);
  const auth0Key = keyToken[REACT_APP_AUTH0_SECRET_INDEX_KEY];
  const keyResponse = await fetchFromRestAPI('/api/v1/chat/key', {
    method: 'GET',
    token,
  });
  if (keyResponse && keyResponse.key) {
    return decryptText(auth0Key, keyResponse.key);
  }

  return null;
}

export async function fetchTherapistPlan() {
  const plans = await fetchFromRestAPI('/api/v1/therapist/plans', {
    method: 'GET'
  });
  if (plans && plans.error) {
    return null;
  }
  return plans;
}

export async function isAccountComplete(token) {
  const userAccountRemote = await fetchUserAccountRemote(token);
  if (!userAccountRemote) {
    return false;
  } else if (userAccountRemote && userAccountRemote.error) {
    return false;
  }
  return UserAccountSchema.isValidSync(userAccountRemote);
}

export async function updateUserAccountRemote(token, userProfileData) {
  const userProfileRecord = await fetchFromRestAPI('/api/v1/user-profile', {
    method: 'POST',
    body: userProfileData,
    token,
  });
  return userProfileRecord;
}

export async function upgradeTherapistPlan(token, body) {
  const response = await fetchFromRestAPI('/api/v1/therapist/upgrade-plan', {
    method: 'POST',
    body: body,
    token,
  });
  return response;
}

export async function fetchTherapistCurrentPlan(token) {
  const plans = await fetchFromRestAPI('/api/v1/therapist/current-plan', {
    method: 'GET',
    token
  });
  if (plans && plans.error) {
    return null;
  }
  return plans;
}

export async function cancelTherapistPlan(token) {
  const response = await fetchFromRestAPI('/api/v1/therapist/cancel-plan', {
    method: 'POST',
    token,
  });
  return response;
}
export async function fetchUserAccountForSetup(token) {
  const userProfile = await fetchFromRestAPI('/api/v1/user-profile/verify-account', {
    method: 'GET',
    token,
  });
  if (userProfile && userProfile.error) {
    return null;
  }
  return userProfile;
}