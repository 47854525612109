import React, { FC, useState, useCallback } from 'react'
import update from 'immutability-helper'
import { ExcerciseCardDnD } from './ExcerciseCardDnD'
import { has } from 'lodash'

export interface Item {
    id: number
    text: string
}

export interface ContainerState {
    cards: any[]
}

export const ExerciseCardContainer: FC<{ tabIndex: any, moveExercise: any, exerciseList: any, removeExercise: any, updateExercise: any, userLanguage: any }> = ({ tabIndex, moveExercise, exerciseList, removeExercise, updateExercise, userLanguage }) => {

    const [cards, setCards] = useState(exerciseList)

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            const dragCard = cards[dragIndex]
            setCards(
                update(cards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }),
            )
            moveExercise(dragIndex, hoverIndex, tabIndex);
        },
        [cards, moveExercise, tabIndex],
    )

    const renderCard = (card: any, index: number) => {
        if (has(card, "_id") && card._id) {
            card["eid"] = card._id;
        }
        if (!has(card, "skipPause")) {
            card["skipPause"] = false;
        }
        if (!has(card, "isCustom")) {
            card["isCustom"] = card.video === null ? true : false;
        }
        if (!has(card, "customDesc")) {
            card["customDesc"] = card.video === null ? true : false;
        }
        return (
            <ExcerciseCardDnD
                key={card.eid}
                index={index}
                id={card.eid}
                moveCard={moveCard}
                tabIndex={tabIndex}
                exerciseObj={card}
                removeExercise={removeExercise}
                updateExercise={updateExercise}
                userLanguage={userLanguage}
            />
        )
    }

    return (
        <>
            {cards.map((card, i) => renderCard(card, i))}
        </>
    )

}
