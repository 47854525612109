import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';
import ControlsTooltip from './ControlsToolTip';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const ToggleVideoButton: FC<{ disabled?: boolean; showLabel?: boolean }> = ({ disabled, showLabel }) => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  return (
    <ControlsTooltip id="tooltip-toggle-audio" label={isVideoEnabled ? 'Kamera ausschalten' : 'Kamera einschalten'}>
      <Button onClick={toggleVideoEnabled} disabled={disabled}>
        {isVideoEnabled ? <FaVideo /> : <FaVideoSlash />}
        {showLabel ? (isVideoEnabled ? ' Kamera ausschalten' : ' Kamera einschalten') : ''}
      </Button>
    </ControlsTooltip>
  );
};

export default ToggleVideoButton;
