import i18next from 'i18next';
import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import StyledModal from './StyledModal';

const PermissionStyles = styled.div`
  color: #444;
  line-height: 2;
  .btn-link {
    margin-right: auto;
    text-decoration: underline;
  }
  strong,
  img {
    display: block;
  }
  img {
    max-width: 400px;
    width: 100%;
    margin: 20px auto;
    border-radius: 4px;
    box-shadow: 1px 3px 5px 0px rgba(209, 209, 209, 1);
  }
`;

type Props = {
  show: boolean;
  onPositive: () => void;
  onNegative: () => void;
  onSupport: () => void;
};

export const PrivacyModal: FC<Props> = ({ show, onPositive, onNegative, onSupport }) => {
  return (
    <StyledModal show={show} size="lg" onHide={() => onNegative()}>
      <PermissionStyles>
        <Modal.Header closeButton>
          <Modal.Title>{i18next.t('loginpage.welcome')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: i18next.t('privacyModal.body') }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => onSupport()}>
            {i18next.t('privacyModal.contactSupport')}
          </Button>
          <Button variant="primary" onClick={() => onPositive()}>
            {i18next.t('privacyModal.iAgree')}
          </Button>
        </Modal.Footer>
      </PermissionStyles>
    </StyledModal>
  );
};

export default PrivacyModal;
