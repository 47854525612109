import React, { FC, useState } from 'react';
import { FaUsers, FaUser } from 'react-icons/fa';
import styled from 'styled-components';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import PrimaryButton from '../PrimaryButton';
import ToggleFullScreenButton from './Controls/ToggleFullScreenButton';
import MainParticipant from './MainParticipant';
import ParticipantNetworkIndicator from './ParticipantNetworkIndicator';
import ParticipantSwitch from './ParticipantSwitch';
import ToggleVideoButton from './Controls/ToggleVideoButton';
import ToggleAudioButton from './Controls/ToggleAudioButton';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #002b4b;
  display: grid;
  grid-template-areas: 'mainParticipant';
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
`;

const NetworkIndicatorContainer = styled.div`
  position: absolute;
  left: 2rem;
  top: 2.3rem;
`;

const MainParticipantContainer = styled.div`
  position: relative;
  grid-area: mainParticipant;
  display: flex;
  justify-content: center;
`;

const ParticipantsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  z-index: 19;
  width: 180px;
  height: auto;
  bottom: 5rem;
  right: 1.5rem;
`;

const CloseFullScreenWrapper = styled.div`
  position: absolute;
  right: 5rem;
  top: 2rem;
  z-index: 2;
`;

const BottomControlsBarMobile = styled.div`
  position: absolute;
  z-index: 49;
  width: 100%;
  bottom: 2.5em;
  left: 50%;
  transform: translateX(-50%);
  grid-area: bottomControlsBar;

  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    font-size: 12px !important;
    margin: 0px 2px 0px 2px;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    grid-area: participantList;
    bottom: 1.2em;
    position: absolute;
    right: 1.5rem;
  }
`;

const GroupRoomMobileFullScreen: FC = () => {
  const {
    isCallHost,
    isP2P,
    room: { localParticipant },
  } = useVideoContext();

  const [showHideParticipants, setShowHideParticipants] = useState(true);
  const toggleShowHideButton = () => setShowHideParticipants(!showHideParticipants);

  return (
    <Container>
      <MainParticipantContainer id="main-video-container">
        {!isP2P && isCallHost && (
          <NetworkIndicatorContainer>
            <ParticipantNetworkIndicator participant={localParticipant} />
          </NetworkIndicatorContainer>
        )}

        <CloseFullScreenWrapper>
          <ToggleFullScreenButton />
        </CloseFullScreenWrapper>

        <MainParticipant isFullScreen />

        {showHideParticipants && (
          <ParticipantsContainer>
            <ParticipantSwitch />
          </ParticipantsContainer>
        )}

        <BottomControlsBarMobile>
          {isCallHost && (
            <PrimaryButton onClick={toggleShowHideButton}>
              {showHideParticipants ? <FaUsers /> : <FaUser />}
            </PrimaryButton>
          )}
          <ToggleVideoButton />
          <ToggleAudioButton />
          <ToggleFullScreenButton />
        </BottomControlsBarMobile>
      </MainParticipantContainer>
    </Container>
  );
};

export default GroupRoomMobileFullScreen;
