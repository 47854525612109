import { useCallback, useEffect } from 'react';
import fscreen from 'fscreen';
import { isMobile } from 'mobile-device-detect';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useFullScreenToggle(mainParticipant = false) {
  const { isFullScreen, setIsFullScreen } = useVideoContext();

  useEffect(() => {
    const onFullScreenChange = () => setIsFullScreen(!!fscreen.fullscreenElement);
    fscreen.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      fscreen.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, [setIsFullScreen]);

  const toggleFullScreen = useCallback(() => {
    const fsElement = document.body;
    isFullScreen
      ? isMobile
        ? setIsFullScreen(!isFullScreen)
        : fscreen.exitFullscreen()
      : isMobile
      ? setIsFullScreen(!isFullScreen)
      : fscreen.requestFullscreen(mainParticipant ? fsElement : document.documentElement);
  }, [isFullScreen, setIsFullScreen, mainParticipant]);

  return [isFullScreen, toggleFullScreen] as const;
}
