import React, { FC, ReactElement } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const ControlsTooltip: FC<{ id: string; label: string; children: ReactElement }> = ({ id, label, children }) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={id}>{label}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );
};

export default ControlsTooltip;
