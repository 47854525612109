import React, { createContext, useContext, useState, useEffect, FC } from 'react';
import { Participant, Room } from 'twilio-video';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

type selectedParticipantContextType = [Participant | null, (participant: Participant) => void];

export const selectedParticipantContext = createContext<selectedParticipantContextType>(null!);

export default function useSelectedParticipant() {
  const [selectedParticipant, setSelectedParticipant] = useContext(selectedParticipantContext);
  return [selectedParticipant, setSelectedParticipant] as const;
}

type SelectedParticipantProviderProps = {
  room: Room;
  children: React.ReactNode;
};

export const SelectedParticipantProvider: FC<SelectedParticipantProviderProps> = ({ room, children }) => {
  const [selectedParticipant, _setSelectedParticipant] = useState<Participant | null>(null);
  const {
    room: { localParticipant },
    isCallHost,
  } = useVideoContext();

  const setSelectedParticipant = (participant: Participant) => {
    if (!isCallHost && participant.identity !== 'host' && participant.sid !== localParticipant.sid) {
      return;
    }
    _setSelectedParticipant((prevParticipant) => (prevParticipant === participant ? null : participant));
  };

  useEffect(() => {
    const onDisconnect = () => _setSelectedParticipant(null);
    room.on('disconnected', onDisconnect);
    return () => {
      room.off('disconnected', onDisconnect);
    };
  }, [room]);

  return (
    <selectedParticipantContext.Provider value={[selectedParticipant, setSelectedParticipant]}>
      {children}
    </selectedParticipantContext.Provider>
  );
};
