import { useEffect } from 'react';
import EventEmitter from 'eventemitter3';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const eventEmitter = new EventEmitter();

const whenTrackSubscribed = (track) => {
  if (track.kind === 'data') {
    track.removeAllListeners();
    track.on('message', (data) => {
      console.log('recieved message from participant', data);
      const eventBody = JSON.parse(data);
      eventEmitter.emit(eventBody.action, eventBody);
    });
  }
};

const subscribeToDataTrack = (participant) => {
  if (!participant.listeners('trackSubscribed').filter((f) => f.name === 'whenTrackSubscribed').length) {
    participant.on('trackSubscribed', whenTrackSubscribed);
  }
};

export default function (): EventEmitter {
  const { room } = useVideoContext();

  useEffect(() => {
    if (!room || !room.participants) return;
    const subscribeOnConnect = (participant) => {
      console.log('new participant connected, subscribe to data track');
      subscribeToDataTrack(participant);
    };

    room.participants.forEach(subscribeToDataTrack);
    if (!room.listeners('participantConnected').filter((f) => f.name === 'subscribeOnConnect').length) {
      room.on('participantConnected', subscribeOnConnect);
    }
  }, [room]);

  return eventEmitter;
}
