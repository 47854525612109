import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import TrainingPlanDetails from '../../components/trainingPlans/TrainingPlanDetails';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import moment from 'moment';
import 'moment/locale/de';
import { useHistory } from 'react-router';
import { cloneDeep, isObject } from 'lodash';
import { emitNotification } from '../../components/Notification';
import i18next from 'i18next';

const Loader = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-grow: 1;
  font-size: 30px;
  justify-content: center;
  padding-top: 100px;
  color: white;
`;

const WorkoutDetailsContainer: FC = () => {
  const defaultTraining = {
    status: 'UNPUBLISHED',
    startDate: moment().toDate(),
    endDate: moment().toDate(),
    therapist: null,
    patient: null,
    therapyId: null,
    isGlobalTemplate: false,
    templateName: null,
    trainings: [
      {
        name: `Training 1`,
        workoutsPerWeek: 3,
        workoutPause: 60,
        exercise: [],
      },
    ],
  };
  const { user, getIdTokenClaims } = useAuth0();
  const { therapyShortId, trainingId } = useParams<{ therapyShortId: string; trainingId: string }>();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [patientDetails, setPatientDetails] = useState({});
  const [trainingDetails, setTrainingDetails] = useState(defaultTraining);
  const history = useHistory();

  const fetchTherapyDetails = async (idToken): Promise<void> => {
    const therapyData = await fetchFromRestAPI(`/api/v1/therapies/${therapyShortId}`, {
      token: idToken,
    });
    setPatientDetails(therapyData.patient);
  };

  const fetchTrainingDetails = async (idToken, updatedId?): Promise<void> => {
    const tid = updatedId ? updatedId : trainingId;
    const trainingData = await fetchFromRestAPI(`/api/v1/trainingplan/${tid}`, {
      token: idToken,
    });
    setTrainingDetails(trainingData);
  };

  const fetchData = async () => {
    setIsFetchingData(true);
    const idToken = await getIdTokenClaims();
    // If therapyShortId available then TherapyDetails
    therapyShortId && (await fetchTherapyDetails(idToken));
    // If trainingId available then TrainingDetails
    trainingId && (await fetchTrainingDetails(idToken));
    setIsFetchingData(false);
  };

  useEffect(() => {
    if (!user) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (isFetchingData) {
    return (
      <Loader>
        <i className="fas fa-spin fa-cog" />
      </Loader>
    );
  }

  const unpublishTrainingPlan = async (trainingId) => {
    const token = await getIdTokenClaims();
    await fetchFromRestAPI(`/api/v1/trainingplan/unpublish/${trainingId}`, {
      method: 'PATCH',
      body: {},
      token,
    });
    await fetchTrainingDetails(token);
  };

  const getUpdatedTraining = async (trainingObject) => {
    const trainingData = cloneDeep(trainingObject);
    let trainings = trainingData.trainings;
    trainings = trainings.map((training) => {
      training.history = [];
      training.exercise.map((e) => {
        if (isObject(e.video)) {
          e.video = e.video._id;
        }
        return e;
      });
      return training;
    });
    trainingData.trainings = trainings;
    return trainingData;
  }

  const publishTrainingPlan = async (date, isGlobalTemplate, templateName) => {
    trainingDetails.endDate = new Date(moment(date, "DD-MM-YYYY").format('YYYY-MM-DD'));
    trainingDetails.therapyId = therapyShortId;
    trainingDetails.isGlobalTemplate = isGlobalTemplate;
    trainingDetails.templateName = templateName;
    const updatedTrainingDetails = await getUpdatedTraining(trainingDetails);
    const token = await getIdTokenClaims();
    const response = await fetchFromRestAPI(`/api/v1/trainingplan/`, {
      method: 'POST',
      body: updatedTrainingDetails,
      token,
    });
    history.push(`/therapien/${therapyShortId}/workout/${response._id}`);
    await fetchTrainingDetails(token, response._id);
  };
  const saveTemplateTrainingPlan = async (isGlobalTemplate, templateName) => {
    trainingDetails.therapyId = therapyShortId;
    trainingDetails.isGlobalTemplate = isGlobalTemplate;
    trainingDetails.templateName = templateName;
    const updatedTrainingDetails = await getUpdatedTraining(trainingDetails);
    const token = await getIdTokenClaims();
    const response = await fetchFromRestAPI(`/api/v1/trainingplan/template`, {
      method: 'POST',
      body: updatedTrainingDetails,
      token,
    });
    if (response && !response.error) {
      //history.push(`/therapien/${therapyShortId}/workout/${response._id}`);
      //await fetchTrainingDetails(token, response._id);
    }
  };
  const saveAsDraftTrainingPlan = async () => {
    const errorNtfnSettings = {
      type: 'error',
      position: 'top-right',
    };
    trainingDetails.endDate = new Date(moment().format('YYYY-MM-DD'));
    trainingDetails.therapyId = therapyShortId;
    const updatedTrainingDetails = await getUpdatedTraining(trainingDetails);
    const token = await getIdTokenClaims();
    const response = await fetchFromRestAPI(`/api/v1/trainingplan/draft`, {
      method: 'POST',
      body: updatedTrainingDetails,
      token,
    });
    if (response && !response.error) {
      history.push(`/therapien/${therapyShortId}/workout/${response._id}`);
      await fetchTrainingDetails(token, response._id);
      emitNotification(i18next.t('errorMessageTemplate.key_TrainingSavedAsDraft'), errorNtfnSettings);
    }else{
      emitNotification(i18next.t('errorMessageTemplate.key_TrainingSavedAsDraftFailed'), errorNtfnSettings);
    }
  };

  const updateTraining = (trainng) => {
    setTrainingDetails(trainng);
  }

  return (
    <TrainingPlanDetails
      patient={patientDetails}
      training={trainingDetails}
      unpublishTrainingPlan={unpublishTrainingPlan}
      publishTrainingPlan={publishTrainingPlan}
      saveAsDraftTrainingPlan={saveAsDraftTrainingPlan}
      closeButtonNavigateTo={`/therapien/${therapyShortId}`}
      updateTrainingPlan={updateTraining}
      saveTemplate={saveTemplateTrainingPlan}
    />
  );
};

export default WorkoutDetailsContainer;
