import Notification from './Notification';
import Header from './Header';
import React, { FC, useContext } from 'react';
import { useAuth0 } from '../util/auth0';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle, ThemeContext } from 'styled-components';
import { Theme } from './ThemeProvider/useTheme/useTheme';
import UpgradeNotification from './UpgradeNotification';

const GlobalStyles = createGlobalStyle`
  html {
    --primary-color: ${(props: { theme: { mode: Theme } }) =>
    props.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f'};
    --secondary-color: #ffffff;
  }
  a {
    color: var(--primary-color);
    &:hover {
      color: var(--primary-color);
    }
  }
  .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    &:hover {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
  .btn-secondary {
    border-color: var(--primary-color);
  }
  .btn-link {
    color: var(--primary-color);
    &:hover {
      color: var(--primary-color);
    }
  }
`;

const PageLayout: FC = ({ children }) => {
  const theme = useContext(ThemeContext);

  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  const shouldShowHeaderNav = isAuthenticated && location.pathname !== '/account-setup'
    && location.pathname !== '/404';


  const isChatPage = location.pathname === '/messages';
  let showFooter = location.pathname !== '/404';
  showFooter = !isChatPage;
  if (isChatPage)
    document.body.classList.add("stop-scrolling");
  else
    document.body.classList.remove("stop-scrolling");
  return (
    <React.Fragment>
      <GlobalStyles theme={theme} />
      <Notification />
      {shouldShowHeaderNav && <Header />}
      {children}
      {showFooter && <Footer isFeedbackButton={!isChatPage} />}
      <UpgradeNotification />
    </React.Fragment>
  );
};

export default PageLayout;
