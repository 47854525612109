import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Badge, Form } from 'react-bootstrap';
import styled from 'styled-components';
import i18next from 'i18next';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import ConfirmationModal from '../../components/ConfirmationModal';
import 'moment/locale/de';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';

const AppointmentListWrapper = styled.main`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  .dropdown-item {
    font-size: 1.5rem;
  }
  .btn-secondary {
    border: 2px solid #ff695f;
    color: #ff695f;
  }
  h1 {
    font-weight: bold;
    font-size: 4rem;
  }
  .banner {
    display: flex;
    > div {
      max-width: 480px;
    }
  }
  .control-btns {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
    .btn {
      margin-bottom: 8px;
    }
  }
  .patient-overview {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    > div {
      &:hover {
        background-color: #ececec;
        cursor: pointer;
        .name {
          font-weight: 700;
        }
      }
      display: flex;
      white-space: nowrap;
      padding: 4px 16px;
      align-items: center;
      background: white;
      &:first-of-type {
        padding-top: 8px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-bottom: 8px;
      }
      .name {
        flex: 1;
        color: #444;
      }
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 39px;
        width: 250px;
      }
    }
  }
  @media (max-width: 750px), (max-device-width: 815px) and (-webkit-min-device-pixel-ratio: 3) {
    .banner {
      h1 {
        font-size: 1.8rem;
      }
      flex-direction: column;
      .control-btns {
        align-items: flex-start;
      }
    }
    .text-right {
      text-align: left !important;
  }
    .patient-overview {
      > div {
        flex-direction: column;
        border-top: 1px solid #eee;
        &:first-of-type {
          border-top: 0;
        }
        .name {
          width: 100%;
          padding: 0;
        }
        .btn-group input {
          width: 100%;
        }
        .btn-secondary {
          margin: 8px auto 8px 0;
        }
      }
    }
  }
`;

const AppointmentListGlobal = ({ appointmentList }) => {
    const [appointmentId, setAppointmentId] = useState(false);
    const [comment, setComment] = useState('');
    const [showCancelModel, setShowCancelModal] = useState(false);
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [appointmentListData, setAppointmentListData] = useState([]);
    const history = useHistory();
    const { getIdTokenClaims } = useAuth0();
    const handelCancelAppointment = async () => {
        const token = await getIdTokenClaims();
        await fetchFromRestAPI(`/api/v1/appointment/therapist/cancel/${appointmentId}`, {
            method: 'POST',
            token,
            body: { comment: comment }
        })
            .then((response) => {
                if (response && !response.error) {
                    const index = appointmentList.findIndex((item) => item._id === appointmentId);
                    appointmentList[index].status = 'cancelled_by_therapist';
                    setAppointmentListData(appointmentList);
                    setComment('');
                }
            })
            .catch((err) => {
                console.error('Error acceptAppointment appointment', err);
            });
        setShowCancelModal(false);
    };
    const handleAcceptAppointment = async () => {
        const token = await getIdTokenClaims();
        await fetchFromRestAPI(`/api/v1/appointment/therapist/confirm/${appointmentId}`, {
            method: 'POST',
            token,
        })
            .then((response) => {
                if (response && !response.error) {
                    const index = appointmentList.findIndex((item) => item._id === appointmentId);
                    appointmentList[index].status = 'confirmed';
                    setAppointmentListData(appointmentList);
                }
            })
            .catch((err) => {
                console.error('Error accept appointment', err);
            });
        setShowAcceptModal(false);
    };
    const handleRejectAppointment = async () => {
        const token = await getIdTokenClaims();
        await fetchFromRestAPI(`/api/v1/appointment/therapist/reject/${appointmentId}`, {
            method: 'POST',
            token,
            body: { comment: comment }
        })
            .then((response) => {
                if (response && !response.error) {
                    const index = appointmentList.findIndex((item) => item._id === appointmentId);
                    appointmentList[index].status = 'rejected';
                    setAppointmentListData(appointmentList);
                    setComment('');
                }
            })
            .catch((err) => {
                console.error('Error reject appointment', err);
            });
        setShowAcceptModal(false);
        setShowRejectModal(false);
    };
    const variantByStatus = {
        pending: 'badge-bg-secondary',
        confirmed: 'badge-bg-info',
        cancelled_by_therapist: 'badge-bg-danger',
        cancelled_by_patient: 'badge-bg-danger',
        rejected: 'badge-bg-danger',
        requested: 'badge-bg-secondary',
    };
    const appointmentStatusBadge = (status) => {
        return <Badge className={'foat-left badge-status align-top mr-3 w-105 ' + variantByStatus[status]}>
            {i18next.t(`createAppointmentForm.appointmentStatus.${status}`)}</Badge>;
    };
    useEffect(() => {
        setAppointmentListData(appointmentList);
    }, [appointmentList])
    return (
        <AppointmentListWrapper>
            <ConfirmationModal
                show={showCancelModel}
                title={i18next.t('createAppointmentForm.deleteModal.title')}
                body={
                    <>
                        <p>{i18next.t('createAppointmentForm.deleteModal.body')}</p>
                        <Form.Control as='textarea' rows={3} value={comment}
                            onChange={e => { comment.length < 200 ? setComment(e.target.value) : setComment(comment.slice(0, 200)) }}
                        />
                    </>
                }
                buttonPositive={i18next.t('createAppointmentForm.labels.delete')}
                buttonNegative={i18next.t('generic.cancel')}
                onPositive={handelCancelAppointment}
                onNegative={() => setShowCancelModal(false)}
            />
            <ConfirmationModal
                show={showAcceptModal}
                title={i18next.t('createAppointmentForm.acceptModal.title')}
                body={
                    <>
                        <p>{i18next.t('createAppointmentForm.acceptModal.body')}</p>
                    </>
                }
                buttonPositive={i18next.t('createAppointmentForm.labels.accept')}
                buttonNegative={i18next.t('generic.cancel')}
                onPositive={handleAcceptAppointment}
                onNegative={() => setShowAcceptModal(false)}
            />
            <ConfirmationModal
                show={showRejectModal}
                title={i18next.t('createAppointmentForm.rejectModal.title')}
                body={
                    <>
                        <p>{i18next.t('createAppointmentForm.rejectModal.body')}</p>
                        <Form.Control as='textarea' rows={3} value={comment}
                            onChange={e => { comment.length < 200 ? setComment(e.target.value) : setComment(comment.slice(0, 200)) }}
                        />
                    </>
                }
                buttonPositive={i18next.t('createAppointmentForm.labels.reject')}
                buttonNegative={i18next.t('generic.cancel')}
                onPositive={handleRejectAppointment}
                onNegative={() => setShowRejectModal(false)}
            />

            <div className="patient-overview">
                {appointmentListData.length > 0 ?
                    <>
                        {map(appointmentListData, (appointment) => {
                            return (
                                <Row key={'request-' + appointment._id}>
                                    <Col xl={3} md={4} className='text-wrap'>

                                        {moment.parseZone(appointment.startDateTime).format('DD-MMM-YYYY HH:mm')}
                                        {' - '}
                                        {moment.parseZone(appointment.endDateTime).format('HH:mm')}

                                    </Col>
                                    <Col xl={3} md={4} className='text-wrap'>
                                        <span className='align-middle font-weight-bold'>{appointment.patient?.firstName} {appointment.patient?.lastName}</span>
                                    </Col>


                                    <Col xl={2} md={4}>
                                        {appointmentStatusBadge(appointment.status)}
                                    </Col>
                                    {appointment.status === 'rejected' || appointment.status === 'cancelled_by_therapist' || appointment.status === 'cancelled_by_patient' ?
                                        <Col xl={4} md={4} className="text-right">

                                        </Col>
                                        :
                                        <Col xl={4} md={4} className="text-right">
                                            {appointment.status === 'pending' ?
                                                <Button className="mr-2"
                                                    onClick={() => {
                                                        setAppointmentId(appointment._id);
                                                        setShowAcceptModal(true);
                                                    }} variant="primary">
                                                    <i className="fas fa-check" aria-hidden="true" /> {i18next.t('generic.apporove')}
                                                </Button> : null}
                                                {appointment.status === 'pending' ?
                                                    <Button className="mr-2"
                                                        onClick={() => {
                                                            setAppointmentId(appointment._id);
                                                            setShowRejectModal(true);
                                                        }}
                                                        variant="secondary"
                                                    >
                                                        <i className="fa fa-times" aria-hidden="true" /> {i18next.t('generic.reject')}
                                                    </Button> : null}
                                                {appointment.status === 'confirmed' ? <Button className="mr-2"
                                                onClick={() => {
                                                    setAppointmentId(appointment._id);
                                                    setShowCancelModal(true);
                                                }}
                                                variant="secondary">
                                                <i className="fas fa-times" aria-hidden="true" /> {i18next.t('createAppointmentForm.labels.cancel')}
                                            </Button> : null
                                            }
                                           
                                            {appointment.status === 'confirmed' ?
                                                <Button className="mr-2" onClick={() => history.push(`/${appointment.roomCode}`)} variant="secondary">
                                                    {i18next.t('patients.enterRoom')}
                                                </Button>
                                                : null}
                                            
                                        </Col>
                                    }
                                </Row>
                            );
                        })}
                    </> : null}
            </div>
        </AppointmentListWrapper>
    );
};

export default AppointmentListGlobal;
