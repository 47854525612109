import React, { FC, memo } from 'react';
import LoginPageLayout from '../components/loginSignup/LoginPageLayout';
import PatientRoomCodeBox from '../components/loginSignup/PatientRoomCodeBox';
import LoginBoxAuth0Lock from '../components/loginSignup/LoginBoxAuth0Lock';
import { useAuth0 } from '../util/auth0';
import { Redirect } from 'react-router-dom';

const LoginPageContainer: FC<{signIn:boolean}> = ({signIn}) => {
  const { isAuthenticated, isApprovedTherapist } = useAuth0();

  if (isAuthenticated && isApprovedTherapist) {
    return <Redirect to="/patienten" />;
  }

  if (isAuthenticated) {
    return <Redirect to="/account-setup" />;
  }
  return <LoginPageLayout patientRoomLoginComponent={<PatientRoomCodeBox />} loginComponent={<LoginBoxAuth0Lock signIn={signIn} />} signIn={signIn} />;
};

export default memo(LoginPageContainer);
