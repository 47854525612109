import React, { FC } from 'react';
import { LocalVideoTrack, Participant, RemoteVideoTrack } from 'twilio-video';

import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import styled from 'styled-components';
import useFullScreenToggle from '../../hooks/useFullScreenToggle/useFullScreenToggle';
import { FaVideoSlash } from 'react-icons/fa';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

interface MainParticipantContainerProps {
  fullScreen?: boolean;
}

const Container = styled.div<MainParticipantContainerProps>`
  position: relative;
  background-color: #595959;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '0' : 'auto')};
  margin-bottom: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '0' : '1em')};
  width: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '100%' : 'calc(90vh - 10em)')};
  min-height: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '100%' : 'calc(60vh - 10em)')};

  max-height: calc(85vh - 10em);
  border-radius: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '0' : '1rem')};
  overflow: hidden;

  & video {
    position: absolute;
    z-index: 0;
    background-size: 100% 100%;
    top: 0px;
    left: 0px;
    min-width: 100%;
    min-height: 100%;
  }

  & svg {
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '0' : '4em 1em 2em 1em')};
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    width: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '100%' : '80%')};
    height: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '100vh' : '70vh')};
    min-height: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '100vh' : '70vh')};
    margin-top: ${(props: MainParticipantContainerProps) => (props.fullScreen ? '0' : '2rem')};
  }
`;

const ContainerVideoSwitchedOff = styled(Container)`
  border-radius: 1rem;

  & video {
    filter: blur(4px) grayscale(1) brightness(0.5);
  }
`;

const NoVideoWrapper = styled.div`
  position: absolute;
  z-index: 0;
  background-size: 100% 100%;
  top: 0px;
  left: 0px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 100%;
  padding: 0.4em;
  width: 100%;
`;

const Identity = styled.h4`
  color: #ffffff;
  padding: 0.1em 0.3em;
  margin: 1em;
  font-size: 1.2em;
  display: inline-flex;
  & svg {
    margin-left: 0.3em;
  }
`;

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

const MainParticipantInfo: FC<MainParticipantInfoProps> = ({ participant, children }) => {
  const publications = usePublications(participant);
  const videoPublication = publications.find((p) => p.trackName.includes('camera'));
  const screenSharePublication = publications.find((p) => p.trackName.includes('screen'));
  const isVideoEnabled = Boolean(videoPublication);
  const [isFullScreen] = useFullScreenToggle(true);

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const Wrapper = isVideoSwitchedOff ? ContainerVideoSwitchedOff : Container;
  const { isP2P } = useVideoContext();

  return (
    <Wrapper data-cy-main-participant id="main-participant-info" fullScreen={isFullScreen || isP2P}>
      <InfoContainer>
        <Identity />
      </InfoContainer>
      {!isVideoEnabled && (
        <NoVideoWrapper>
          <FaVideoSlash />
        </NoVideoWrapper>
      )}
      {children}
    </Wrapper>
  );
};

export default MainParticipantInfo;
