import i18next from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { formatDate } from '../util/dateTimeUtils';
import FormGroup from './FormGroup';

const Wrapper = styled.div`
  display: flex;
  padding: 3rem 2rem;
  justify-content: center;
`;

const EditForm = styled.div`
  width: 30%;
`;

const FormFooter = styled.div`
  display: flex;
  flex-flow: row-reverse;
`;

const StyledCalendar = styled(Calendar)`
  width: 70%;
  height: 70vh;
  padding-right: 2rem;
`;

const localizer = momentLocalizer(moment);

const ScheduleSession = ({ patientWithSessions, scheduledSessions, onNewScheduledSessionCreated }) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [shouldSendInviteEmail, setShouldSendInviteEmail] = useState(false);
  const [inviteMessage, setInviteMessage] = useState('');
  const [inviteEmailAdress, setInviteEmailAdress] = useState(patientWithSessions.email);

  const handleCalendarSelect = ({ start, end }) => {
    setSelectedTimeSlot({
      start: start,
      end: end,
    });
  };

  const calendarEvents = () => {
    let calendarEntries = patientWithSessions.sessions.map((session, idx) => {
      return {
        id: idx,
        title: `Past session with ${patientWithSessions.name}`,
        start: new Date(session.start),
        end: new Date(session.end),
      };
    });

    if (scheduledSessions) {
      calendarEntries = [
        ...calendarEntries,
        ...scheduledSessions.map((session, idx) => {
          return {
            id: calendarEntries.length + idx,
            title:
              patientWithSessions.id === session.patient
                ? `Geplante Stunde mit ${patientWithSessions.name}`
                : 'Geplante Stunde',
            start: new Date(session.start),
            end: new Date(session.end),
          };
        }),
      ];
    }

    if (selectedTimeSlot) {
      calendarEntries.push({
        id: calendarEntries.length,
        title: `Nächste Sitzung mit ${patientWithSessions.name}`,
        start: selectedTimeSlot.start,
        end: selectedTimeSlot.end,
      });
    }

    return calendarEntries;
  };

  const handleFormSubmit = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      console.log('form is invalid');
    } else {
      onNewScheduledSessionCreated(
        {
          patient: patientWithSessions.id,
          start: selectedTimeSlot.start.toISOString(),
          end: selectedTimeSlot.end.toISOString(),
        },
        shouldSendInviteEmail,
        inviteEmailAdress,
        inviteMessage
      );
    }
  };

  return (
    <Wrapper>
      <StyledCalendar
        selectable
        localizer={localizer}
        onSelectSlot={handleCalendarSelect}
        defaultView={Views.WEEK}
        scrollToTime={new Date()}
        events={calendarEvents()}
      />
      <EditForm>
        {selectedTimeSlot ? (
          <Form onSubmit={handleFormSubmit}>
            <Form.Row>
              <FormGroup as={Col}>
                <Form.Label>Start</Form.Label>
                <Form.Control value={formatDate(selectedTimeSlot.start)} disabled={true} />
              </FormGroup>
            </Form.Row>
            <Form.Row>
              <FormGroup as={Col}>
                <Form.Label>Ende</Form.Label>
                <Form.Control value={formatDate(selectedTimeSlot.end)} disabled={true} />
              </FormGroup>
            </Form.Row>
            <Form.Row>
              <FormGroup as={Col}>
                <Form.Check
                  type="checkbox"
                  label=" Einladung per E-Mail versenden"
                  onChange={(e) => setShouldSendInviteEmail(e.target.checked)}
                />
              </FormGroup>
            </Form.Row>
            {shouldSendInviteEmail ? (
              <React.Fragment>
                <Form.Row>
                  <FormGroup as={Col}>
                    <Form.Label>{i18next.t('scheduleSession.labels.inviteEmailAddress')}</Form.Label>
                    <Form.Control
                      required
                      value={inviteEmailAdress}
                      onChange={(e) => setInviteEmailAdress(e.target.value)}
                    />
                  </FormGroup>
                </Form.Row>
                <Form.Row>
                  <FormGroup as={Col}>
                    <Form.Label>Nachricht</Form.Label>
                    <Form.Control
                      disabled={!shouldSendInviteEmail}
                      as="textarea"
                      rows={3}
                      placeholder={i18next.t('scheduleSession.placeholders.inviteMessage')}
                      value={inviteMessage}
                      onChange={(e) => {
                        setInviteMessage((e.target as HTMLInputElement).value);
                      }}
                    />
                  </FormGroup>
                </Form.Row>
              </React.Fragment>
            ) : null}

            <FormFooter>
              <Button variant="primary" type="submit">
                {shouldSendInviteEmail ? 'Termin erstellen & Einladung versenden' : 'Termin erstellen'}
              </Button>
            </FormFooter>
          </Form>
        ) : (
          <div>Selektiere den gewünschten Termin im Kalender</div>
        )}
      </EditForm>
    </Wrapper>
  );
};

export default ScheduleSession;
