import { FC, useEffect } from 'react';
import useParticipantSentMessage from '../useParticipantSentMessage/useParticipantSentMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { LocalVideoTrack } from 'twilio-video';
import generateVideoScreenshot from '../../../util/generateVideoScreenshot';
import useDataTrack, { CallMessagingAction } from '../../../hooks/useDataTrack/useDataTrack';
import { getFullURL } from '../../../util/endpoints';

/*
  This component adds a screenshot provider which generates a screenshot from the users local video
  track and sends the screenshot as image to the host participant
*/
const GenerateScreenshotHandler: FC = () => {
  const participantSentMessage = useParticipantSentMessage();
  const { localTracks } = useVideoContext();
  const [sendMessage] = useDataTrack();
  const localVideoTrack = localTracks.find((track) => track.name.includes('camera')) as LocalVideoTrack;

  useEffect(() => {
    const generateScreenshot = async ({ uploadToken }) => {
      try {
        if (!localVideoTrack) {
          sendMessage({
            action: CallMessagingAction.ACTION_SCREENSHOT_NOT_POSSIBLE,
          });
          return;
        }
        console.log(`Request to generate screenshot received.`);
        const screenshotObj = await generateVideoScreenshot(localVideoTrack);
        console.log(`Generated screenshot with dimensions ${screenshotObj.width}x${screenshotObj.height}`);
        // no fetchFromRestAPI since blob instead of JSON is posted
        const response = await fetch(getFullURL(`/api/v1/upload/${uploadToken}`), {
          method: 'POST',
          body: screenshotObj.blob,
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const { key, contentType } = await response.json();
        sendMessage({
          action: CallMessagingAction.ACTION_SCREENSHOT_GENERATED,
          key,
          contentType,
        });
      } catch (ex) {
        console.error(ex);
      }
    };

    participantSentMessage.on(CallMessagingAction.ACTION_REQUEST_SCREENSHOT, (eventData) => {
      generateScreenshot(eventData).catch((err) => {
        console.error('Error on generating screenshot object', err);
      });
    });
    return () => {
      participantSentMessage.off(CallMessagingAction.ACTION_REQUEST_SCREENSHOT);
    };
  }, [localTracks, participantSentMessage, localVideoTrack, sendMessage]);
  return null;
};

export default GenerateScreenshotHandler;
