import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import { FaThLarge, FaSquare } from 'react-icons/fa';
import ControlsTooltip from './ControlsToolTip';
import useRoomView from '../../../hooks/useRoomView/useRoomView';

const ToggleViewButton: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const [roomView, changeRoomView] = useRoomView();

  const toggleView = () => {
    if (roomView === 'grid') {
      changeRoomView('standard');
    } else {
      changeRoomView('grid');
    }
  };

  return (
    <ControlsTooltip id="tooltip-toggle-audio" label={roomView === 'grid' ? 'Standardansicht' : 'Rasteransicht'}>
      <Button onClick={toggleView} disabled={disabled}>
        {roomView === 'grid' ? <FaSquare /> : <FaThLarge />}
      </Button>
    </ControlsTooltip>
  );
};

export default ToggleViewButton;
