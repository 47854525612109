import React, { FC } from 'react';
import styled from 'styled-components';

import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import Participant from './Participalnt';
import { isMobile } from 'mobile-device-detect';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';

interface ScrollContainerContainerProps {
  isVertical?: boolean;
  isHighlighted?: boolean;
}

const Container = styled.div<ScrollContainerContainerProps>`
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: participantList;
  flex-wrap: wrap-reverse;
  border: ${(props: ScrollContainerContainerProps) => (props.isHighlighted ? '0' : '0px')};
  border-radius: 1rem;
`;

const ParticipantSwitch: FC<{ isHighlighted?: boolean }> = ({ isHighlighted }) => {
  const {
    isCallHost,
    isP2P,
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const mainParticipant = useMainSpeaker();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  return (
    <Container isHighlighted={isHighlighted}>
      {(mainParticipant !== localParticipant || !participants || !participants.length) && (
        <Participant
          participant={localParticipant}
          isSelected={selectedParticipant === localParticipant || isHighlighted}
          onClick={() => setSelectedParticipant(localParticipant)}
          disableAudio
        />
      )}
      {participants
        .filter(
          (participant) =>
            (participant && participant.identity === 'host' && participant !== mainParticipant) ||
            (isP2P && mainParticipant !== participant) ||
            (isMobile && participant.identity === localParticipant.identity && mainParticipant !== localParticipant) ||
            (isCallHost && participant !== mainParticipant)
        )
        .map((participant) => (
          <Participant
            key={participant.sid}
            participant={participant}
            isSelected={selectedParticipant === participant || isHighlighted}
            disableAudio
            onClick={() => setSelectedParticipant(participant)}
          />
        ))}
    </Container>
  );
};

export default ParticipantSwitch;
