import React, { memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import packageJson from '../../package.json';
import i18next from 'i18next';

const Wrapper = styled.div`
  color: inherit;
  margin: 2rem;
`;

const Content = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
    color: #161616;
  }
`;

const About = () => {
  const version=packageJson.version;
  return (
    <Container>
      <Wrapper>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Content>
              <h1>{i18next.t('about.heading')}</h1>
              <div className="mb-3">
                <ReactSVG src="/ce.svg" />
              </div>
              <div dangerouslySetInnerHTML={{ __html: i18next.t('about.aboutHtml',{version:version}) }}>
               
              </div>
            </Content>
          </Col>
        </Row>
      </Wrapper>
    </Container>
  );
};

export default memo(About);
