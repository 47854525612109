import React, { memo } from 'react';
import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';
import { useHistory } from 'react-router';
import i18next from 'i18next';

const Wrapper = styled.div`
    background: #ffffff;
    width : 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content : center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content : center;
    align-items: center;
`;

const TitleText = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 100%;
    color: #FF695F;
`;

const SubHeading = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 150%;
    color: #161616;
`;

const ContentText = styled.span`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #161616;
    margin-bottom: 15px;
`;

const NotFound = () => {
    const history = useHistory();
    return (
        <Wrapper>
            <Content>
                <TitleText>404</TitleText>
                <SubHeading>{i18next.t('notFound.subTitle')}</SubHeading>
                <ContentText>{i18next.t('notFound.body')}</ContentText>
                <PrimaryButton onClick={() => { history.push(`/therapien`); }}>
                    {i18next.t('notFound.buttonText')}
                </PrimaryButton>
            </Content>
        </Wrapper>
    );
};

export default memo(NotFound);
