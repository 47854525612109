import moment from 'moment';
import 'moment/locale/de';
import React, { FC } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { formatDuration } from '../util/dateTimeUtils';

type Statistics = {
  day: Date;
};

const CallStatisticsPerDayChart: FC<{ callStatisticsPerDay: Statistics[] }> = ({ callStatisticsPerDay }) => {
  const chartData = callStatisticsPerDay.map((statistics: Statistics) => ({
    dateFormatted: moment(statistics.day).locale('de').format('dd, Do MMM'),
    ...statistics,
  }));

  return (
    <ResponsiveContainer>
      <BarChart data={chartData} margin={{ top: 0, right: 30, left: 20, bottom: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dateFormatted" />
        <YAxis name="hours" unit="h" />
        <Tooltip
          content={({ active, payload, label }: TooltipProps) => {
            if (active) {
              return (
                <div className="custom-tooltip">
                  <p className="label">
                    <strong>{label}: </strong>
                    {formatDuration(moment.duration(payload?.[0]?.value as number, 'hour'))}
                  </p>
                </div>
              );
            }
            return null;
          }}
        />
        <Bar fill="#FFAB24" dataKey="durationInHours" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CallStatisticsPerDayChart;
