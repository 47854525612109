import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import styled from 'styled-components';
import SecondaryButton from '../../components/SecondaryButton';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import { isEmpty, map } from 'lodash';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Image from 'react-bootstrap/Image'
import { getFullURL } from '../../util/endpoints';
import ReactQuill from 'react-quill';

const ActionButton = styled(SecondaryButton)`
  float: center;
`;

const IconButton = styled(Button)`
  white-space: nowrap;
  background-color:#ff695f;
`;
const modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        ['link', 'image'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ],
};

const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet'
];
const InsurancePlan = () => {
    const planInit = {
        _id: null,
        planName: "",
        planCode: "",
        planDescription: "",
        headerTitle: null,
        headerImage: null,
        price: 0,
        videoUrl: null,
        planLogo: null,
        status: "Active",
        planLogoURL: null,
        isCouponRequired: false
    };
    const imageValue = "<img src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyNCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5LjAzNDUgMjAuNjU5OEwxOS4wMjM0IDIwLjY4OTdIOC4wMTM5OUMzLjYyOTA0IDIwLjY4OTcgMC4xMTA1NjkgMTcuMzMxOCAwLjAwMjYwNjg1IDEzLjA0NTJDLTAuMDY2NiAxMC4zNTgxIDEuMjQ1NTYgNy44NTA5IDMuNTM0OTIgNi4zMjE0MUwzLjU1NzA3IDYuMzA0ODRDNC44Mjc3MSA1LjQ2NTM4IDYuMzA4NzMgNS4wMDM1NSA3LjgzNDA1IDQuOTY4MjNMOC4wMDg0NSA0Ljk2NTUyQzEwLjQwMyA0Ljk2NTUyIDEyLjYzMTUgNi4xMjgyNSAxMy45NzEzIDguMDc4ODNMMTMuOTk5IDguMTE2ODZDMTQuNzY4NiA5LjI1MjQzIDE1LjE5MjEgMTAuNTM3NCAxNS4yMjgxIDExLjgzNjNWMTUuNzc1NUgxMC43NDlMOS4xMjEzIDEzLjM4NzJIMTIuNzkyVjExLjg4NDlDMTIuNzY3MSAxMS4wMzQ2IDEyLjQ3NjQgMTAuMTgxOCAxMS45NTYgOS40MjA4N0MxMS4wNDUyIDguMDkyNDEgOS41MTk5MyA3LjMyMDg4IDcuODk3NzIgNy4zNTg5MUM2LjgyOTE3IDcuMzgzMzYgNS43OTkzNyA3LjcwNDIgNC45MTYyOSA4LjI4ODAxQzMuMzE2MjMgOS4zNDUwNyAyLjM4ODg2IDExLjEgMi40Mzg2OSAxMi45ODUyQzIuNTEzNDMgMTUuOTc2MiA0Ljk1NzgyIDE4LjI5OTIgOC4wMTM5OSAxOC4yOTkyQzguMDM4OSAxOC4yOTkyIDguMDYxMDUgMTguMjk5MiA4LjA4NTk2IDE4LjI5NjNIMTcuNDIwNkwxOS4wMzQ1IDIwLjY1OThaIiBmaWxsPSIjMTlCQUJBIi8+CjxwYXRoIGQ9Ik0yMy4zNjc2IDEwLjg1ODZMMTkuMzQ4MyAyMC4xNzE5TDE5LjI1MzQgMjAuMzkwNEwxOS4xMjUxIDIwLjY4OTdMMTcuNDk3OCAxOC4zNDI0TDE3LjYxMjMgMTguMDIxNkwyMS4xMDEyIDkuOTQxMjZDMjEuODQwOCA4LjIzODg1IDIxLjY0NTUgNi4zMDk4MSAyMC41NzkyIDQuNzc3MzdDMTkuOTc5MSAzLjkxNDAyIDE5LjEzOSAzLjIzOTUzIDE4LjE1MzcgMi44MjY0N0MxNi4zMTE2IDIuMDQ5NzMgMTQuMTkwMyAyLjI3NjA5IDEyLjU3NyAzLjQwOTVDMTEuNzM2OSAyLjk5NjQ1IDEwLjg1MjEgMi42OTk5NCA5LjkzMTAzIDIuNTI0M0MxMi4yNDQ5IDAuMDg4MzE2OCAxNS45NTk5IC0wLjY4ODk2NSAxOS4xMzYyIDAuNjUyMTlDMTkuODQ1MiAwLjk0ODk2NSAyMC40OTgzIDEuMzQwMTcgMjEuMDg0NCAxLjgwOTYxQzIxLjY3MDYgMi4yODE3NiAyMi4xODQxIDIuODM0ODQgMjIuNjE2OCAzLjQ1NTM3QzI0LjE0MzUgNS42NDg1NCAyNC40MjU0IDguNDE2NjQgMjMuMzY3NiAxMC44NTg2WiIgZmlsbD0iIzE5QkFCQSIvPgo8L3N2Zz4K'/>&nbsp;";
    const styleValue = "style='list-style-type:none; list-style: none;'";
    const inputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [planList, setPlanList] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(planInit);
    const { getIdTokenClaims, user } = useAuth0();
    const [image, setImage] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);
    const fetchPlanList = async () => {
        setSelectedPlan(planInit);
        setIsLoading(true);
        setImage(null);
        setSelectedFile(null);
        const token = await getIdTokenClaims();
        if (token) {
            const plans = await fetchFromRestAPI('/api/v1/insurance/all', {
                method: 'GET',
                token,
            });
            if (plans && plans.length > 0) {
                setPlanList(plans);
            }
        }
        setIsLoading(false);
    }
    const handleDisplayFileDetails = () => {
        if (inputRef.current?.files && inputRef.current.files[0]) {
            setSelectedFile(inputRef.current.files[0]);
            setImage(URL.createObjectURL(inputRef.current.files[0]));
        }
    }
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const editPlan = (plan) => {
        if (plan.planDescription) {
            plan.planDescription = plan.planDescription.split(styleValue).join('');
            plan.planDescription = plan.planDescription.split(imageValue).join('');
        }
        setSelectedPlan(plan);
    }
    const addPlan = async (plan) => {
        setIsLoading(true);
        const token = await getIdTokenClaims();
        const res = await fetch(getFullURL('/api/v1/insurance/add'), {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token.__raw}`,
            },
            body: plan
        });
        if (res) {
            fetchPlanList();
        }
        setIsLoading(false);
    }
    const savePlan = () => {
        if (selectedPlan && !isEmpty(selectedPlan.planCode)) {
            var data = new FormData();
            data.append('planName', selectedPlan.planName);
            data.append('planCode', selectedPlan.planCode);
            let dis = selectedPlan.planDescription;
            if (selectedPlan.planDescription && selectedPlan.planDescription.indexOf('<ul') >= 0 && selectedPlan.planDescription.indexOf(imageValue) < 0) {
                dis = dis.replace(/<li>/g, "<li " + styleValue + ">" + imageValue);
            }
            data.append('planDescription', dis ? dis : '');
            data.append('status', selectedPlan.status);
            data.append('isCouponRequired', selectedPlan.isCouponRequired.toString());
            if (!isEmpty(selectedPlan.headerTitle))
                data.append('headerTitle', selectedPlan.headerTitle);
            if (!isEmpty(selectedPlan.headerImage))
                data.append('headerImage', selectedPlan.headerImage);
            data.append('price', selectedPlan.price.toString());
            if (!isEmpty(selectedPlan.videoUrl))
                data.append('videoUrl', selectedPlan.videoUrl);
            if (!isEmpty(selectedPlan.planLogoURL))
                data.append('planLogoURL', selectedPlan.planLogoURL);

            if (selectedFile) {
                data.append(
                    "planLogo",
                    selectedFile,
                    selectedFile.name
                );
            }
            if (selectedPlan._id) {
                data.append('_id', selectedPlan._id);
                updatePlan(data);
            } else {
                addPlan(data);
            }
        } else {
            alert("Plan code and name is required");
        }
    }
    const updatePlan = async (plan) => {
        setIsLoading(true);
        const token = await getIdTokenClaims();
        const res = await fetch(getFullURL('/api/v1/insurance/update'), {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${token.__raw}`,
            },
            body: plan
        });
        if (res) {
            fetchPlanList();
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchPlanList();
        // eslint-disable-next-line
    }, [user]);

    return (
        <React.Fragment>
            <Table bordered className="h-75 overflow-auto">
                <tbody>
                    <tr>
                        <td className='w-50'>
                        <Form.Group key={'logoURL' + selectedPlan._id}>
                            <Form.Label>Plan Logo URL</Form.Label>
                            <Form.Control type="text"
                                defaultValue={selectedPlan.planLogoURL}
                                onChange={(e) => {
                                    selectedPlan.planLogoURL = e.target.value;
                                    setSelectedPlan(selectedPlan);
                                }}
                            />
                        </Form.Group>
                        <small>Please use url instead of upload logo</small>
                            <input ref={inputRef} className="d-none" type="file" accept="image/*" onChange={handleDisplayFileDetails} />
                            <Image src={image ? image : (selectedPlan.planLogo ? selectedPlan.planLogo : '../default_profile.png')} roundedCircle={true} className='border' width='80px' height='80px' />
                            <br />
                            <IconButton className='mt-4' onClick={handleUpload}>
                                <i className='fas fa-upload' />  Upload Plan Logo (Depreciated)
                            </IconButton>
                        </td>
                        
                        <td className='w-50'>
                            <Form.Group key={'dec' + selectedPlan._id}>
                                <Form.Label>Description</Form.Label>
                                <ReactQuill defaultValue={selectedPlan.planDescription} modules={modules}
                                    formats={formats}
                                    placeholder="Description"
                                    onChange={(value) => {
                                        selectedPlan.planDescription = value;
                                        setSelectedPlan(selectedPlan);
                                    }}

                                />

                            </Form.Group>
                        </td>

                    </tr>
                    <tr>
                        <td className='w-50'>
                            <Form.Group key={'code' + selectedPlan._id}>
                                <Form.Label>Plan Code</Form.Label>
                                <Form.Control type="text"
                                    defaultValue={selectedPlan.planCode}
                                    onChange={(e) => {
                                        selectedPlan.planCode = e.target.value;
                                        setSelectedPlan(selectedPlan);
                                    }}
                                />
                            </Form.Group>
                        </td>
                        <td className='w-50'>
                            <Form.Group key={'name' + selectedPlan._id}>
                                <Form.Label>Plan Name</Form.Label>
                                <Form.Control type="text"
                                    defaultValue={selectedPlan.planName}
                                    onChange={(e) => {
                                        selectedPlan.planName = e.target.value;
                                        setSelectedPlan(selectedPlan);
                                    }}
                                />
                            </Form.Group>
                        </td>

                    </tr>
                    <tr>
                        <td className='w-50'>
                            <Form.Group key={'title' + selectedPlan._id}>
                                <Form.Label>Header Title</Form.Label>
                                <Form.Control type="text"
                                    defaultValue={selectedPlan.headerTitle}
                                    onChange={(e) => {
                                        selectedPlan.headerTitle = e.target.value;
                                        setSelectedPlan(selectedPlan);
                                    }}
                                />
                            </Form.Group>
                        </td>
                        <td className='w-50'>
                            <Form.Group key={'image' + selectedPlan._id}>
                                <Form.Label>Header Image URL</Form.Label>
                                <Form.Control type="text"
                                    defaultValue={selectedPlan.headerImage}
                                    onChange={(e) => {
                                        selectedPlan.headerImage = e.target.value;
                                        setSelectedPlan(selectedPlan);
                                    }}
                                />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-50'>
                            <Form.Group key={'video' + selectedPlan._id}>
                                <Form.Label>Video URL (optional)</Form.Label>
                                <Form.Control type="text"
                                    defaultValue={selectedPlan.videoUrl}
                                    onChange={(e) => {
                                        selectedPlan.videoUrl = e.target.value;
                                        setSelectedPlan(selectedPlan);
                                    }}
                                />
                            </Form.Group>
                        </td>
                        <td className='w-50'>
                            <Form.Group key={'price' + selectedPlan._id}>
                                <Form.Label>Price</Form.Label>
                                <Form.Control type="number"
                                    defaultValue={parseFloat(selectedPlan.price.toString())}
                                    onChange={(e) => {
                                        selectedPlan.price = parseFloat(e.target.value);
                                        setSelectedPlan(selectedPlan);
                                    }}
                                />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td className='w-50'>
                            <Form.Group>
                                <Form.Control as="select" size="sm" defaultValue={selectedPlan.status} onChange={(e) => {
                                    selectedPlan.status = e.target.value;
                                    setSelectedPlan(selectedPlan);
                                }}>
                                    <option value="Active">Active</option>
                                    <option value="Deleted">Deleted</option>
                                </Form.Control>
                            </Form.Group>
                        </td>

                        <td className='w-50'>
                            <Form.Group>
                                <Form.Check
                                    inline
                                    label="isCouponRequired"
                                    type="checkbox"
                                    defaultChecked={selectedPlan.isCouponRequired}
                                    onChange={(e) => {
                                        selectedPlan.isCouponRequired = e.target.checked;
                                        setSelectedPlan(selectedPlan)
                                    }}
                                />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td className='centered-td' colSpan={2}>

                            <ActionButton className="m-1" onClick={() => savePlan()}>{selectedPlan._id ? "Save" : "Add New"}</ActionButton>
                            <ActionButton onClick={() => setSelectedPlan(planInit)}>Cancel</ActionButton>

                        </td>
                    </tr>
                </tbody>
            </Table>
            {
                isLoading ? 'Refreshing...' :
                    <Table striped bordered hover className="h-75 overflow-auto">
                        <thead>
                            <tr>
                                <th>Plan Logo<p><small>Upload logo is depreciated, small logo will not be displayed in the new app</small></p></th>
                                <th className='centered-td'>Plan Name</th>
                                <th className='centered-td'>Plan Code</th>
                                <th className='centered-td'>Description</th>
                                <th className='centered-td'>Header Title</th>
                                <th className='centered-td'>Header Image</th>
                                <th className='centered-td'>Video (Optional)</th>
                                <th className='centered-td'>Price</th>
                                <th className='centered-td'>Status</th>
                                <th className='centered-td'>isCouponRequired</th>
                                <th className='centered-td'>Actions</th>

                            </tr>
                        </thead>

                        <tbody key={planList.length} >

                            {map(planList, (plan) => {
                                return (
                                    <tr key={plan._id}>
                                        <td className='centered-td'>
                                        <Image src={plan.planLogoURL ? plan.planLogoURL : '../default_profile.png'}  className='border' width='70px' height='70px' />
                                       <p>&nbsp;</p>
                                            <Image src={plan.planLogo ? plan.planLogo : '../default_profile.png'} roundedCircle={true} className='border' width='30px' height='30px' />
                                        </td>
                                        <td className='centered-td'>{plan.planCode}</td>
                                        <td className='centered-td'>
                                            {plan.planName} </td>
                                        <td className='centered-td is-breakable'>
                                            <p dangerouslySetInnerHTML={{ __html: plan.planDescription }} /></td>
                                        <td className='centered-td is-breakable'> {plan.headerTitle}</td>
                                        <td className='centered-td is-breakable'>{plan.headerImage ? <img src={plan.headerImage} alt={plan.headerImage} width={100} height={100}></img> : null}</td>
                                        <td className='centered-td is-breakable'>{plan.videoUrl ? <a href={plan.videoUrl} target="_blank" rel="noopener noreferrer" >Open</a> : null}</td>
                                        <td className='centered-td'> {plan.price}</td>
                                        <td className='centered-td'>
                                            {plan.status}</td>

                                        <td className='centered-td'>
                                            {plan.isCouponRequired ? 'YES' : 'NO'}
                                        </td>
                                        <td className='centered-td'>
                                            <ActionButton onClick={() => editPlan(plan)}>Edit</ActionButton>
                                        </td>
                                    </tr>
                                );
                            })}

                        </tbody>
                    </Table>
            }
        </React.Fragment >
    );
};

export default InsurancePlan;
