import React, { FC } from 'react';
import styled from 'styled-components';

import ParticipantStrip from './ParticipantStrip';
import MainParticipant from './MainParticipant';
import ControlsBar from './Controls/ControlsBar';
import EndCallButton from './Controls/EndCallButton';
import MuteAllAudioButton from './Controls/MuteAllAudioButton';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ToggleVideoButton from './Controls/ToggleVideoButton';
import ToggleAudioButton from './Controls/ToggleAudioButton';
import ParticipantNetworkIndicator from './ParticipantNetworkIndicator';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #002b4b;
  display: grid;
  grid-template-areas:
    'mainParticipant'
    'participantList'
    'bottomControlsBar';
  grid-template-rows: calc(95vh - 17em) 12em 5em;
  grid-template-columns: 1fr;

  @media only screen and (max-width: 812px) {
    grid-template-areas:
      'mainParticipant'
      'participantList'
      'bottomControlsBar';
    grid-template-rows: calc(95vh - 17em) 16em 5em
    grid-template-columns: 1fr;
  }
`;

const NetworkIndicatorContainer = styled.div`
  position: absolute;
  left: 2rem;
  top: 2.3rem;

  @media only screen and (max-width: 768px) {
    left: 0.9rem;
    top: 1.8rem;
  }
`;

const MainParticipantContainer = styled.div`
  position: relative;
  grid-area: mainParticipant;
  display: flex;
  justify-content: center;
`;

const BottomControlsBar = styled.div`
  position: relative;
  grid-area: bottomControlsBar;

  @media only screen and (max-width: 768px) {
    display: none;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    display: none;
  }
`;
const BottomControlsBarMobile = styled.div`
  position: relative;
  grid-area: bottomControlsBar;

  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    font-size: 12px !important;
    :first-child {
      margin-right: 5px;
    }
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
    display: none;
  }
`;

const MuteAllWrapper = styled.div`
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  z-index: 2;
`;

const GroupRoom: FC = () => {
  const {
    isCallHost,
    isP2P,
    room: { localParticipant },
    pinCode,
  } = useVideoContext();

  return (
    <Container>
      <MainParticipantContainer id="main-video-container">
        {!isP2P && isCallHost && (
          <NetworkIndicatorContainer>
            <ParticipantNetworkIndicator participant={localParticipant} />
          </NetworkIndicatorContainer>
        )}
        <EndCallButton pin={pinCode} />
        <MainParticipant />
      </MainParticipantContainer>
      <ParticipantStrip />
      <BottomControlsBar>
        {isCallHost && !isP2P && (
          <MuteAllWrapper>
            <MuteAllAudioButton />
          </MuteAllWrapper>
        )}
        <ControlsBar isCallHost={isCallHost} isConnected isP2P={isP2P} />
      </BottomControlsBar>
      <BottomControlsBarMobile>
        <ToggleVideoButton showLabel />
        <ToggleAudioButton showLabel />
      </BottomControlsBarMobile>
    </Container>
  );
};

export default GroupRoom;
