import { useEffect, useState } from 'react';
import useParticipantSentMessage from '../../components/VideoProvider/useParticipantSentMessage/useParticipantSentMessage';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import useDataTrack, { CallMessagingAction } from '../useDataTrack/useDataTrack';
import useVideoContext from '../useVideoContext/useVideoContext';
import { emitNotification } from '../../components/Notification';
import i18next from 'i18next';

export default function useRequestScreenshot(): {
  isWaitingForScreenshot: boolean;
  screenshotReceived?: string;
  requestScreenshot: () => Promise<void>;
  cancel: () => Promise<void>;
  attachScreenshotToSession: () => Promise<unknown>;
} {
  const { room, sessionId } = useVideoContext();

  const [isWaitingForScreenshot, setIsWaitingForScreenshot] = useState(false);
  const [screenshot, setScreenshot] = useState(null);
  const participantSentMessage = useParticipantSentMessage();
  const { getIdTokenClaims } = useAuth0();

  const [sendMessage] = useDataTrack();

  const requestScreenshot = async () => {
    const token = await getIdTokenClaims();
    const { uploadToken } = await fetchFromRestAPI(`/api/v1/upload/request-token/${sessionId}`, {
      method: 'POST',
      token,
    });

    // send request screenshot message to other participant so that the other participant can upload the screenshot
    sendMessage({
      action: CallMessagingAction.ACTION_REQUEST_SCREENSHOT,
      uploadToken,
    });
    setIsWaitingForScreenshot(true);
  };

  const deleteUploadedImage = async (key: string) => {
    const token = await getIdTokenClaims();
    await fetchFromRestAPI(`/api/v1/upload/${key}`, {
      method: 'DELETE',
      token,
    });
  };

  const cancel = async () => {
    if (screenshot?.key) {
      await deleteUploadedImage(screenshot.key);
    }
    setIsWaitingForScreenshot(false);
    setScreenshot(null);
  };

  const createFileRemote = async (key: string, sessionId: string, contentType: string) => {
    const token = await getIdTokenClaims();
    const fileObject = {
      type: contentType,
      session: sessionId,
      url: key,
      name: 'screenshot',
      patient: room.name, // note: this only holds for P2P rooms
    };
    return fetchFromRestAPI('/api/v1/files', {
      method: 'POST',
      token,
      body: fileObject,
    });
  };

  const attachScreenshotToSession = async () => {
    if (!sessionId) {
      console.error('Cannot create file for screenshot if no session ID is present');
      return;
    }
    const { key, contentType } = screenshot;
    setScreenshot(null);
    const fileRemote = await createFileRemote(key, sessionId, contentType);
    return fileRemote;
  };

  useEffect(() => {
    participantSentMessage.on(CallMessagingAction.ACTION_SCREENSHOT_GENERATED, ({ key, contentType }) => {
      if (!key) {
        console.error(
          `Invalid event received for action ${CallMessagingAction.ACTION_SCREENSHOT_GENERATED}. Missing property key`
        );
        setIsWaitingForScreenshot(false);
      } else {
        setScreenshot({ key, contentType });
        setIsWaitingForScreenshot(false);
      }
    });
    participantSentMessage.on(CallMessagingAction.ACTION_SCREENSHOT_NOT_POSSIBLE, () => {
      emitNotification(i18next.t('room.screenshot.not-possible'));
      setIsWaitingForScreenshot(false);
    });
    return () => {
      participantSentMessage.off(CallMessagingAction.ACTION_SCREENSHOT_NOT_POSSIBLE);
      participantSentMessage.off(CallMessagingAction.ACTION_SCREENSHOT_GENERATED);
    };
  }, [participantSentMessage]);

  return {
    isWaitingForScreenshot,
    screenshotReceived: screenshot?.key,
    requestScreenshot,
    cancel,
    attachScreenshotToSession,
  };
}
