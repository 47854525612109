import React, { FC } from 'react';
import styled from 'styled-components';

import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import Participant from './Participalnt';
import useFullScreenToggle from '../../hooks/useFullScreenToggle/useFullScreenToggle';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';

interface ScrollContainerContainerProps {
  isVertical?: boolean;
}

const Container = styled.div<ScrollContainerContainerProps>`
  overflow-x: auto;
  grid-area: participantList;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
`;
const ScrollContainer = styled.div``;

const ParticipantStrip: FC = () => {
  const {
    isCallHost,
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const mainParticipant = useMainSpeaker();
  const [isFullScreen] = useFullScreenToggle(true);
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  return (
    <Container>
      <ScrollContainer>
        {(participants.length < 1 || mainParticipant !== localParticipant) && (
          <Participant
            participant={localParticipant}
            isSelected={selectedParticipant === localParticipant}
            onClick={() => setSelectedParticipant(localParticipant)}
            disableAudio
          />
        )}
        {participants
          .sort((a, b) => a.sid.localeCompare(b.sid))
          .filter((participant) => {
            if (isFullScreen && !isCallHost) {
              if (participant && participant.identity !== 'host') {
                return false;
              }
            }
            return participant !== mainParticipant && participant.identity !== localParticipant.identity;
          })
          .map((participant) => (
            <Participant
              key={participant.sid}
              participant={participant}
              disableAudio
              isSelected={selectedParticipant === participant}
              onClick={() => setSelectedParticipant(participant)}
            />
          ))}
      </ScrollContainer>
    </Container>
  );
};

export default ParticipantStrip;
