import React, { FC } from 'react';
import { Participant } from 'twilio-video';
import styled from 'styled-components';

const IndicatorWrapper = styled.span`
  margin-bottom: 5px;
  width: 14px;
  height: 14px;
  color: #fff;
  border-radius: 100%;
  background-color: #ff695f;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    cursor: default;
    font-size: 10px;
    font-weight: 800;
    line-height: 12px;
  }
`;

const ParticipantTrainerIndicator: FC<{ participant: Participant }> = ({ participant }) => {
  return (
    (participant.identity === 'host' || !participant) && (
      <IndicatorWrapper>
        <span>T</span>
      </IndicatorWrapper>
    )
  );
};

export default ParticipantTrainerIndicator;
