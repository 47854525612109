import i18next from 'i18next';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import StyledModal from '../StyledModal';
import Form from 'react-bootstrap/Form';

type Props = {
  show: boolean;
  onPositive: (id) => void;
  onNegative: () => void;
};

export const SelectCameraModal: FC<Props> = ({ show, onPositive, onNegative }) => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const gotDevices = useCallback(
    (dev) => {
      const filteredDevices = [];
      let count = 0;
      dev.forEach((mediaDevice) => {
        if (mediaDevice.kind === 'videoinput') {
          if (!selectedDevice) {
            setSelectedDevice(mediaDevice.deviceId);
          }
          const oneDevice = {
            label: mediaDevice.label || `Camera ${count++}`,
            id: mediaDevice.deviceId,
          };
          filteredDevices.push(oneDevice);
          setDevices(filteredDevices);
        }
      });
    },
    [setDevices, selectedDevice]
  );

  const deviceSelected = (e) => {
    if (e && e.target && e.target.value) {
      setSelectedDevice(e.target.value);
    }
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(gotDevices);
  }, [gotDevices]);

  return (
    <StyledModal show={show} onHide={() => onNegative()}>
      <Modal.Header closeButton>
        <Modal.Title>{i18next.t('room.controls.cameraSwitch.changeActiveCamera')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{i18next.t('room.controls.cameraSwitch.selectCameraFromList')}</p>
        <Form.Control as="select" onChange={deviceSelected}>
          {devices.map((d) => {
            return (
              <option key={d.id} value={d.id}>
                {d.label}
              </option>
            );
          })}
        </Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={() => onPositive(selectedDevice)}>
          {i18next.t('room.controls.cameraSwitch.changeCamera')}
        </Button>
      </Modal.Footer>
    </StyledModal>
  );
};

export default SelectCameraModal;
