export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || window.injectedEnv?.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE || window.injectedEnv?.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || window.injectedEnv?.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_LOGOUT_URL =
  process.env.REACT_APP_AUTH0_LOGOUT_URL || window.injectedEnv?.REACT_APP_AUTH0_LOGOUT_URL;
export const AUTH0_REGISTRATION_START =
  process.env.REACT_APP_AUTH0_REGISTRATION_START || window.injectedEnv?.REACT_APP_AUTH0_REGISTRATION_START;
export const AUTH0_REDIRECTURL =
  process.env.REACT_APP_AUTH0_REDIRECTURL || window.injectedEnv?.REACT_APP_AUTH0_REDIRECTURL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || window.injectedEnv?.REACT_APP_SENTRY_DSN;
export const HOME_URL = process.env.REACT_APP_HOME_URL || window.injectedEnv?.REACT_APP_HOME_URL;
export const I18NEXT_URL = process.env.REACT_APP_I18NEXT_URL || window.injectedEnv?.REACT_APP_I18NEXT_URL;
export const I18NEXT_DEBUG = process.env.REACT_APP_I18NEXT_DEBUG || window.injectedEnv?.REACT_APP_I18NEXT_DEBUG;
export const REACT_APP_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL || window.injectedEnv?.REACT_APP_WEBSOCKET_URL;
export const REACT_APP_AUTH0_SECRET_INDEX_KEY = process.env.REACT_APP_AUTH0_SECRET_INDEX_KEY || window.injectedEnv?.REACT_APP_AUTH0_SECRET_INDEX_KEY