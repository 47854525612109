import React, { memo } from 'react';
import styled from 'styled-components';
import { Theme } from './ThemeProvider/useTheme/useTheme';
import { Container, Button, Badge,Row,Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
const Wrapper = styled.div`
  color: inherit;
  margin: 2rem;

  .btn-secondary {
    border: 2px solid ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
    color: ${(p) => (p.theme.mode === Theme.FITNESS ? '#1B7B7C' : '#ff695f')};
  }
`;

const Content = styled.div`
  background-color: #ffffff;
`;

const TopNavBar = styled.div`
  padding: 0.8rem 1.6rem;
  background-color: #002b4b;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.8rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
`;

const BackButton = styled(Button)`
  border: 2px solid #ffffff !important;
  color: #ffffff;
  background-color: #122b4b !important;
  text-align: center;
  font-size: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  margin-right: 1rem;
  .svg-inline--fa {
    margin-right: 1rem;
  }
`;

const TherapyDetails = ({user, children, therapy, closeButtonNavigateTo }) => {
  const patientProfile = therapy.patientProfile;
  const firstName = patientProfile ? therapy.patient.firstName : therapy.firstName;
  const lastName = patientProfile ? therapy.patient.lastName : therapy.lastName;
  let planName = patientProfile && therapy.patient && therapy.patient.insurancePlan ? therapy.patient.insurancePlan.planName : '';
  const email = patientProfile && therapy.patient ? therapy.patient.email : therapy.email;

  const history = useHistory();

  return (
    <Container>
      <Wrapper>
        <TopNavBar>
          <Row>
            <Col md={8}>
            <BackButton onClick={() => history.push(`${closeButtonNavigateTo}`)}>
              <i className="fas fa-angle-left" />
              <span className="hidden-xs">{i18next.t('generic.back')}</span>
            </BackButton>
            {firstName} {lastName} 
            </Col>
            <Col md={4}>  {email === user.email ?
              <Badge className={'badge-status badge-light mt-2 ml-5 float-right'}>
                {i18next.t('patients.therapistBadge')}</Badge> : null}
            {email !== user.email && planName ?
              <Badge className={'badge-status badge-light mt-2 ml-5 float-right'}>
                {planName}</Badge> : null}
                </Col>
          </Row>
        </TopNavBar>
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  );
};

export default memo(TherapyDetails);
