import React, { FC, useEffect, useState, memo } from 'react';
import { fetchFromRestAPI } from '../../util/api';
import { useAuth0 } from '../../util/auth0';
import ChatMessageComponent from './ChatMessageComponent';
import ChatHeadComponent from './ChatHeadComponent';
import ChatHeadHorizontalComponent from './ChatHeadHorizontalComponent';
type Props = {
    therapist: any,
    webSocket: any,
    chatKey: string,
    messages: any,
    tKeyPair: any,
    selectedTherapy: any;
    resetNewChatMessage: () => void;
    handleTherapySelection: (therapy) => void;
};
const ChatComponent: FC<Props> = ({ therapist, webSocket, messages, chatKey, tKeyPair, resetNewChatMessage, handleTherapySelection, selectedTherapy }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 720)
    const { user, getIdTokenClaims } = useAuth0();
    const [therapies, setTherapies] = useState([]);
    const handelTherapySelection = (therapy) => {
        handleTherapySelection(therapy);
    }
    const handleResize = () => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })
    useEffect(() => {
        if (!user) return;
        async function load() {
            const token = await getIdTokenClaims();
            const response = await fetchFromRestAPI('/api/v1/therapies/chat', { token });
            if (response && !response.error) {
                setTherapies(response);
            }
        }

        load();
    }, [user]); // eslint-disable-line

    const handleRefreshChatHead = async () => {
        const token = await getIdTokenClaims();
        const response = await fetchFromRestAPI('/api/v1/therapies/chat', { token });
        if (response && !response.error) {
            setTherapies(response);
        }
    }

    return (
        <React.Fragment>
            <div className="card border-top-1 no-x-overflow no-y-overflow w-100 chat-order-container">
                {isMobile && therapies ?
                    <div className="container-fluid">
                        <ChatHeadHorizontalComponent therapies={therapies} selectedTherapy={selectedTherapy} handleSelectTherapy={handelTherapySelection} key={chatKey} newMessage={messages} tKeyPair={tKeyPair}></ChatHeadHorizontalComponent>
                    </div>
                    : null}
                <div className="row g-0">
                    {!isMobile && therapies ? <ChatHeadComponent therapies={therapies} selectedTherapy={selectedTherapy} handleSelectTherapy={handelTherapySelection} key={chatKey} newMessage={messages} tKeyPair={tKeyPair} refreshChatHead={handleRefreshChatHead} resetNewChatMessage={resetNewChatMessage}></ChatHeadComponent> : null}
                    {selectedTherapy ? <ChatMessageComponent key={selectedTherapy._id} therapy={selectedTherapy} therapist={therapist} webSocket={webSocket} newMessage={messages} tKeyPair={tKeyPair} isMobile={isMobile}></ChatMessageComponent> : null}
                </div>
            </div>
        </React.Fragment>
    )

};

export default memo(ChatComponent);
