import { useCallback, useEffect, useState } from 'react';

import Video, { LocalVideoTrack, LocalAudioTrack, CreateLocalTrackOptions, LocalDataTrack } from 'twilio-video';
import { ensureMediaPermissions } from '../../../util';

export function useLocalAudioTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>();
  const [isAcquiringLocalAudioTrack, setIsAcquiringLocalAudioTrack] = useState(false);

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    return ensureMediaPermissions().then(() =>
      Video.createLocalAudioTrack(options).then((newTrack) => {
        setTrack(newTrack);
        return newTrack;
      })
    );
  }, []);

  useEffect(() => {
    // We get a new local audio track when the app loads.
    setIsAcquiringLocalAudioTrack(true);
    getLocalAudioTrack().finally(() => setIsAcquiringLocalAudioTrack(false));
  }, [getLocalAudioTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return { audioTrack: track, getLocalAudioTrack, isAcquiringLocalAudioTrack };
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalVideoTrack, setIsAcquiringLocalVideoTrack] = useState(false);

  const getLocalVideoTrack = useCallback((newOptions?: CreateLocalTrackOptions) => {
    // In the DeviceSelector and FlipCameraButton components, a new video track is created,
    // then the old track is unpublished and the new track is published. Unpublishing the old
    // track and publishing the new track at the same time sometimes causes a conflict when the
    // track name is 'camera', so here we append a timestamp to the track name to avoid the
    // conflict.
    const options: CreateLocalTrackOptions = {
      frameRate: 24,
      height: 720,
      width: 1280,
      name: `camera-${Date.now()}`,
      ...newOptions,
    };

    return ensureMediaPermissions().then(() =>
      Video.createLocalVideoTrack(options).then((newTrack) => {
        setTrack(newTrack);
        return newTrack;
      })
    );
  }, []);

  useEffect(() => {
    // We get a new local video track when the app loads.
    setIsAcquiringLocalVideoTrack(true);
    getLocalVideoTrack().finally(() => setIsAcquiringLocalVideoTrack(false));
  }, [getLocalVideoTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return { videoTrack: track, getLocalVideoTrack, isAcquiringLocalVideoTrack };
}

export function useLocalDataTrack() {
  const [track, setTrack] = useState<LocalDataTrack>();
  const [isAcquiringLocalDataTrack, setIsAcquiringLocalDataTrack] = useState(false);

  const getLocalDataTrack = useCallback(
    (deviceId?: string) => {
      const options: CreateLocalTrackOptions = {};

      if (deviceId) {
        options.deviceId = { exact: deviceId };
      }

      return ensureMediaPermissions().then(() => {
        if (!track) {
          const newTrack = new Video.LocalDataTrack();
          setTrack(newTrack);
          return newTrack;
        } else {
          return track;
        }
      });
    },
    [track]
  );

  useEffect(() => {
    // We get a new local audio track when the app loads.
    setIsAcquiringLocalDataTrack(true);
    getLocalDataTrack().finally(() => setIsAcquiringLocalDataTrack(false));
  }, [getLocalDataTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return { dataTrack: track, getLocalDataTrack, isAcquiringLocalDataTrack };
}

export default function useLocalTracks() {
  const { audioTrack, getLocalAudioTrack, isAcquiringLocalAudioTrack } = useLocalAudioTrack();
  const { videoTrack, getLocalVideoTrack, isAcquiringLocalVideoTrack } = useLocalVideoTrack();
  const { dataTrack, getLocalDataTrack, isAcquiringLocalDataTrack } = useLocalDataTrack();

  const isAcquiringLocalTracks = isAcquiringLocalAudioTrack || isAcquiringLocalVideoTrack || isAcquiringLocalDataTrack;

  const localTracks = [audioTrack, videoTrack, dataTrack].filter((track) => track !== undefined) as (
    | LocalAudioTrack
    | LocalVideoTrack
    | LocalDataTrack
  )[];

  return {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    getLocalDataTrack,
    isAcquiringLocalTracks,
    isAcquiringLocalDataTrack,
  };
}
