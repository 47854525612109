import moment from 'moment';
import 'moment/locale/de';
import React, { FC } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { formatDuration } from '../util/dateTimeUtils';

type Statistics = {
  patient: string;
};

const CallStatisticsPerPatientChart: FC<{ callStatisticsPerPatient: Statistics[] }> = ({
  callStatisticsPerPatient,
}) => {
  return (
    <ResponsiveContainer>
      <BarChart data={callStatisticsPerPatient} margin={{ top: 0, right: 30, left: 20, bottom: 30 }} layout="vertical">
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis name="hours" unit="h" type="number" />
        <YAxis dataKey="patient" type="category" />
        <Tooltip
          content={({ active, payload, label }: TooltipProps) => {
            if (active) {
              return (
                <div className="custom-tooltip">
                  <p className="label">
                    <strong>{label}: </strong>
                    {formatDuration(moment.duration(payload?.[0]?.value as number, 'hour'))}
                  </p>
                </div>
              );
            }
            return null;
          }}
        />
        <Bar fill="#FFAB24" dataKey="durationInHours" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CallStatisticsPerPatientChart;
