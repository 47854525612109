import i18next from 'i18next';
import React, { FC, useState, useRef } from 'react';
import Avatar from 'react-avatar-edit';
import { Row, Col, Image, Button, Alert } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
const IconButton = styled(Button)`
  white-space: nowrap;
  background-color:#ff695f;
`;
const ErrorDiv = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
const ProfilePicUpload: FC<{ isShow: boolean, src: any, onPositive: any, onNegative: any }> = ({ isShow, src, onPositive, onNegative }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [preview, setPreview] = useState(null);
    const [srcImage, setSrcImage] = useState(src);
    const [isValid, setIsValid] = useState(null);
    const onClose = () => {
        onNegative();
    }
    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }
    const handleAccept = () => {
        urltoFile(preview, 'image', preview.substring(preview.indexOf(":") + 1, preview.indexOf(";"))).then(function (file) {
            onPositive(file, preview);
        });
    }
    const onCrop = (preview) => {
        setPreview(preview);
        setIsValid(true);
    }

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 1000000) {
            setIsValid(false);
            elem.target.value = "";
        } else {
            setIsValid(true);
        };
    }
    const handleUpload = () => {
        inputRef.current?.click();
    };
    const handleDisplayFileDetails = () => {
        if (inputRef.current?.files && inputRef.current.files[0]) {
            setSrcImage(URL.createObjectURL(inputRef.current.files[0]));
        }
    }
    return (
        <Modal size="xl" show={isShow} onHide={() => onNegative()}>
            <Modal.Header closeButton>
                <Modal.Title>{i18next.t('generic.uploadTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {isValid === false ?
                        <Col md={12}>
                            <Alert variant="danger">{i18next.t('generic.fileTooBig')}</Alert>
                        </Col> : null}
                    {srcImage != null ?
                        <Col md={9} className="overflow-hidden hidden-xs" key={'ib-' + srcImage.name}>
                            <Avatar
                                width={800}
                                height={295}
                                onCrop={onCrop}
                                onClose={onClose}
                                onBeforeFileLoad={onBeforeFileLoad}
                                src={srcImage}
                                label={i18next.t('generic.chooseFileLabel')}
                            /></Col> : null}
                    {srcImage != null ?
                        <Col md={9} className="overflow-hidden hidden-lg" key={'is-' + srcImage.name}>
                            <Avatar
                                width={300}
                                height={195}
                                onCrop={onCrop}
                                onClose={onClose}
                                onBeforeFileLoad={onBeforeFileLoad}
                                src={srcImage}
                                label={i18next.t('generic.chooseFileLabel')}
                            /></Col> : null}
                    <Col md={srcImage != null ? 3 : 12} className="text-center center-block">
                        <input ref={inputRef} className="d-none" type="file" accept="image/*" onChange={handleDisplayFileDetails} />
                        <Image className="mt-2 border" roundedCircle={true} src={preview ? preview : '../default_profile.png'} alt="Preview" width='100px' height='100px' />
                        <br />
                        <IconButton className='mt-4' onClick={handleUpload}>
                            <i className='fas fa-upload' /> {i18next.t('generic.uploadImageBtn')}
                        </IconButton>
                        <ErrorDiv>{i18next.t('generic.chooseFileLabel')}</ErrorDiv>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onNegative()}>
                    {i18next.t('generic.cancel')}
                </Button>
                <Button variant='primary' onClick={handleAccept} disabled={!isValid} >
                    {i18next.t('generic.save')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfilePicUpload;
