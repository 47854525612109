import i18next from 'i18next';
import { map } from 'lodash';
import TimePicker from 'react-time-picker';
import 'rc-time-picker/assets/index.css';
import React, { memo, useEffect, useState } from 'react';
import { Button, Col, Row, Form, Table } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import styled from 'styled-components';
import { fetchFromRestAPI } from '../util/api';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAuth0 } from '../util/auth0';
import { getFullURL } from '../util/endpoints';
import { emitNotification } from '../components/Notification';
import ProfilePicUpload from '../components/ProfilePicUpload';
const Wrapper = styled.div`
  h2 {
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 2rem;
  }
`;
const IconButton = styled(Button)`
  white-space: nowrap;
  background-color:#ff695f;
`;

const AvailabilityContainer = ({ userProfile }) => {
  const { getIdTokenClaims } = useAuth0();
  const [image, setImage] = useState(null)
  const [errorMsg, setErrorMsg] = useState({
    about: '',
    profilePic: '',
    specialization: '',
    availability: '',
    invalidTime: '',
    termsAndCondition: '',
    privacyPolicy: ''
  })
  const i18nextLng = localStorage.getItem('i18nextLng');
  const userLanguage = i18nextLng && i18nextLng.includes('-') ? i18nextLng.split('-')[0] : i18nextLng;
  const availabilityData = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: []
  }
  const [isError, setIsError] = useState(true);
  const [bookingBefore, setBookingBefore] = useState(userProfile.bookingBefore);
  const [privacyPolicy, setPrivacyPolicy] = useState('');
  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [showProfilePicUpload, setShowProfilePicUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [about, setAbout] = useState(userProfile.about ? userProfile.about : '');
  const [deOption, setDEOption] = useState([]);
  const [enOption, setENOption] = useState([]);
  const [availability, setAvailability] = useState(userProfile.availability ? userProfile.availability : availabilityData);
  const [multiSelections, setMultiSelections] = useState([]);
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }]
    ],
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet'
  ];
  useEffect(() => {
    isValid();
    // eslint-disable-next-line
  }, [multiSelections, selectedFile,bookingBefore]);

  const isValid = () => {
    setIsError(false);
    let errorMsg = {
      about: '',
      profilePic: '',
      specialization: '',
      availability: '',
      invalidTime: '',
      termsAndCondition: '',
      privacyPolicy: ''
    };
    if (about.length === 0) {
      setIsError(true);
      errorMsg.about = 'aboutError';
    }
    if (privacyPolicy.length === 0) {
      setIsError(true);
      errorMsg.privacyPolicy = 'privacyPolicy';
    }

    if (termsAndCondition.length === 0) {
      setIsError(true);
      errorMsg.termsAndCondition = 'termsAndCondition';
    }
    if (availability.mon.length === 0 && availability.tue.length === 0 && availability.wed.length === 0 && availability.thu.length === 0 && availability.fri.length === 0 && availability.sat.length === 0 && availability.sun.length === 0) {
      setIsError(true);
      errorMsg.availability = 'noAvailability';
    }
    if (availability.mon.length !== 0) {
      availability.mon.forEach(e => {
        if (e.startTime > e.endTime) {
          setIsError(true);
          errorMsg.invalidTime = 'invalidTime';
        }
      });
    }
    if (availability.tue.length !== 0) {
      availability.tue.forEach(e => {
        if (e.startTime > e.endTime) {
          setIsError(true);
          errorMsg.invalidTime = 'invalidTime';
        }
      });
    }
    if (availability.wed.length !== 0) {
      availability.wed.forEach(e => {
        if (e.startTime > e.endTime) {
          setIsError(true);
          errorMsg.invalidTime = 'invalidTime';
        }
      });
    }
    if (availability.thu.length !== 0) {
      availability.thu.forEach(e => {
        if (e.startTime > e.endTime) {
          setIsError(true);
          errorMsg.invalidTime = 'invalidTime';
        }
      });
    }
    if (availability.fri.length !== 0) {
      availability.fri.forEach(e => {
        if (e.startTime > e.endTime) {
          setIsError(true);
          errorMsg.invalidTime = 'invalidTime';
        }
      });
    }
    if (availability.sat.length !== 0) {
      availability.sat.forEach(e => {
        if (e.startTime > e.endTime) {
          setIsError(true);
          errorMsg.invalidTime = 'invalidTime';
        }
      });
    }
    if (availability.sun.length !== 0) {
      availability.sun.forEach(e => {
        if (e.startTime > e.endTime) {
          setIsError(true);
          errorMsg.invalidTime = 'invalidTime';
        }
      });
    }
    if (!selectedFile && !userProfile.profilePic) {
      setIsError(true);
      errorMsg.profilePic = 'picRequired';
    }
    if (multiSelections.length === 0) {
      setIsError(true);
      errorMsg.specialization = 'specializationRequired';
    }
    setErrorMsg({ ...errorMsg });
  }
  const getContract = async () => {
    const token: any = await getIdTokenClaims();
    const contract = await fetchFromRestAPI(`/api/v1/therapist/contract/${userProfile._id}`, {
      method: 'GET',
      token,
    });
    if (contract && !contract.error) {
      setPrivacyPolicy(contract.privacyPolicy);
      setTermsAndCondition(contract.termsAndCondition)
    }
    return contract;
  }
  const getSpecialization = async () => {
    if (userProfile.specialization) {
      if (userLanguage === 'de')
        setMultiSelections(JSON.parse(JSON.stringify(userProfile.specialization)).map(({ _id, de }) => ({ _id, labelKey: de })));
      else
        setMultiSelections(JSON.parse(JSON.stringify(userProfile.specialization)).map(({ _id, en }) => ({ _id, labelKey: en })));
    }
    const idToken: any = await getIdTokenClaims();
    await fetchFromRestAPI(`/api/v1/specialization`, {
      token: idToken,
    }).then((response) => {
      if (response && response.length > 0) {
        const deOption = JSON.parse(JSON.stringify(response)).map(({ _id, de }) => ({ _id, labelKey: de }));
        const enOption = JSON.parse(JSON.stringify(response)).map(({ _id, en }) => ({ _id, labelKey: en }));
        setENOption(enOption);
        setDEOption(deOption);
      }

    }).catch((err) => {
      console.log(err);
    });
  }
  useEffect(() => {
    const fetchDataAsync = async () => {
      if (userProfile) {
        await getContract();
        await getSpecialization();
      }
    };
    fetchDataAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const formatTimeToColon = (time: String) => {
    if (!time || time === '0') {
      time = '0000';
    }
    if (time && time.toString().length === 3)
      time = 0 + time.toString();
    return time.toString().replace(/(.{2})(?!$)/g, '$1:');
  }
  const removeTime = (key, index) => {
    availability[key].splice(index, 1);
    setAvailability({ ...availability });
    isValid();
  }
  const addNewTime = (key) => {
    let lastEndTime = 900;
    let newEndTime = 1700;
    if (availability[key].length > 0) {
      lastEndTime = availability[key][availability[key].length - 1].endTime;
      newEndTime = lastEndTime + 400;
    }
    availability[key].push({
      startTime: lastEndTime,
      endTime: newEndTime > 2400 ? '000' : newEndTime
    });
    setAvailability({ ...availability });
    isValid();
  }
  const setTimeValue = (key, i, v, action) => {
    if (v) {
      const value = v.replace(/:/g, '');
      if (parseInt(value) >= 0 && parseInt(value) <= 2400) {
        if (action === 'end')
          availability[key][i].endTime = parseInt(value);
        else
          availability[key][i].startTime = parseInt(value);
        setAvailability({ ...availability });
      }
      isValid();
    }
  }

  const handleSaveAvailability = async () => {
    const token = await getIdTokenClaims();
    userProfile.specialization = multiSelections.map(({ _id }) => (_id));
    userProfile.availability = availability;
    userProfile.bookingBefore=bookingBefore;
    var data = new FormData();
    data.append('specialization', JSON.stringify(userProfile.specialization));
    data.append('availability', JSON.stringify(availability));
    data.append('about', about.trim());
    data.append('privacyPolicy', privacyPolicy.trim());
    data.append('termsAndCondition', termsAndCondition.trim());
    data.append('bookingBefore', bookingBefore);
    if (selectedFile) {
      data.append(
        "profilePic",
        selectedFile,
        selectedFile.name
      );
    }
    const response = await fetch(getFullURL('/api/v1/therapist/update'), {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token.__raw}`,
      },
      body: data
    });
    if (response.ok) {
      emitNotification(i18next.t('errorMessageTemplate.key_profileUpdatedSuccess'), {
        type: 'error',
        position: 'top-right',
      });
      setIsError(true);
    } else {
      emitNotification(i18next.t('errorMessageTemplate.genericMsg'), {
        type: 'error',
        position: 'top-right',
      });
    }
  }
  const handleDisplayFileDetails = (src, preview) => {
    setImage(preview);
    setSelectedFile(src);
    setShowProfilePicUpload(false);
  };
  const renderTr = (k, v, i) => {
    return (
      <tr key={'atr-' + k} >
        <td className='w-10'>{i18next.t(`myAccountOverview.Availability.days.${k}`)}</td>
        <td className='w-50'>
          {map(v, (a, i) => renderTime(k, a, i))}</td>
        <td className='w-10'>
          {v.length < 3 ?
            <button type='button' className='close' aria-label='Dismiss' onClick={() => { addNewTime(k) }}>
              <span aria-hidden='true'>+</span>
            </button>
            : null}</td>
      </tr>
    );
  }
  const renderTime = (key, v, i) => {
    return (
      <div key={'time-' + i} ><TimePicker
        format='HH:mm'
        minutePlaceholder='_ _'
        hourPlaceholder='_ _'
        className='time-input-small'
        clockIcon={null}
        clearIcon={null}
        value={formatTimeToColon(v.startTime)}
        onChange={(e) => { setTimeValue(key, i, e, 'start'); }}
      /> - <TimePicker
          format='HH:mm'
          minutePlaceholder='_ _'
          hourPlaceholder='_ _'
          className={i%2 !==0 ? 'time-input-small ml-1' : 'time-input-small'}
          clockIcon={null}
          clearIcon={null}
          value={formatTimeToColon(v.endTime)}
          onChange={(e) => { setTimeValue(key, i, e, 'end'); }}
        /><button type='button' className='close close-icon' aria-label='Dismiss'
          onClick={() => { removeTime(key, i) }}
        >
          <span aria-hidden='true'><i className='fas fa-trash'></i></span>
        </button>
      </div>);
  }
  return (
    <Wrapper>
      <Row>
        <Col md={12}>
          <h2>{i18next.t('myAccountOverview.Availability.title')}</h2>
          <p>{i18next.t('myAccountOverview.Availability.discription')}</p>
          <h2>{i18next.t('myAccountOverview.Availability.aboutYou')}</h2>
          <p>{i18next.t('myAccountOverview.Availability.aboutDiscription')}
          </p>
          <Row className='pb-3'>
            <Col md={3}>
              <ProfilePicUpload onNegative={() => setShowProfilePicUpload(false)} key={'pp-' + showProfilePicUpload} isShow={showProfilePicUpload} src={null} onPositive={handleDisplayFileDetails} />
              <Image src={image ? image : (userProfile.profilePic ? userProfile.profilePic : '../default_profile.png')} roundedCircle={true} className='border' width='80px' height='80px' />
              <IconButton className='ml-4' onClick={() => setShowProfilePicUpload(true)}>
                <i className='fas fa-upload' /> {i18next.t('myAccountOverview.Availability.uploadImage')}
              </IconButton>
              {errorMsg.profilePic ? <p className="error-msg">{i18next.t(`myAccountOverview.Availability.Validation.${errorMsg.profilePic}`)}</p> : null}
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>{i18next.t('myAccountOverview.Availability.yourDiscription')}</Form.Label>
                <Form.Label className='float-right'>{about ? about.length : 0}/200</Form.Label>
                <Form.Control as='textarea' rows={3} value={about}
                  onChange={e => { (e.target.value && e.target.value.length < 200) ? setAbout(e.target.value) : setAbout(e.target.value.slice(0, 200)) }}
                  onKeyUp={e => isValid()} />
                {errorMsg.about ? <p className="error-msg">{i18next.t(`myAccountOverview.Availability.Validation.${errorMsg.about}`)}</p> : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>

            <Col xs={12} md={4}>
              <p>{i18next.t('myAccountOverview.Availability.yourService')} ({multiSelections.length}/3)</p>
              <Typeahead
                id="specialization-typeahead-multiple"
                multiple
                className="pb-2"
                onChange={setMultiSelections}
                labelKey={(option) => `${option.labelKey}`}
                options={multiSelections.length < 3 ? (userLanguage === 'de' ? deOption : enOption) : []}
                placeholder={i18next.t('myAccountOverview.Availability.yourService')}
                selected={multiSelections}
              />
              {errorMsg.specialization ? <span className="error-msg">{i18next.t(`myAccountOverview.Availability.Validation.${errorMsg.specialization}`)}</span> : null}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={4}>
              <p>{i18next.t('myAccountOverview.Availability.bookBefore')}</p>
              <select className='form-control' name="bookingBefore" defaultValue={bookingBefore} onChange={(e) => { setBookingBefore(e.target.value) }}>
                {
                  i18next.t('myAccountOverview.Availability.hourDropdown').split(',').map((val, i) => {
                    return (
                      <option key={'hours-' + i} value={val}>{val} {i18next.t('myAccountOverview.Availability.hourLable')}</option>
                    );
                  })}
              </select>
            </Col>
            </Row>
            <Row className="mt-2">
            <Col xs={12} md={4}>
              <p className="font-weight-bold">{i18next.t('myAccountOverview.Availability.weeklyHours')}</p>
              {errorMsg.availability ? <p className="error-msg">{i18next.t(`myAccountOverview.Availability.Validation.${errorMsg.availability}`)}</p> : null}
              <Table>
                <tbody>
                  {Object.entries(availability).map(([k, v], i) => (renderTr(k, v, i)))}
                </tbody>
              </Table>
              {errorMsg.invalidTime ? <p className="error-msg">{i18next.t(`myAccountOverview.Availability.Validation.${errorMsg.invalidTime}`)}</p> : null}

            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={12} md={4}>
              <h2>{i18next.t('myAccountOverview.contractTitle')}</h2>
              <p>{i18next.t('myAccountOverview.contractDescription')}</p>
              <Form.Group className='mb-3'>
                <Form.Label>{i18next.t('myAccountOverview.privacyPolicy')}</Form.Label>
                <ReactQuill value={privacyPolicy} modules={modules}
                  formats={formats}
                  placeholder={i18next.t('myAccountOverview.privacyPolicy')}
                  onChange={(e) => setPrivacyPolicy(e)}
                  onKeyUp={e => isValid()}
                />

                {errorMsg.privacyPolicy ? <p className="error-msg">{i18next.t(`myAccountOverview.Availability.Validation.${errorMsg.privacyPolicy}`)}</p> : null}
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>{i18next.t('myAccountOverview.termsAndConditions')}</Form.Label>
                <ReactQuill value={termsAndCondition} modules={modules}
                  formats={formats}
                  placeholder={i18next.t('myAccountOverview.termsAndConditions')}
                  onChange={(e) => setTermsAndCondition(e)}
                  onKeyUp={e => isValid()}
                />
                {errorMsg.termsAndCondition ? <p className="error-msg">{i18next.t(`myAccountOverview.Availability.Validation.${errorMsg.termsAndCondition}`)}</p> : null}
              </Form.Group>
            </Col>
          </Row>
          <Button variant='primary' type='submit' disabled={isError}
            onClick={handleSaveAvailability}>
            {i18next.t('generic.save')}
          </Button>
        </Col>
      </Row>

    </Wrapper>
  );
};

export default memo(AvailabilityContainer);