import { useCallback, useEffect, useState } from 'react';

export default function useTimer() {
  const [timerSeconds, setTimerSeconds] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);

  const setTimerState = useCallback(
    (state) => {
      setIsTimerActive(state);
    },
    [setIsTimerActive]
  );

  const resetTimer = useCallback(() => {
    setTimerSeconds(0);
  }, [setTimerSeconds]);

  useEffect(() => {
    let interval = null;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimerSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isTimerActive && timerSeconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, timerSeconds]);

  return [timerSeconds, isTimerActive, resetTimer, setTimerState] as const;
}
